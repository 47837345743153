.nj-usermenu{ padding: 0; background-color: white; box-shadow: none; height: auto; width: 210px; max-height: 0; overflow: hidden; top: 60px; right: 0; position: absolute; z-index: 1000; transition: all 400ms ease-in-out;
  &__list{ padding: 8px 0; margin: 0; }
  &__item{ }
  &__link{ padding: 10px 25px; display: block; display: flex; align-items: center; color: black;
    i{ margin-right: 20px; font-size: 20px;  }
    &:hover{ color: var(--nj-color-base-gray-800); }
  }
  &--mobile{ max-height: 0; position: fixed; width: calc(100% - 60px); top: auto; bottom: 90px; right: 30px; box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.06), 0px 3px 1px rgba(0, 0, 0, 0.06);
    &.active{ max-height: 250px;  }
  }
}

.nj-deploy-usermenu{ position: relative;
  > span{ padding: 4px 12px 4px 6px; border-radius: 4px; background-color: white; }
  &:hover { cursor: pointer;
    .nj-avatar__picture{ box-shadow: 0 0 0 2px var(--nj-color-base-blue-corporate); }
    > span{ background-color: var(--nj-color-base-gray-200); display: block; }
    .nj-usermenu{ max-height: 250px; box-shadow: var(--nj-shadow-level-8-dp);
      .material-icons.md--red{ color: var(--nj-color-base-red); }
    }
  }
}


.nj-avatar{
  &--account-mobile{ position: fixed !important; right: 30px; bottom: 30px; z-index: 1999;
    .nj-avatar__picture{ box-shadow: 0 0 0 2px var(--nj-color-base-blue-corporate); }
  }
}
