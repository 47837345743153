.nj-pagination{ margin-top: 40px; display: flex; justify-content: center;
  &__list{ display: flex; list-style: none;
    li{ margin: 0 10px;
      a{ background-color: $gris-clair; height: 40px; width: 40px; display: flex; align-items: center; justify-content: center; @extend %title-font-bold; font-size: 14px;
        &.active, &:hover{ background: var(--nj-color-palette-teal-500); color: #fff; }
      }
    }
  }
}
.nj-admin-filters{ background-color: $gris-clair;

}