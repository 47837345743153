.nl-subscription{ display: flex; background: var(--nj-color-base-blue-venice); align-items: center; justify-content: center; padding: 30px 0 50px;
	.container{ display: flex; justify-content: space-between; align-items: center; }
	&__image{ width: 45px;  }
	i.nj-icon{ align-self: center;
		&.unlogged{ align-self: center; }
	}
	&__text{ width: 350px; font-size: 20px; line-height: 1.2; @extend %title-font; color: #fff; margin-bottom: 0;
		strong{ @extend %title-font-bold; display: block; }
		&--subscribed{ margin-right: 30px; width: auto; }
		&.nl-subscription__text--unlogged{ margin-left: 0; margin-right: auto; }
	}
	&__form{ width: calc(100% - 485px); position: relative; display: flex; align-items: flex-end;
		.nj-form-group{ margin-top: 0; flex: 1; margin-bottom: 0;
			.nj-form-control{ background-image: linear-gradient(to top, var(--nj-color-text-label-focus) 2px, transparent 2px),linear-gradient(to top, #fff 1px, transparent 1px);
				&::placeholder{ color: #fff; }
			}
		}
	}
	&__subscribe{ display: flex; justify-content: space-between; align-items: center;
		img{ height: 15px; margin-left: 20px; }
	}
	&--logged{ padding: 30px 0 30px;
		.container{ justify-content: center; }
	}
	&--events-events, &--users-login, &--users_settings-inscription, &--users-passwordForgotten, &--users-password, &--submissions-result{ margin-top: 0;  }
}

@media screen and (max-width: 1140px){
	.nl-subscription{
		&__text{ width: 300px; font-size: 18px; }
		&__form{ width: calc(100% - 435px); }
	}
}


@media screen and (max-width: 1080px){
	.nl-subscription{
		&__text{ font-size: 18px; width: 260px; }
		&__form{ width: calc(100% - 395px); }
	}
}


@media screen and (max-width: 960px){
	.nl-subscription{
		.container{ flex-wrap: wrap; }
		&__text{ align-self: center; width: auto; }
		&__form{ margin: 0; width: 100%; }
		.nj-btn--outline-light{ margin-left: 30px; }
	}
}


@media screen and (max-width: 780px){
	.nl-subscription{
		.nj-btn--outline-light{ margin: 20px auto 0;  }
	}
}

@media screen and (max-width: 500px){
	.nl-subscription{ padding: 30px 0;
	}
}

