.nj-survey{ display: flex; justify-content: space-between; margin-bottom: 80px; align-items: flex-start;
  &__sidebar{ width: 450px; background-color: var(--nj-color-base-gray-100); box-sizing: border-box; padding: 20px;
      &--no-background{ background: transparent; }
  }
  &__sidebar-link{ margin-bottom: 10px; display: flex; align-items: flex-start;
    &.active{ font-weight: bold; }
    i{ opacity: 0; transition: opacity 300ms ease-in-out; color: var(--nj-color-base-blue-corporate); }
    span{ margin-right: 10px; }
    &.answered{ font-weight: bold; color: var(--nj-color-base-blue-corporate);
      i{ opacity: 1; }
    }
    &.error{ color: var(--nj-color-base-red); font-weight: bold;
      i{ opacity: 1;
        &.error{ color: var(--nj-color-base-red); }
      }
    }
  }
  select[name=id_startups] + .chosen-container{ margin-top: 10px; }
  select[name=id_submitter] + .chosen-container{ margin-top: 10px; }
  &__group-number{ position: absolute; left: 15px; top: 12px; border: 2px solid var(--nj-color-base-gray-300); color: var(--nj-color-base-gray-500); display: flex; align-items: center; justify-content: center; border-radius: 50%; height: 30px; width: 30px; font-size: 11px; }
  &__group-container{ position: relative;
    .js-stepped-form-question-group{ color: var(--nj-color-base-gray-500); }
    &.active{
      .nj-survey__group-number{ border: 2px solid #000; color: #000; }
      .js-stepped-form-question-group{ color: #000; }
    }
  }
  &__alert{
    &.nj-alert{ display: none;
      &.visible{ display: flex; }
    }
  }
  &__content-container{ width: calc(100% - 490px); }
  &__content{ display: flex; flex-direction: column; justify-content: space-between; height: 100%; position: relative;
    .nj-file{ font-size: 10px; }
    h4{
      small{ display: block; margin-top: 10px; font-size: 1rem;}
    }
  }
  &__footer{ display: flex; justify-content: space-between; align-items: flex-end;
    .nj-progress__bar{ transition: width 300ms ease-in-out; }
    .nj-progress{ background-color: var(--nj-color-base-blue-bigstone); margin-top: 10px; }
  }
  &__submit-container{ border: 1px solid var(--nj-color-base-blue-corporate); padding: 20px;
    a{ color: var(--nj-color-base-blue-corporate); text-decoration: underline;  }
  }
  &__progressbar{ width: 50%; }
  &__question{ display: none;
    &.active{ display: block; }
  }
  .nj-rgpd__text{
    p{ font-size: 0.75rem; }
  }
  .js-save-draft{ display: none;
    &.visible{ display: inline-flex; }
  }
  .js-question-next{ visibility: hidden;
    &.visible{ visibility: visible; }
  }
  &--cfp{
    .input_line{
      textarea{ resize: vertical; line-height: initial; font-size: 0.85rem; line-height: 1.5; min-height: 200px; }
      .note-editor + .nj-label-static{ top: 0; }
      label[for=input_op_description]{ display: none; }
      label[for=input_op_benefit]{ display: none; }
    }
    .nj-langswitcher-container{ width: 120px; position: absolute; top: -100px; right: 0; }
    select[name=KromiFormLangSwitcher]{  }
  }
}


@media screen and (max-width: 992px){
  .nj-survey{ flex-direction: column-reverse;
    &__sidebar{ width: 100%; margin-top: 20px; }
    &__content-container{ width: 100%; }
    &__footer{ position: fixed; bottom: 0; left: 0; background-color: white; width: 100%; padding: 10px; }
    &__progressbar{ width: calc(100% - 240px); }
  }
}