.nj-subscription{ padding-top: 60px; min-height: calc(100vh - 96px); padding-bottom: 100px; box-sizing: border-box; background: url('../images/innovators-background.jpg') no-repeat top center; background-size: cover; background-attachment: fixed; overflow: visible; height: auto;  display: flex; justify-content: flex-start; align-items: center;
  &__container{ position: relative; width: 1120px; z-index: 1; height: auto; margin: 0 auto; background-color: #ffffff; box-shadow: 4px 4px 20px #0003; padding: 50px; box-sizing: border-box;
    h1{ font-size: 36px; @extend %title-font-bold; margin-bottom: 30px; color: $secondary-color; line-height: 1.3;  }
  }
  &__container--password-container, &__container--password{ max-width: 700px; }
  &--password-forgotten, &--password{ padding-top: 0; align-items: flex-start; padding-top: 50px;
    .nj-form-group{ width: 350px; margin: 20px auto 40px; }
  }
  &--password{
    .nj-form-group{ margin: 0 auto; }
    .btn-group{ justify-content: flex-start; flex-direction: row-reverse;
      .nj-btn--outline-primary{ margin-right: 20px; }
      .nj-btn--primary{ margin-right: 0 !important; }
    }
  }
  &--result{ text-align: center; }
  &__okta-container{ color: #fff; margin-top: 3vh; background-color: $bleu-okta; padding: 30px 40px; margin: 0 50px; }
  &__okta{ display: flex; color: #fff; align-items: center; justify-content: space-between;
    h2{ @extend %title-font-black; font-size: 20px;
      small{ display: block; @extend %title-font-reg; }
    }
    span{ @extend %title-font-reg; font-size: 20px; max-width: 145px; width: auto; margin: 0 45px; }
    img{ width: 110px; filter: brightness(0) invert(1); margin-left: 0; margin-right: auto; }
    a{ min-width: 190px; box-sizing: border-box; display: flex; background-color: #fff; color: $bleu-okta;
      label{ margin-left: 20px; }
      img{ width: 20px; margin: 0; filter: none; }
      &:hover{
        label{ cursor: pointer; }
      }
    }
  }
  &__overlay{ background: rgba(255,255,255, 0.5); position: fixed; z-index: 0; top: 0; left: 0; right: 0; bottom: 0; }
  &__submit-info{ margin: 0; padding: 20px 30px 0 30px; color: var(--nj-color-base-gray-600); }
  .checkbox-group{
    label{ margin-bottom: 0; }
    .nj-checkbox{ padding-top: 18px !important; }
  }
}

@media screen and (max-width: 1250px){
  .nj-subscription{
    &__container{ width: 90%; }
  }
}

@media screen and (max-width: 1200px){
  .nj-subscription{
    &__container{ width: 90%; }
    &__okta{ flex-wrap: wrap;
      h2{
      }
      span{ font-size: 16px; width: 125px; margin: 0 30px; }
      img{  }
    }
  }
}

@media screen and (max-width: 1130px){
  .nj-subscription{
    &__container{ width: 90%; }
    &__okta{ flex-wrap: wrap;
      h2{ width: 100%; margin-bottom: 10px;
        small{ display: inline; }
      }
      span{ margin: 0; font-size: 20px; width: 155px; }
      img{ margin: 0 auto; }
    }
  }
}

@media screen and (max-width: 991px){
  .nj-subscription{
    > .container{
      > .row{ flex-direction: column-reverse;
        .col{ margin: 0; padding: 0; min-height: auto;
          .nj-subscription__engie{ margin: 0 !important;  }
        }
      }
    }
  }
}




@media screen and (max-width: 750px){
  .nj-subscription{
    > .container{
      > .row{ flex-direction: column-reverse;
        .col{ margin: 0; padding: 0; min-height: auto;
          h1{ font-size: 2.5rem; }
          .nj-alert{ margin-bottom: 30px; }
          #input_line_us_youtube{ margin-bottom: 30px; }
        }
      }
    }
  }
}


@media screen and (max-width: 560px){
  .nj-subscription{
    &__okta-container{ margin: 20px 0; }
    &__okta{ flex-direction: column; justify-content: center;
      h2{ text-align: center;
        small{ display: none;}
      }
      span{ text-align: center; }
      img{ margin: 10px auto; }
    }
  }
}

@media screen and (max-width: 400px){
  .nj-subscription{
    &__okta-container{ padding: 10px 20px 20px 20px; }
  }
}