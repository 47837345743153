.nj-search-results{  margin: 30px auto 0;
  &__container{ position: relative; width: 1120px; margin: 0 auto; height: auto; }
  &__title{ font-size: 36px; @extend %title-font-reg; color: $secondary-color;
    strong{ font-size: 36px; @extend %title-font-bold; }
  }
  &__description{ font-size: 16px;  @extend %title-font-bold; margin-top: 10px;}
  &__filters{ margin-top: 30px; margin-bottom: 50px;
    .nj__form{ display: flex; justify-content: space-between; width: 100%; flex-wrap: nowrap; }
    .nj__field{ flex: 1; margin-right: 20px; color: $main-color; @extend %title-font-reg;  }
    .nj__select{ margin-right: 20px; color: $main-color; @extend %title-font-reg; }
  }
}

@media screen and (max-width: 1250px){
  .nj-search-results{
    &__container{ width: 90%; padding: 0 0 20px 0; box-sizing: border-box; }
  }
}

@media screen and (max-width: 980px){
  .nj-search-results{
    &__container{ width: 100%; padding: 20px 20px 0 20px; box-sizing: border-box; }
  }
}

@media screen and (max-width: 700px){
  .nj-search-results{
    &__filters{
      .nj__form{ flex-direction: column;
        select, input[type=text]{ margin-right: 0; margin-bottom: 20px; }
      }
    }
  }
}