// colors

$nj-bleu-ciel: #00aaff;
$nj-vert-fonce: #027436;
$nj-vert-clair: #B4D236;
$nj-bleu: #0078be;
$nj-turquoise: #1CB198;
$nj-vert: #04A84F;
$nj-jaune: #FFCB04;
$nj-orange: #f07d00;
$nj-rouge: #EC2323;
$nj-majenta: #EA2B8B;
$nj-bleu-fonce: #010F67;
$nj-violet: #92278F;
$nj-mauve: #552386;
$nj-rose: #910f7d;
$nj-jaune-trophees: #FFC300;

$trophies-filters: #005faa;
$trophies-status : #5fc2ea;
$event-date : #5fc2ea;
$scroll-down : #5fc2ea;
$vert-nav : #5fc2ea;
$pager : #5fc2ea;
$bg-3d : #ebeef0;
$title-video-detail: #009fe3;
$map-bg: #275da9;
$map-overlay-text-bg: #4675b6;
$map2018-overlay-text-bg: #4795d1;
$entity-name: #009fe3;

$direct: #e00019;

$innovweek-bleu-clair : #5fc2ea;
$innovweek-bleu-fonce : #005faa;

$innovweek-bleu-turquoise: #37B9C3;

$fb-color: #3664a2;
$twt-color: #1da1f2;

$gris-sombre: #191919;
$gris-slogan: #a79f9a;
$gris-border: #d0d0d0;
$gris1: #f0f0f0;
$gris2: #d7d7d7;
$gris3: #afafaf;
$gris4: #7d7d7d;
$gris5: #414042;
$gris-texte: $gris5;
$gris-border-tableau: #ebebeb;
$gris-fonce:#212121;
$gris-fonce-iw:#414141;
$fond-map-iw-2018: #2783c9;

$iw2018-color2: #3b257f;
$iw2018-color3: #69ae23;
$iw2018-color4: #008dd1;

$nj-bleu-ciel2: #00b1eb;

// Couleurs refonte
$gris-blanc: #FAFAFA;
$gris-tres-clair: #F5F5F5;
$gris-clair: #EEEEEE;
$gris: #AFAFAF;
$gris2: #BDBDBD;
$gris3: #e0e0e0;
$bleu-gradient: #00BCFD;
$vert-gradient: #23D2B5;
$main-color: var(--nj-color-palette-blue-600);
$secondary-color: var(--nj-color-palette-teal-500);
$rouge-bis: #E74A50;
$orange: #F78A31;
$bleu-okta: #007DC1;
$violet: #AD4B9E;
$color-procurement: #005A78;
$gris-medium: #757575;
$jaune: #fbdb3c;
$violet: #1f106d;
// fonts



// ########  #######  ##    ## ########  ######
// ##       ##     ## ###   ##    ##    ##    ##
// ##       ##     ## ####  ##    ##    ##
// ######   ##     ## ## ## ##    ##     ######
// ##       ##     ## ##  ####    ##          ##
// ##       ##     ## ##   ###    ##    ##    ##
// ##        #######  ##    ##    ##     ######



%title-font-black {
  font-family: 'Lato', arial, sans-serif;
  font-style: normal; font-weight: 900;
}
%title-font-bold {
  font-family: 'Lato', arial, sans-serif;
  font-style: normal; font-weight: 700;
}
%title-font-reg {
  font-family: 'Lato', arial, sans-serif;
  font-style: normal; font-weight: 400;
}
%title-font-med {
  font-family: 'Lato', arial, sans-serif;
  font-style: normal; font-weight: 400;
}
%title-font {
  font-family: 'Lato', arial, sans-serif;
  font-style: normal; font-weight: 300;
}
%title-font-light {
  font-family: 'Lato', arial, sans-serif;
  font-style: normal; font-weight: 300;
}
%title-font-thin {
  font-family: 'Lato', arial, sans-serif;
  font-style: normal; font-weight: 100;
}

%text {
  font-family: 'Lato', arial, sans-serif;
  font-style: normal; font-weight: 400;
}
