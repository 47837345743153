.nj-discussion{
  &__message{ border-bottom: 1px solid $gris; padding: 30px;
    &.js-discussions-message-model{ display: none; }
  }
  &__messages{ }
  &__message-header{ justify-content: space-between; display: flex; }
  &__message-author{ font-size: 14px; }
  &__message-by{ margin-left: 0; margin-right: auto; font-size: 11px; }
  &__message-container{ display: flex; justify-content: space-between; align-items: center; }
  &__message-delete{ width: 10px;
    img{ width: 10px; }
  }
  &__message-content{ margin-top: 20px; @extend %title-font-reg; font-size: 14px; color: $gris-fonce; flex: 1; }
  &__message-date{ font-size: 12px; color: $gris; }
  &__form-header{ display: flex; margin-bottom: 20px; align-items: flex-end; justify-content: flex-start; @extend %title-font-reg; font-size: 16px; color: var(--nj-color-text-label);
    span{
      &.to{ color: $main-color; font-size: 12px; margin-right: 5px; }
      &.new{ display: block; margin-right: 20px; }
    }
  }
  &__message-dest{ display: none; justify-content: flex-start; @extend %title-font-bold; font-size: 14px; color: $gris-fonce; margin-top: 10px;
    span{ @extend %title-font-bold; color: $main-color; font-size: 14px; margin-right: 10px; }
    &.active{ display: flex;  }
  }
  &__form{ box-sizing: border-box; display: flex; flex-direction: column; margin-bottom: 30px;
    textarea{ width: 100%; box-sizing: border-box; height: 150px; margin-bottom: 0; padding: 10px; }
    button[type=submit]{ margin-top: 0; align-self: flex-end; }
    .chosen-container{ border: none; border-bottom: 1px solid $gris; width: auto !important; flex: 1; }
    .chosen-choices{ border: none; box-shadow: none; background: none;
      li.search-choice{ background: none !important; box-shadow: none !important; border: none !important;
        span{ @extend %title-font-reg; font-size: 12px; color: $main-color; }
      }
    }
    span.new{ font-size: 12px; }
  }
  &__messages-empty{

  }
  .js-discussions-private-container{ width: 0; overflow: hidden; transition: width 300ms ease-in-out; display: flex; align-items:
  center; justify-content: flex-end;
    &.active{ width: 80px; }
    input{ margin: 2px 0 0 8px; }
  }
}