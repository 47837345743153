.nj-personal-data{
  &__container{ padding: 50px 0;
    .wysiwyg {
      p{ font-size: 16px !important; color: #000000; }
      ul{ font-size: 16px !important; }
    }
    header{ display: none; }
  }
  &__actions-container{ display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 50px;
    a{ display: inline-block;
      &:first-of-type{ margin-bottom: 30px; }
    }
  }
  &__delegate-container{ display: flex; justify-content: space-between; margin-top: 30px; margin-bottom: 20px; }
  &__delegate-container-left{ width: 50%; padding-right: 60px; box-sizing: border-box;
    p{ margin: 0; }
  }
  &__delegate-container-right{ width: 50%; display: flex;
    figure{ width: 65px; height: 65px; margin-right: 20px; background: url('../images/mail-circled.svg')no-repeat center center; background-size: contain; }
    figcaption{
      p strong{ color: $main-color !important; @extend %title-font-bold; }
      p{ margin: 0; }
      ul{ list-style: none; padding-left: 0;
        li{ margin-bottom: 2px;
          &:first-of-type{ color: $main-color !important; @extend %title-font-bold; }
        }
      }
    }
  }
  &__intro{ background: var(--nj-color-base-primary); color: white; font-size: 20px; @extend %title-font-med; padding: 50px 0; text-align: center; }
}

@media screen and (max-width: 600px){
  .nj-personal-data{
    &__actions-container{ padding: 0 20px;
      .nj__btn{ display: flex; justify-content: center; align-items: center; line-height: 1.3; font-size: 16px; padding: 10px 20px; height: auto;
        &:first-of-type{ margin-bottom: 20px; }
      }
    }
  }
}