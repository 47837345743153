.nj-page{
   &__container{ position: relative; width: 1120px; margin: 0 auto; height: auto; padding-top: 40px;
     p{ margin-top: 20px; @extend %title-font-reg; }
     strong, b{  @extend %title-font-bold; }
     ul{ list-style: disc; padding-left: 30px; list-style-image: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); @extend %title-font-reg; margin-top: 10px;
       li{ margin-bottom: 5px; font-size: 16px; }
     }
     ol{ padding-left: 30px; list-style: decimal; @extend %title-font-reg; margin-top: 10px; font-size: 16px; }
     h2{ margin-top: 40px; @extend %title-font-reg; color: $main-color; line-height: 1.3;
      strong{ @extend %title-font-reg; }
     }
     h3{ @extend %title-font-reg; margin-top: 20px; margin-bottom: 10px; color: $secondary-color; }
     iframe{ margin: 30px 0; }
   }
}

.nj-page-header{ height: 650px; z-index: 0; overflow: hidden; position: absolute; top: 0; left: 0; width: 100%;
  img{ object-fit: cover; width: 100%; height: 100%;  }
}

.nj-collapse-imgs{ display: flex; justify-content: space-between; flex-wrap: nowrap;
  .nj-collapse-img{ flex: 1; opacity: 0.4; height: auto; position: relative;
    img{ max-width: 100%; width: 100%; object-fit: cover; height: 250px; }
    &:hover{ opacity: 1; cursor: pointer; }
    &.show{ opacity: 1; }
    h2{ position: absolute; bottom: 30px; left: 20px; color: #fff; margin-bottom: 0; }
  }
}

.dl-block{ display: flex; height: 200px; width: 450px;
  &__border{ background: linear-gradient(135deg,#0af,#23d2b5); height: 100%; width: 35%; padding: 10px; }
  &__left{ background: var(--nj-color-base-purple); height: 100%; justify-content: center; align-items: center; display: flex; }
  &__image{ width: 70%; }
  &__right{ height: 100%; width: 65%; box-shadow: var(--nj-shadow-level-2-dp); padding: 20px; display: flex; flex-direction: column; justify-content: space-between; }
  &__text{  }
}

.nj-sustainable-theme{
  &__image{ width: 250px;
    img{ object-fit: cover; height: 100%; width: 100%; }
  }
}

.cb-page{
  &__container{ z-index: 1; position: relative; background-color: #fff; padding-top: 60px; padding-bottom: 60px; margin-top: 200px; }
  &__title-container{ margin: 0 40px 60px; display: flex; }
}

.cb-section{ margin: 0 40px 80px;
  &__content-centered{ max-width: 750px; width: 100%; margin: 0 auto; text-align: center; z-index: 2;
    .cb-section__row{ z-index: 1; position: relative; }
    &--bordered{ border: 24px solid var(--nj-color-base-gray-100); padding: 50px 80px; }
  }
  &__row{ display: flex; justify-content: space-between; position: relative; }
  &__col{ width: 100%; position: relative;
    &--fourth{ width: calc((100% - 150px) / 4); }
    &--third{ width: calc((100% - 100px) / 3); }
    &--three-fourth{ width: calc((100% - ((100% - 150px) / 4)) - 50px); }
    &--two-thirds{ width: calc((100% - ((100% - 100px) / 3)) - 50px); }
    &--mid{ width: calc((100% - 50px) / 2); }
    &--image-container{
      img{ width: 100%; height: 100%; object-fit: cover; }
    }
    &--bg{ border: 32px solid var(--nj-color-base-gray-100); width: calc((100% - 100px) / 3); padding: 30px; color: #fff; transition: border 200ms ease-in-out;
      h2{ font-weight: 900; }
      h3{ font-weight: 900; }
      &:nth-of-type(1){ background-color: var(--nj-color-base-purple); }
      &:nth-of-type(2){ background-color: var(--nj-color-base-blue-corporate); }
      &:nth-of-type(3){ background-color: var(--nj-color-base-green-java); }
      &:hover{ color: #fff; border: 32px solid var(--nj-color-base-gray-200); }
    }
    img{ max-width: 100%; }
    h3{ font-size: 1.5rem; }
  }
  &__title{
    &--overflow{ width: 100%;  }
  }
  &--section1{
    h2{ margin-top: 20px; }
  }
  &--section3{ background: var(--nj-color-base-blue-venice);
    .cb-section__col{
      &--text{ padding: 70px 90px; color: #fff;
        h2, h3, p, span, label { color: #fff !important; }
      }
    }
  }
  &--section4{
    .cb-section__row{ justify-content: flex-end; }
    .cb-section__col{
      &:first-of-type{ position: absolute; right: calc(50% - 80px); z-index: 2; }
    }
  }
  &--section5{
    .cb-section__row{ justify-content: flex-end; }
    .cb-section__col{
      &:first-of-type{ background: var(--nj-color-base-blue-venice); position: absolute; left: 0; z-index: 2; box-sizing: border-box; padding: 40px 80px; color: #fff; }
    }
  }
  &--section13{
    .cb-section__row{ justify-content: flex-start; flex-wrap: wrap; }
    .cb-section__col{ width: 100%; margin: 0;
      &:last-of-type{ background: var(--nj-color-base-blue-venice); box-sizing: border-box; padding: 20px 40px; color: #fff; }
      img{ margin: 0; }
      p > a{ background: none !important; text-decoration: underline; color: #fff !important; }
    }
  }
  &--section15{ display: flex; align-items: center; justify-content: center; }
  &--section16{
    .cb-section__col--bg{ width: 50%; }
  }
  &--section6{ margin: 0 0 100px 0;
    img{ margin-bottom: -50px; }
  }
  &--section17{ margin-bottom: 40px;
    .nj-card{
      &--cover{ background-size: cover; }
      &__overlay{ width: 100%; }
    }
    ul{ list-style-type: square; padding-left: 30px; overflow: hidden;
      ::marker{ color: var(--nj-color-palette-teal-600); }
      li{ margin-bottom: 5px; }
    }
  }
}

.nj-section{
  &--quinconce{ position: relative; width: 1120px; margin: 0 auto; height: auto; display: flex; }
  &--container{ position: relative; width: 1120px; margin: 0 auto; height: auto;
    h2{ @extend %title-font-reg; color: $main-color; text-align: center; max-width: 70%; margin: 100px auto 20px; line-height: 1.3;
      strong, b{ @extend %title-font-bold; }
      &.nj__title-left{ max-width: 100%; margin: 100px 0 20px; text-align: left; }
    }
    h3{ @extend %title-font-reg; font-size: 26px; line-height: 1.3; color: $secondary-color; margin-top: 20px;
      &.centered{  margin: 20px auto 0; text-align: center; max-width: 70%; display: block; }
    }
    p{  @extend %title-font-reg; font-size: 16px; max-width: 70%; text-align: center; margin: 10px auto 0;
      strong, b{ @extend %title-font-bold; }
    }
  }
  &--gradient{ background: var(--nj-color-base-blue-venice); padding: 100px 0;
    h2{ @extend %title-font-bold; color: #fff; margin: 0 auto 20px; line-height: 1.3;
      strong, b{ @extend %title-font-black; }
    }
    h3{ color: #fff; @extend %title-font-reg; }
    p{  @extend %title-font-reg; font-size: 16px; margin: 20px auto 0; color: #fff;
      strong, b{ @extend %title-font-bold; }
    }
  }
  &--simple{
    h2{ @extend %title-font-reg; color: $main-color; margin: 100px auto 20px; line-height: 1.3;
      strong, b{ @extend %title-font-bold; }
      &.nj__title-left{ max-width: 100%; margin: 100px 0 20px; text-align: left; }
    }
    p{  @extend %title-font-reg; font-size: 16px; margin: 10px auto 0;
      strong, b{ @extend %title-font-bold; }
    }
  }
}

.nj-quinconce{ overflow: visible;
  &__picto-container{ background: var(--nj-color-base-blue-venice); width: 40%; display: flex; align-items: center; justify-content: center;
    img{ width: 40%; height: auto; max-height: 160px; }
  }
  &__image-container{ width: 40%; display: flex; align-items: center; justify-content: center;
    img{ width: 100%; height: 100%; object-fit: cover; }
  }
  &__content{ width: calc(60% + 50px); box-sizing: border-box; padding: 60px; box-shadow: 4px 4px 20px #0003; top: -50px; left: -50px; z-index: 2; position: relative; background-color: white;
    .nj-quinconce__title{ @extend %title-font-bold; color: $main-color; margin-bottom: 20px; font-size: 32px; }
    .nj-quinconce__subtitle{ color: $secondary-color;  @extend %title-font-reg; font-size: 20px; }
    .nj-quinconce__text{ @extend %title-font-reg; font-size: 16px; margin-top: 20px;
      a{ color: $main-color; }
    }
    h3{ @extend %title-font-reg; font-size: 26px; line-height: 1.3; color: $secondary-color; margin-top: 20px; }
    ul{ list-style: disc; padding-left: 30px; list-style-image: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); @extend %title-font-reg; margin-top: 10px;
      li{ margin-bottom: 5px; }
    }
    ol{ padding-left: 30px; list-style: decimal; @extend %title-font-reg; margin-top: 10px; font-size: 16px; }
  }
}

.nj-cards{ display: flex; justify-content: center; width: 70%; margin: 40px auto 40px;
  .nj-card{ width: 33%; background: $gris-clair; padding: 30px; box-sizing: border-box; z-index: 1; display: flex; align-items: center; flex-direction: column;
    &__title{  @extend %title-font-bold; margin-bottom: 20px; text-align: center; font-size: 24px; line-height: 1.3; color: $gris-fonce; }
    &__text{  @extend %title-font-reg; font-size: 16px; margin-top: 20px; line-height: 1.2; max-width: 100%; margin-top: 10px;
      &:first-of-type{ margin-top: 0; }
    }
    &:hover{ box-shadow: 4px 4px 20px #0003; z-index: 2; background-color: $gris-tres-clair; }
    img{ margin-bottom: 20px;  }
  }
}

.nj-thirds{ display: flex; justify-content: space-between; width: 100%; margin: 40px auto 40px;
  .nj-third{ width: calc((100% - 40px) / 3); padding: 0; box-sizing: border-box; z-index: 1; display: flex; align-items: center; flex-direction: column;
    h3{ margin-top: 0; text-align: center; }
    p{ text-align: center; }
    img{ width: 100%; height: 100%; object-fit: cover; display: block; margin: 0 auto; }
    .nj-third__image-container{ height: 250px; width: 100%; margin-bottom: 0; box-sizing: border-box; display: flex; align-items: flex-end; }
    .nj-third__content{ padding: 20px; text-align: center; }
    span{ margin-top: 20px; }
  }
}

.nj-doubles{ display: flex; justify-content: space-between; width: 100%; margin: 40px auto 40px;
  .nj-double{ width: calc((100% - 20px) / 2); padding: 0; box-sizing: border-box; z-index: 1; display: flex; align-items: center; flex-direction: column;
    h3{ margin-top: 0; text-align: center; }
    p{ text-align: center; }
    img{ width: 100%; height: 100%; object-fit: cover; display: block; margin: 0 auto; }
    .nj-double__image-container{ height: 250px; width: 100%; margin-bottom: 0; box-sizing: border-box; display: flex; align-items: flex-end; }
    .nj-double__content{ padding: 20px; text-align: center; }
    span{ margin-top: 20px; }
  }
}

.ces-2018, .engie-at-ces-2018{
  .nj-row.nj-row--small-margin{ margin: 0;
    .nj-col.nj-col--full{  margin: 20px 0 0 0;
      h2{ margin-bottom: 0; }
    }
  }
  .nj-thirds{
    .nj-third{
      .nj-third__image-container{ height: 140px; }
    }
  }

  .nj-doubles{
    .nj-double{
      .nj-double__image-container{ height: 140px; }
    }
  }
}

.nj__btn-container{ display: flex; justify-content: center; margin: 40px 0; }

.nj-image-links{ display: flex; justify-content: space-between; align-items: center; margin: 40px 0;
  .nj-image-link{ width: calc((100% - 20px) / 2); display: flex; flex-direction: column; align-items: center;
    &__image-container{ height: 285px; width: 100%; }
    img{ object-fit: cover; width: 100%; height: 100%; }
    span{ background-color: $main-color; color: #ffffff; height: 45px; line-height: 45px; padding: 0 20px;  @extend %title-font-bold; font-size: 14px; box-shadow: 4px 4px 20px #0003; }
  }
  &--three{
    .nj-image-link{ width: calc((100% - 40px) / 3); }
  }
}


.nj-row{ display: flex; width: 100%; margin: 100px 0; justify-content: space-between; flex-wrap: wrap; align-items: flex-start;
  .nj-col{ text-align: left;
    h2{ margin-top: 0; text-align: left; @extend %title-font-bold; font-size: 32px; line-height: 1.3; max-width: 100%; color: $main-color; }
    h3{ @extend %title-font-reg; font-size: 26px; line-height: 1.3; color: $secondary-color; margin-top: 20px; }
    ul{ list-style: disc; padding-left: 30px; list-style-image: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); @extend %title-font-reg; margin-top: 10px;
      li{ margin-bottom: 5px; }
    }
    ol{ padding-left: 30px; list-style: decimal; @extend %title-font-reg; margin-top: 10px; font-size: 16px; }
    p{ text-align: left; max-width: 100%;
      ol{ padding-left: 30px; list-style: decimal; @extend %title-font-reg; margin-top: 10px; font-size: 16px; }
    }

    a{ color: $main-color; }
    .nj-socials-icons{ display: flex; margin: 10px 0;
      a{ width: 45px; height: 45px; text-align: center; margin-right: 10px;
        img{ width: 20px; }
      }
    }
    img, iframe { max-width: 100%; }
    iframe{  width: 100%; }
    &--fourth{ width: calc((100% - 150px) / 4); }
    &--mid{ width: calc((100% - 50px) / 2);
      &.no-margin{ width: 50%; }
      &.padding{ padding: 5%; box-sizing: border-box;
        h2{  }
        h3{ margin-top: 10px; }
        p{ margin-top: 20px;  }
      }
      .image-cover{  width: 100%; height: 100%; object-fit: cover; display: block; }
      iframe{ height: 300px; }
    }
    &--full{ width: 100%; margin: 30px 0; }
    &--content-right{ display: flex; flex-wrap: wrap; justify-content: flex-end; }
    &--content-center{ display: flex; flex-wrap: wrap; justify-content: center; }
    &--full-image{ width: 100%; margin: 30px 0; height: 33vh;
      img{ width: 100%; height: 100%; object-fit: cover; display: block; }
    }
  }
  &--small-margin{ margin: 20px 0; }
  &--vertical-center{ align-items: center; }
  &--no-margin{ margin: 0; }
}

.nj-section--container{
  .nj-quinconce-blocks{ display: flex; margin: 100px 0;
    &__content-container{ width: 50%;
      h2{ text-align: left; @extend %title-font-bold; font-size: 32px; line-height: 1.3; max-width: 100%; margin: 80px 40px 20px; }
    }
    &__content{ box-shadow: 4px 4px 20px #0003; text-align: left; padding: 60px; margin-left: -120px; background-color: #ffffff;
      p{ text-align: left; max-width: 100%; @extend %title-font-reg; font-size: 16px; }
    }
    &__image-container{ width: 50%; height: 350px; }
    &__image{ object-fit: cover; width: 100%; height: 100%; }
  }
  .nj-quinconce-dispo{ display: flex; margin: -60px 0 100px 0;
    &__content-container{ width: 60%; z-index: 1;
      h2{ text-align: left; @extend %title-font-bold; font-size: 32px; line-height: 1.3; max-width: 100%; margin: 0; }
      h3{ @extend %title-font-reg; font-size: 26px; line-height: 1.3; color: $secondary-color; margin-top: 20px; }
      p{ margin-top: 20px; }
    }
    &__content{ box-shadow: 4px 4px 20px #0003; text-align: left; padding: 60px; background-color: #ffffff; margin-left: 0;
      p{ text-align: left; max-width: 100%; @extend %title-font-reg; font-size: 16px; }
    }
    &__image-container{ width: calc(40% + 120px); height: 360px; margin-top: 22%; margin-left: -120px; z-index: 0; }
    &__image{ object-fit: cover; width: 100%; height: 100%; }
    &--reverse{
      .nj-quinconce-dispo__image-container{ margin-top: 0; margin-right: -120px; margin-left: 0; }
      .nj-quinconce-dispo__content-container{ padding-top: 60px; display: flex; flex-direction: column;
        a{ align-self: flex-end; }
      }
    }
  }
}

.nj-section--full{
  .nj-quinconce-blocks{ display: flex; margin: 100px 0;
    &__content-container{ width: calc(50% + 120px); position: relative; z-index: 2; margin-top: 60px; margin-bottom: auto; margin-right: -120px; box-sizing: border-box; background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); display: flex; justify-content: flex-end; height: auto; }
    &__actions-container{ display: flex; position: absolute; bottom: 0; margin-bottom: -45px; right: 0;
      a{
        &:first-of-type{ margin-right: 1px; }
      }
    }
    &__content{ text-align: left; padding: 50px 60px 50px 0; color: #ffffff; width: calc((1120px + 240px) / 2); box-sizing: border-box;
      h2{ text-align: left; @extend %title-font-bold; font-size: 32px; line-height: 1.3; max-width: 100%; margin: 0 0 30px; color: #ffffff; }
      p{ text-align: left; max-width: 100%; @extend %title-font-reg; font-size: 16px; margin-top: 10px; }
      a{ margin-top: 40px; }
    }
    &__image{ width: 55%; height: auto; margin-right: 10%; }

    &__image-container{ width: calc(50% + 120px); z-index: 1; height: auto; margin-left: -120px; display: flex; align-items: center; justify-content: flex-end; background: $gris-clair;
      &--image-full{ background: transparent;
        .nj-quinconce-blocks__image{ width: 100%; height: 100%; margin-right: 0; object-fit: cover; }
      }
    }
  }
}

.contentbuilder-helper{
  h3{ @extend %title-font-reg; font-size: 26px; line-height: 1.3; color: $secondary-color; margin-top: 20px; }
  ul{ list-style: disc; padding-left: 30px; list-style-image: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); @extend %title-font-reg; margin-top: 10px;
    li{ margin-bottom: 5px;
      b{ @extend %title-font-bold; }
    }
  }
  p{ text-align: left; max-width: 100%; margin-top: 20px; }
}

.nj__image{
  &--full-width{ width: 100%; }
}

.nj-dispo-main-section{ width: 94%; padding-left: calc((100% - 1120px) / 2); margin-bottom: 250px; display: flex; justify-content: flex-end; background: var(--nj-color-base-blue-venice); position: relative;
  &__content-container{ box-sizing: border-box; padding: 100px 75px 320px 0; display: flex; }
  &__content{
    h2{ text-align: left; @extend %title-font-bold; font-size: 24px; line-height: 1.3; max-width: 100%; margin: 0 0 30px; color: #ffffff; }
    p{ text-align: left; max-width: 100%; @extend %title-font-reg; font-size: 16px; margin-top: 10px; color: #ffffff;  }
  }
  &__image{ margin-right: 75px; }
  &__cards{ position: absolute; top: 400px; z-index: 2; display: flex; justify-content: space-between; width: 1120px; }
  &__card{ background-color: $gris-clair; width: calc((100% - 60px)/3); margin-top: 0; height: 400px; display: block; box-shadow: 4px 4px 20px #0003; padding: 30px 40px; box-sizing: border-box; transition: all 100ms ease-in-out; display: flex; flex-direction: column; justify-content: flex-start;
    h3{ @extend %title-font-bold; font-size: 20px; line-height: 1.3; margin-top: 10px; }
    p{ @extend %title-font-reg; font-size: 13px; margin-top: 10px; }
    span{ background-color: #ffffff; margin-bottom: 0; margin-top: auto; width: 100%; box-sizing: border-box; justify-self: flex-end; color: $main-color; padding: 5px 0; text-align: center; @extend %title-font-bold; display: block; font-size: 13px; min-height: 50px; height: auto; line-height: 1.2; display: flex; justify-content: center; align-items: center; }
    &:hover{ margin-top: 0; background-color: #ffffff; cursor: pointer;
      span{ color: #ffffff; background-color: $main-color; }
    }
    &:first-of-type{ margin-top: 40px;
      &:hover{  margin-top: 0;  }
    }
    &:nth-of-type(2){ margin-top: 60px;
       &:hover{  margin-top: 20px; }
    }
    &:last-of-type{ margin-top: 20px;
      &:hover{  margin-top: -20px;  }
    }

  }
}

.nj__contentbuilder{ border: 10px solid; border-image-source: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); border-image-slice: 1; margin-top: 100px;
  .nj-row{ align-items: flex-start; }
  &.is-builder{
    .row{ display: block; }
  }
}


.row{
  .nj-col{
    ul{ list-style: disc; padding-left: 30px; list-style-image: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); @extend %title-font-reg; margin-top: 10px;
      li{ margin-bottom: 5px; }
    }
  }
  .nj-col{
    span{ font-family: 'Lato', arial, sans-serif !important; font-weight: 400; font-size: 16px; }
  }
  .nj-section--container{
    h2{
      &.nj__title-left{ margin: 0 0 20px; }
    }
  }
  .nj-image-links{ margin-bottom: 0; }
  .nj-dispo-main-section{ margin-bottom: 250px; }
  .nj-quinconce-blocks{ margin: 100px 0 30px; }
}

.is-tool.is-column-tool.active{ left: 90px !important;
  .cell-add{ display: none !important; }
}


#CookieDeclarationUserStatusPanel{ border: 1px solid var(--nj-color-base-primary); padding: 20px; }

#CookieDeclarationUserStatusLabelOn, #CookieDeclarationUserStatusLabelMulti, #CookieDeclarationUserStatusLabelOff{ color: var(--nj-color-base-primary); margin-bottom: 20px; font-weight: bold; margin-bottom: 20px; display: block; }

#CookieDeclarationConsentIdAndDate span{ margin-bottom: 10px; }

#CookieDeclarationUserStatusLabelConsentDate, #CookieDeclarationUserStatusLabelConsentId{ font-weight: bold; display: block; }

#CookieDeclarationChangeConsent a{ background-color: var(--nj-color-base-primary); padding: 8px 32px; color: white; font-weight: bold; font-size: 14px; }

#new-page{
  .kromi-list__actions{ display: flex; justify-content: flex-end; width: 100%; margin: 20px auto; }
  .nj-admin-filters{ background: var(--nj-color-base-gray-100)!important;
    form{ display: flex; justify-content: space-between; align-items: flex-end; padding: 20px;
      .form-group{ flex: 1; margin-right: 20px; }
    }
  }
}

@media screen and (max-width: 1300px){
  .nj__contentbuilder{ padding: 0 0 0 80px; }
}

@media screen and (max-width: 1250px){
  .nj-page{
    &__container, &__title-container{ width: 100%; padding: 20px 20px 0 20px; box-sizing: border-box; }
  }
  .nj-section--quinconce{ width: 90%; }
  .nj-section--container{ width: 100%; padding: 0 20px 0 20px; box-sizing: border-box;  }
  .nj-section--full{
    .nj-quinconce-blocks{ width: 100%;
      &__content-container{ padding-left: 60px; margin-top: 0; width: calc(50% + 60px); margin-right: -60px; }
      &__image-container{ margin-top: 100px; margin-left: -60px; width: calc(50% + 60px); }
      &__content{ width: 100%; }
    }
  }

  .cb-section__col--bg{ border: 16px solid var(--nj-color-base-gray-100);
    h3{ font-size: 1rem;}
    &:hover{ border: 16px solid var(--nj-color-base-gray-200); }
  }

  .nj-row{
    .nj-col{ padding: 0 20px; box-sizing: border-box;
      &.no-margin{ padding: 0;
        &.padding{ padding: 5%; }
      }
    }
  }
  .nj-dispo-main-section{
    &__content-container{  }
    &__cards{ width: 100%;  }
    &__card{ width: calc((100% - 60px)/3); height: auto;
      p{ margin-bottom: 20px; }
    }
  }
}

@media screen and (max-width: 1080px){
  .nj-dispo-main-section{ width: 100%;
    &__cards{ top: 450px; }
  }
}


@media screen and (max-width: 1200px){
  .cb-section{
    &--section2{
      .cb-section{
        &__col{
          &--text{ padding: 40px 60px; }
        }
      }
    }
    &--section5, &--section6{
      .cb-section__col--full{ padding: 0 30px; }

    }
  }
}

@media screen and (max-width: 992px){
  .cb-page__container.container{ max-width: 100% !important; }
  .cb-section{ margin: 0 40px 80px;
    &--section2{
      .cb-section{
        &__col{
          &--text{ padding: 20px 30px; }
        }
      }
    }
  }
  .cb-section.cb-section--section14 {
    .cb-section__row { flex-wrap: wrap; }
  }
  .cb-section__col--bg{ width: 100%; margin-bottom: 20px; }
}

@media screen and (max-width: 600px){
  .cb-page__container.container{ max-width: 100% !important; }
  .cb-section{ margin: 0 20px 40px;
    &--section3{
      .cb-section{
        &__col{
          h2.t-tera{ font-size: 2rem; }
          &:first-of-type{ right: calc(50% - 10px); }
        }
      }
    }
    &--section4{
      .cb-section{
        &__col{
          &:first-of-type{ padding: 20px 40px; }
        }
      }
    }
    &--section10{
      .cb-section{
        &__col{
          &:last-of-type{ margin-top: 20px; }
        }
      }
    }
  }
}

@media screen and (max-width: 980px){
  .nj-section--quinconce{ width: 100%; padding-left: 50px;  box-sizing: border-box; }
  .nj-quinconce{
    &__picto-container{ height: 300px; }
  }
  .nj-cards{ width: 100%; }
  .nj-section--container{
    .nj-quinconce-dispo{
      &__content-container{ width: 100%; }
      &__content{
      }
      &__image-container{ width: 0; display: none; }
    }
  }
  .nj-dispo-main-section{ width: 100%;
    &__content-container{  }
    &__cards{ width: 100%;  }
    &__card{ height: auto;
      p{ margin-bottom: 30px; }
    }
  }
}

@media screen and (max-width: 900px) {
  .nj-section--container {
    h2 {
      margin: 30px auto 20px;
    }
  }
  .nj-section--full {
    .nj-quinconce-blocks { margin: 50px 0;

    }
  }
  .nj-dispo-main-section{ height: auto; width: 100%; padding: 50px 75px; box-sizing: border-box; margin-bottom: 100px;
    &__content-container{ flex-direction: column; padding: 0;
      h2{ margin-top: 20px; }
    }
    &__image{ width: 100px; }
    &__cards{ position: relative; width: 100%; top: 50px; flex-direction: column; }
    &__card{ width: 100%; margin-top: 0; margin-bottom: 40px;
      &:first-of-type{ margin-top: 0;
        &:hover{  margin-top: 0;  }
      }
      &:nth-of-type(2){ margin-top: 0;
        &:hover{  margin-top: 0; }
      }
      &:last-of-type{ margin-top: 0;
        &:hover{  margin-top: 0;  }
      }
    }
  }
}

@media screen and (max-width: 800px){
  .nj-cards{ flex-wrap: wrap;
    .nj-card{ width: calc(50% - 10px); margin: 5px; }
  }
  .nj-image-links{ flex-wrap: wrap; justify-content: center;
    .nj-image-link{ width: calc(50% - 40px); margin: 5px 20px 20px; }
  }
  .nj-row{ flex-direction: column; margin: 30px 0;
    .nj-col{ margin: 20px 0;
      &--mid, &--third, &--fourth{ width: 100%;
        &.no-margin{ width: 100%; }
      }
      img{ margin: 0 auto; display: block; }
    }
  }
  .nj-doubles{ flex-wrap: wrap;
    .nj-double{ width: 100%; margin: 0; margin-bottom: 20px; margin-bottom: 30px;
      .nj-double__image-container{ width: 100%; height: auto;
        img{ object-fit: unset; width: auto !important; height: 140px !important; }
      }
    }
  }
  .nj-thirds{ flex-wrap: wrap;
    .nj-third{ width: 100%; margin: 0; margin-bottom: 20px; margin-bottom: 30px;
      .nj-third__image-container{ width: 100%; height: auto;
        img{ object-fit: unset; width: auto !important; height: 140px !important; }
      }
    }
  }
}

@media screen and (max-width: 800px){
  .cb-section{
    &--section2{
      .cb-section__row{ flex-wrap: wrap;
        .cb-section__col{ width: 100%; }
      }
    }
    &--section1{
      .cb-section__row{ flex-wrap: nowrap; flex-direction: column-reverse;
        .cb-section__col{ width: 100%;
          &:last-of-type{ margin-bottom: 20px; }
        }
      }
    }
    &--section4{
      .cb-section__row{ flex-wrap: wrap; flex-direction: row;
        .cb-section__col{ width: 100%; position: relative;
          &:last-of-type{ margin-bottom: 20px; }
        }
      }
    }
    &--section10{
      .cb-section__row{ flex-wrap: wrap; flex-direction: row;
        .cb-section__col{ width: 100%; position: relative;
          &:last-of-type{ margin-bottom: 20px; }
        }
      }
    }
    &--section17{
      .cb-section__row{ flex-wrap: wrap; flex-direction: row;
        .cb-section__col{ width: 100%; position: relative; margin-bottom: 40px; }
      }
    }
  }
}


@media screen and (max-width: 700px){
  .nj-quinconce{ flex-direction: column; padding: 0; margin-top: -50px;
    &__picto-container{ height: 250px;
      img{ height: 100px; }
    }
    &__content{ left: 50px; width: calc(100% - 100px); }
  }
  .nj-section--full{
    .nj-quinconce-blocks{ flex-direction: column-reverse; margin: 30px 0 150px;
      &__content-container{ width: 80%; }
      &__image-container{ width: 80%; align-self: flex-end; margin-bottom: -40px; margin-top: 0; padding: 80px 0; justify-content: center;
        img{ width: 80%; margin-right: 0; }
      }
      &__content{  }
    }
  }

  .nj-section--container{
    .nj-quinconce-blocks{ flex-direction: column; margin-top: 50px;
      &__content-container{ width: 100%; box-sizing: border-box; margin-left: 0;
        h2{ margin: 40px 10%; }
      }
      &__image-container{ width: 100%; align-self: flex-end; margin-bottom: 0;
        img{ width: 100%; margin-right: 0; }
      }
      &__content{ margin-left: 0;  }
    }
  }
  .nj-row{  margin: 10px 0 20px;

  }
}

@media screen and (max-width: 600px){
  .nj-quinconce{
    &__content{ padding: 40px; }
  }
  .nj-cards{ flex-wrap: wrap;
    .nj-card{ width: 100%; margin: 0; margin-bottom: 20px; }
  }
  .nj-doubles{ flex-wrap: wrap;
    .nj-double{ width: 100%; margin: 0; margin-bottom: 20px; }
  }
  .nj-thirds{ flex-wrap: wrap;
    .nj-third{ width: 100%; margin: 0; margin-bottom: 20px; }
  }
  .nj-image-links{
    .nj-image-link{ width:100%; margin: 0 0 20px 0; }
  }
  .nj-section--full{
    .nj-quinconce-blocks{
      &__content-container{ width: 100%; }
      &__image-container{ width: 100%; margin-bottom: 0; }
      &__actions-container{ flex-direction: column; margin-bottom: -90px;
        a{
          &:last-of-type{ margin-top: 1px; }
        }
      }
    }
  }
  .nj-section--container{
    .nj-quinconce-dispo{
      &__content{ padding: 30px; }
    }
  }
  .nj-row{
    .nj-col{ margin: 10px 0;
      h2{ margin-bottom: 0;  }
    }
  }
  .nj-section--container{
    h2, p{ max-width: 90%; }
  }
}

@media screen and (max-width: 500px){
  .nj-quinconce{ flex-direction: column; padding: 0;
    &__picto-container{ height: 250px; width: 100%; }
    &__content{ left:0; width: 100%; padding: 20px 30px; top: 0; }
  }
  .nj-section--full{
    .nj-quinconce-blocks{
      &__content-container{ padding-left: 0; }
      &__image-container{  }
      &__content{ padding: 20px 30px; }
    }
  }
  .nj-dispo-main-section{ padding: 50px 30px;
    &__content{
      h2{ font-size: 24px; }
    }
    &__card{
      h3{ font-size: 20px; }
    }
  }
  .nj-section--container{
    .nj-quinconce-dispo{
      &__content-container{
        h2{ font-size: 24px; }
        h3{ font-size: 20px; }
      }
    }
    .nj-quinconce-blocks{ margin-bottom: 50px;
      &__content{ padding: 30px;  }
    }
  }
  .nj-cards{ }
  .nj-row{
    &--vertical-center{ align-items: center; }
    .nj-col{ align-items: flex-start; justify-content: flex-start;
      img{ margin: 0 auto; display: block; }
    }
  }
  .cb-section{
    &--section7{
      .cb-section__content-centered--bordered{ padding: 20px; }
    }
  }
}

@media screen and (max-width: 375px){
  .nj-dispo-main-section{ padding: 30px 20px 50px; }
  .nj-section--container{ padding-left: 20px; padding-right: 20px;
    .nj-quinconce-dispo{ margin-bottom: 50px;
      &--reverse{
        .nj-quinconce-dispo__content-container{ padding-top: 0; }
      }
    }
  }
}


