.nj-expert-access{ margin: 50px 0; display: flex;
  &__by{ display: flex; flex-direction: row; justify-content: space-between; align-items: flex-start; width: 100%;

  }
  &__user{ display: flex; flex-direction: column; }
  &__admin{ background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); width: 100%; padding: 40px; box-sizing: border-box; color: #fff; margin: 0 0 0;
    h2{  @extend %title-font-black; }
    p{ margin: 20px 0 0 0; }
  }
  &__admin-container{ display: flex; flex-wrap: wrap; width: 350px; align-items: flex-start; margin: 0 0 auto;
    a{ width: auto; flex: 1; box-sizing: border-box; display: flex; justify-content: space-between; margin-top: 0; margin-bottom: auto;
      &.nj__btn--refuse{ color: $rouge-bis; }
      &.complete{
        span{ font-size: 14px; }
      }
      img{ width: 10px; }
      span{ font-size: 13px; }
    }
  }
  &__status{ display: block;
    span{ padding: 0 5px; margin-left: 5px; font-size: 12px; height: 20px; line-height: 20px; display: inline-block; }
    label{ font-size: 12px; color: $gris; }
    &--waiting, &--tocomplete{
      span{ background-color: $orange; color: #fff; height: 20px; line-height: 20px; }
    }
    &--validated{
      span{ background-color: $vert-gradient; color: #fff; height: 20px; line-height: 20px; }
    }
    &--refused{
      span{ background-color: $rouge-bis; color: #fff; height: 20px; line-height: 20px; }
    }
  }
  &__subtitle{ margin-top: 40px; font-size: 20px; @extend %title-font-bold; color: $main-color; }
  &__legend{ margin: 10px 0 20px; font-size: 14px; }
  &__content{ margin-left: 40px; width: calc(100% - 390px); }
  &__pp-container{ display: flex; }
  &__pp{ width: 80px; height: 80px; border-radius: 50%; overflow: hidden; margin-right: 20px;
    img{ width: 100%; }
  }
  &__name{ @extend %title-font-bold; display: flex; flex-direction: column; font-size: 24px; }
  &__bu{ @extend %title-font-reg; }
  &__entity{ @extend %title-font-reg; font-size: 12px; margin-top: 10px; }
  &__text{ margin: 20px 0; background-color: $gris-tres-clair; padding: 30px; }
  &__tags{ color: $main-color; font-size: 12px;
    ul{ display: flex; flex-wrap: wrap;
      li{ padding: 5px 8px; border: 1px solid $main-color; margin-right: 5px; margin-bottom: 5px; }
    }
  }
  .nj-discussion{
    &__chat{ background-color: $gris-tres-clair; margin-top: 10px;
      span.new{ margin-bottom: 0; @extend %title-font-bold; color: #000; }
      textarea{ background-color: transparent; border: 1px solid $gris3; }
    }
    &__message{ border-top: 1px solid $gris3; border-bottom: none;
      &:first-of-type{ border-top: none; }
    }
    &__messages-empty{ text-align: center; padding-bottom: 20px; font-size: 12px; }
    &__message-content{ font-size: 14px; }
    &__message-author{ font-size: 14px; }
    &__message-date{ font-size: 12px; }
    .nj__btn{ height: 30px; line-height: 30px; }
  }
}

.nj-expert-access-status{ display: block; padding: 0 5px; font-size: 12px; @extend %title-font-bold; display: inline-block;
  &--waiting, &--tocomplete{
     background-color: $orange; color: #fff; height: 20px; line-height: 20px;
  }
  &--validated{
    background-color: $vert-gradient; color: #fff; height: 20px; line-height: 20px;
  }
  &--refused{
    background-color: $rouge-bis; color: #fff; height: 20px; line-height: 20px;
  }
}

#admin-expert-access{
  thead th:last-of-type{ display: none; }
  .list-actions-buttons{ display: none; }
  .page-header{ display: none; }
  .nj__form{ margin-top: 30px; }
  tr{
    th:first-of-type, td:first-of-type{ width: 150px; }
    th:nth-of-type(3), td:nth-of-type(3){ width: 150px; text-align: center; }
    th:nth-of-type(4), td:nth-of-type(4){ width: 150px;
      a.nj__btn.nj__btn--primary.nj__btn--supersmall{ @extend %title-font-bold; font-size: 12px; }
    }
  }
  .input_line{ padding: 0;  }
}
