.nj-home-banner{
	&__content{ position: relative; width: 100%; display: flex; align-items: stretch; background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); box-sizing: border-box; }
	&__image-container{ z-index: 1; position: relative; width: 33%; display: flex; align-items: center; justify-content: center; box-sizing: border-box; }
	&__image{ width: 235px; height: auto; z-index: 2; position: relative; max-height: 235px; }
	&__text-container{ z-index: 1; padding: 45px 75px; width: 67%; box-sizing: border-box;
		&--nopaddingleft{ padding-left: 0; }
	}
	&__image-cover{ object-fit: cover; height: 100%; width: 100%; position: absolute; top: 0; left: 0; z-index: 0; }
	h2{ @extend %title-font-black; font-size: 32px; line-height: 40px; color: #fff; max-width: 600px;
		strong{  @extend %title-font-black; }
	}
	p{ margin-top: 25px; @extend %title-font-reg; font-size: 16px; color: #fff; }
}

.nj-home-banner-engie{ background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); padding: 30px 80px; text-align: center; position: relative; width: 1120px; margin: 0 auto; box-sizing: border-box; margin-top: 160px; height: auto;
	h2{ @extend %title-font-reg; font-size: 40px; line-height: 50px; color: #fff; display: inline-block;
		strong{  @extend %title-font-black; }
	}
	p{ margin-top: 25px; @extend %title-font-reg; font-size: 20px; color: #fff; }
}

.nj-home-submissions{ margin-top: 100px;
	&__container{ position: relative; width: 1120px; margin: 0 auto; height: auto;  }
	&__list{ display: flex; justify-content: space-between; flex-wrap: wrap; }
	&__image-container{
		img{ width: 40px; }
	}
	&__card{ width: calc((100% - 64px) / 3); display: block; padding: 40px; box-shadow: 4px 4px 20px #0003; box-sizing: border-box; border-top: 3px solid $main-color; }
	&__infos{  }
	&__section-title{ margin-bottom: 40px; color: #000000;
		strong{ color: $main-color; }
	}
	&__header{ display: flex; justify-content: space-between; }
	&__depositary{ @extend %title-font-bold; font-size: 20px;  }
	&__bu{ @extend %title-font-reg; font-size: 16px; color: $gris; }
	&__company{ @extend %title-font-reg; font-size: 16px; color: $gris; }
	&__date{ @extend %title-font-reg; font-size: 14px; color: $gris; }
	&__type{ @extend %title-font-bold; font-size: 14px; color: $main-color; text-transform: uppercase;
		&--projects{ color: $violet; }
		&--projects_procurement{ color: $color-procurement; }
		&--submissions-2{ color: $secondary-color; }
	}
	&__type-container{ display: flex; margin-top: 60px; justify-content: space-between; }
	&__title{ @extend %title-font-bold; font-size: 24px; margin-top: 24px; }
	&__actions-container{ display: flex; justify-content: flex-end; }
}

.nj-edito{ margin-top: 100px;
	&__title-container{ position: relative; width: 1120px; margin: 0 auto; height: auto;
		.nj-news__title{ color: $main-color; }
	}
	&__container{ position: relative; width: 100%; display: flex; }
	&__news-container{ width: 60%; display: flex; flex-direction: column; align-items: flex-start;
		.nj__btn{ display: inline-flex; }
	}
	&__news{ display: flex; flex-direction: column; }
	&__news-actions{ display: flex; justify-content: flex-end; width: 100%; }
	&__event-container{ width: 40%; padding-right: 30px; box-sizing: border-box;  }
}

.nj-actu{ display: flex; border: none;
	&__image-container{ width: 50%; height: 285px; display: block; }
	&__image{ object-fit: cover; width: 100%; height: 100%; }
	&__content{ width: 50%; padding: 30px; box-sizing: border-box; display: flex; flex-direction: column; justify-content: flex-start; }
	&__title{ @extend %title-font-bold; font-size: 22px; text-transform: none; line-height: 1.5; margin-top: 20px; }
	&__theme{ @extend %title-font-reg; text-transform: uppercase; font-size: 14px; color: $secondary-color; }
	&__separator{ margin: 0 10px; font-size: 14px; color: $gris; }
	&__date{ @extend %title-font-reg; font-size: 14px; color: $gris-fonce; }
	&:last-of-type{ border-top: 5px solid $nj-bleu-ciel;
		.nj-actu__content{ text-align: right; }
	}
}

.nj-edito-event{ display: flex; border: none; background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); height: calc(100% - 45px); padding: 10%; flex-direction: column; box-sizing: border-box;
	&__title{ @extend %title-font-black; font-size: 48px; text-transform: none; line-height: 1.2; color: #ffffff; }
	&__description{ color: #ffffff; margin-top: 20px; @extend %title-font-reg; font-size: 20px; line-height: 1.3; }
	&__actions{ display: flex; justify-content: flex-end; }
	&__date{ @extend %title-font-black; font-size: 20px; line-height: 1.3; color: #ffffff; margin-bottom: 10px; text-transform: uppercase; }
	&__place{  @extend %title-font-reg; font-size: 20px; text-transform: uppercase; margin-bottom: 20px; color: #ffffff; display: flex; align-items: center;
		img{ margin-right: 10px; }
	}
}

.nj-ecosystem{ display: flex; margin-top: 140px; height: auto;
	&__left{ width: 63%; display: flex; justify-content: space-between; }
	&__right{ width: 37%; display: flex; flex-direction: column; height: 100%; }
	&__bloc{ height: 250px; width: 100%; background-color: $main-color; color: #ffffff; display: flex; justify-content: center; align-items: center; @extend %title-font-reg; font-size: 24px; flex-direction: column;
		img{ height: 90px; margin-bottom: 10px; }
		&:last-of-type{ background-color: $secondary-color;
			img{ height: 110px; }
		}
	}
	&__image-container{ width: 54%; margin-left: -27%; margin-top: -40px; height: 580px; }
	&__image{ object-fit: cover; width: 100%; height: 100%; object-position: top left; }
	.nj-ecosystem-edito{ width: 66%; z-index: 1; height: 500px; display: flex; flex-direction: column; padding: 10% 5% 10% 20%; box-sizing: border-box; background: #ffffff 0% 0% no-repeat padding-box; box-shadow: 4px 4px 20px #0003;
		&__title{ font-size: 28px; text-transform: none; line-height: 1.3; max-width: 70%; @extend %title-font-bold; margin-top: 30px; color: $secondary-color; }
		&__subtitle{ font-size: 24px; color: $main-color; @extend %title-font-reg; }
		&__text{ margin-top: 10px; font-size: 16px; @extend %title-font-reg; }
	}
}

.nj-themes{ margin-top: 50px;
	&__container, &__title-container { position: relative; width: 1120px; margin: 0 auto; height: auto; }
	&__title { margin-bottom: 40px; color: $main-color; }
	&__container{ display: flex; flex-wrap: wrap; }
	&__theme{ width: 25%; height: 285px; display: flex; background: $gris-tres-clair; display: flex; align-items: center; justify-content: center; color: #000000; font-size: 24px; @extend %title-font-reg; flex-direction: column;
		&:nth-of-type(2){ background: $gris-clair; }
		&:nth-of-type(3){ background: $gris-clair; }
		&:nth-of-type(6){ background: $gris-clair; }
		&:nth-of-type(7){ background: $gris-clair; }
		img{ height: 92px; width: auto; }
		span{ margin-top: 20px; text-align: center; }
	}
	&__text{ width: 50%; display: flex; align-items: flex-start; justify-content: center; flex-direction: column; font-size: 16px; @extend %title-font-reg; padding-right: 10%; box-sizing: border-box; padding: 20px 10% 20px 0;
		strong{ margin-top: 20px; }
	}
	&--big{ margin-top: 50px;
		.nj-theme{ width: 50%; padding: 40px 60px; height: auto; box-sizing: border-box; justify-content: flex-start;
			span{ margin-top: 20px; }
			p{ font-size: 16px; @extend %title-font-reg; text-align: center; margin-top: 10px; }
		}
	}
}

@media screen and (max-width: 1250px){
	.nj-themes{
		&__container, &__title-container{ width: 100%; padding: 20px 20px 0 20px; box-sizing: border-box; }
	}
	.nj-edito{
		&__title-container{ width: 100%; padding: 20px 20px 0 20px; box-sizing: border-box; }
	}
	.nj-home-banner{
		&__container{ width: 100%; padding: 20px 20px 0 20px; box-sizing: border-box; }
	}
	.nj-home-submissions{
		&__container{ width: 90%; }
	}
	.nj-home-banner-engie{ width: calc(100% - 40px); margin-left: 20px; margin-right: 20px; }
}

@media screen and (max-width: 1080px){
	.nj-home-submissions__card{ width: calc((100% - 30px) / 2); margin-bottom: 32px;
		&:nth-of-type(2n){ margin-left: 30px; }
	}

}

@media screen and (max-width: 1000px){
	.nj-themes{
		&__theme{ width: 50%; }
		&__text{ padding: 40px;  }
		&__theme{
			&:nth-of-type(1){ background: $gris-tres-clair; }
			&:nth-of-type(2){ background: $gris-clair; }
			&:nth-of-type(3){ background: $gris-clair; }
			&:nth-of-type(4){ background: $gris-tres-clair; }
			&:nth-of-type(5){ background: $gris-tres-clair; }
			&:nth-of-type(6){ background: $gris-clair; }
		}
	}
}



//@media screen and (max-width: 900px){
//
//	.nj-ecosystem{ flex-wrap: wrap; flex-direction: column;
//		&__left{ width: 100%; }
//		&__right{ width: 100%; display: flex; flex-direction: row; }
//		&__bloc{ width: 50%; }
//	}
//
//}

@media screen and (max-width: 900px){
	.nj-edito{
		&__container{ flex-wrap: wrap; flex-direction: column; }
		&__event-container{ width: 100%; }
		&__news-container{ width: 100%; margin-top: 50px; }
	}
	.nj-themes{
		&__text{ display: none; }
		&__theme{
			&:nth-of-type(1){ background: $gris-tres-clair; }
			&:nth-of-type(2){ background: $gris-clair; }
			&:nth-of-type(3){ background: $gris-clair; }
			&:nth-of-type(4){ background: $gris-tres-clair; }
			&:nth-of-type(5){ background: $gris-tres-clair; }
			&:nth-of-type(6){ background: $gris-tres-clair; }
		}
	}
	.nj-ecosystem{ margin-top: 100px; flex-wrap: wrap; flex-direction: column;
		&__left{ flex-wrap: wrap; width: 100%; }
		&__right{ width: 100%; display: flex; flex-direction: row; }
		&__bloc{ width: 50%; }
		.nj-ecosystem-edito{ width: 90%; padding: 40px; height: auto; }
		.nj-ecosystem__image-container{ width: 90%; margin-left: 0; margin-top: -100px; margin-left: 10%; height: 500px; }
		.nj-ecosystem__right{ width: 80%; margin-left: 20%; margin-top: -60px; }
	}
}

@media screen and (max-width: 750px){
	.nj-home-banner{ margin-top: 100px;
		&__content{ flex-direction: column; }
		&__image-container{ margin-right: 0; width: 100%; min-height: 200px; }
		&__text-container{ width: 100%; }
		&__image{ width: 235px; height: auto; }
	}

	.nj-home-banner-engie{ margin-top: 100px; }

	.nj-home-submissions__card{ width: 100%;
		&:nth-of-type(2n){ margin-left: 0; }
	}
}


@media screen and (max-width: 600px){
	.nj-edito{
		&__news-container{
			.nj-edito__news{ padding: 0 30px; }
			.nj-actu{ display: flex; flex-direction: column;
				&__theme{ font-size: 12px; }
				&__date{ font-size: 12px; }
				&__image-container{ width: 100%; height: 285px; display: block; order: 1; }
				&__content{ width: 100%; padding: 30px; order: 2; box-sizing: border-box; display: flex; flex-direction: column; justify-content: flex-start; }
				&__title{ font-size: 16px; text-transform: none; line-height: 1.5; margin-top: 20px; text-align: left; }
				&__header{ text-align: left; }
			}
		}
	}
	.nj-ecosystem{ margin-top: 100px;
		.nj-ecosystem__right{
			.nj-ecosystem__bloc{ height: 200px; font-size: 16px;
				img{ height: 100px; }
			}
		}
	}
	.nj-themes{
		&--big{
			.nj-theme{ width: 100%; background-color: $gris-tres-clair;
				&:nth-of-type(2){ background-color: $gris-clair; }
				&:nth-of-type(3){ background-color: $gris-tres-clair; }
				&:nth-of-type(4){ background-color: $gris-clair; }
				&:nth-of-type(5){ background-color: $gris-tres-clair; }
				&:nth-of-type(6){ background-color: $gris-clair; }
			}
		}
	}
	.nj-home-banner-engie{ padding: 30px;
		h2{ font-size: 30px; line-height: 40px; }
		p{ font-size: 18px; }
	}
}

@media screen and (max-width: 500px){
	.nj-themes{
		&__theme{ width: 50%; height: 200px;
			&:nth-of-type(1){ background: $gris-tres-clair; }
			&:nth-of-type(2){ background: $gris-clair; }
			&:nth-of-type(3){ background: $gris-clair; }
			&:nth-of-type(4){ background: $gris-tres-clair; }
			&:nth-of-type(5){ background: $gris-tres-clair; }
			&:nth-of-type(6){ background: $gris-tres-clair; }
			span{ font-size: 18px; }
		}
	}
	.nj-ecosystem{
		.nj-ecosystem__right{
			.nj-ecosystem__bloc{ height: 150px; font-size: 16px;
				img{ height: 70px; }
			}
		}
	}

	.nj-home-banner{
		&__text-container{ padding: 30px; }
	}
}
@media screen and (max-width: 450px) {
	.nj-home-banner-engie {	padding: 30px;
		h2{ font-size: 24px; line-height: 30px; }
		p{ font-size: 16px; }
	}
}

@media screen and (max-width: 400px){
	.nj-ecosystem{ margin-top: 50px;
		.nj-ecosystem__right{
			.nj-ecosystem__bloc{ font-size: 12px; }
		}
	}
	.nj-themes {
		&__theme { width: 100%;
			&:nth-of-type(1){ background: $gris-tres-clair; }
			&:nth-of-type(2){ background: $gris-clair; }
			&:nth-of-type(3){ background: $gris-tres-clair; }
			&:nth-of-type(4){ background: $gris-clair; }
			&:nth-of-type(5){ background: $gris-tres-clair; }
			&:nth-of-type(6){ background: $gris-clair; }
		}
	}
	.nj-edito{ margin-top: 80px;
		&__event-container{ padding-right: 0; }
		.nj-edito-event__actions{ flex-direction: column; padding: 0 20px; }
		.nj-edito-event__title{ font-size: 24px; }
		.nj-edito-event__description{ font-size: 16px; }
		.nj-news__title{ font-size: 30px; margin-bottom: 20px; }
	}
	.nj-edito__news-container{
		.nj-actu__content{ padding: 20px; }
	}
	.nj-themes{ margin-top: 50px;
		&__title{ font-size: 30px; margin-bottom: 20px; }
	}

	.nj-home-banner{
		&__text-container{ padding: 20px; }
		&__content{
			p{ font-size: 14px; }
		}
	}
}





