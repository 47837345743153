.nj-header-kromi{ height: 200px; background: $gris-tres-clair;
  &__container{ position: relative; width: 1120px; margin: 0 auto; height: 100%; display: flex; justify-content: space-between; align-items: flex-end; padding-bottom: 5vh; box-sizing: border-box; z-index: 2;}
  &__container-left{ margin-top: calc(20vh + 73px); }
  &__container-right{ margin-top: calc(16vh + 73px); position: relative; padding-top: 105px; }
  &__image-background{ display: block; object-fit: cover; height: 100%; width: 100%; position: absolute; top: 0; left: 0; }
  &__title{ font-size: 54px; @extend %title-font-bold; line-height: 1; color: $gris-sombre;
    span{ @extend %title-font; }
    &--admin{
      small{ @extend %title-font-reg;
        label{ @extend %title-font-bold; color: $main-color; }
      }
    }
  }
  &__role{ @extend %title-font-light; color: $gris2; font-size: 32px; }
  &__home-subtitle{ font-size:30px; line-height: 40px; @extend %title-font-reg; color: #ffffff; width: 480px; margin-top: 24px;
    &--visitor{ font-size: 26px; @extend %title-font-light; }
  }
  &__actions{ margin-top: 40px; flex-wrap: wrap; }
  &__action-container{ display: inline-flex; flex-direction: column;
    h3{ @extend %title-font-black; color: #fff; font-size: 20px; margin-bottom: 20px; }
    .nj-header-kromi__action{ font-size: 18px; justify-content: center;
      img{ height: 11px; margin-left: 20px; }
    }
  }
  &__action{ @extend %title-font-bold; font-size: 17px; padding: 0 20px; height: 60px; line-height: 60px; display: inline-flex; position: relative; align-items: center;
    img{ height: 0; margin-left: 0; transition: margin-left 100ms ease-in-out; }
    &--propose-innovation{ background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); color: white; z-index: 1; width: auto; box-sizing: border-box; }
    &--calls-for-projects{ background: #ffffff; margin-left: -60px; z-index: 0; padding: 0 20px 0 75px; color: $secondary-color; }
    &--engie{ margin-left: 0; padding-left: 20px; box-sizing: border-box; width: 400px; }
    &:hover{
      img{  margin-left: 20px; height: 11px;  }
    }
  }
  &__patch{ position: absolute; right: -50px; top: -5px;
    &--ti{ border-radius: 50%; width: 140px; top: 10px; }
  }
  &__content{ width: 440px;
    .nj-header-kromi__promise-title{ background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); color: white; @extend %title-font-bold; font-size: 20px; padding: 60px 60px 40px 60px;
      strong{ @extend %title-font-black; }
      &--engie{ padding: 50px 40px 40px; }
    }
    .nj-header-kromi__promise-text{ @extend %title-font-reg; font-size: 16px; padding: 60px; background: #ffffff 0% 0% no-repeat padding-box; box-shadow: 4px 4px 20px #0003;
      &--engie{ padding: 40px;
        strong{ display: block; }
        p{ margin-bottom: 20px; font-size: 14px;
          &:last-of-type{ margin-bottom: 0; }
        }
      }
    }
  }
  .nl-subscription__subscribe{ margin-right: 20px; margin-left: auto; }
  &--edito{ height: 420px; background: url('../images/image-edito.jpg') no-repeat top center; background-size: cover; position: relative;
    .nj-header-kromi__container{  justify-content: flex-end; flex-wrap: wrap; flex-direction: column; padding-bottom: 10vh; }
    .nj-header-kromi__title{ width: 100%; color: #ffffff; }
    .nj-header-kromi__subtitle{ margin-bottom: 40px; margin-top: auto; width: 100%; color: #ffffff; @extend %title-font-reg; font-size: 20px; text-transform: uppercase; }
  }
  &--notre-dispo{ height: 420px; background: url('../images/home-background.jpg') no-repeat center center; background-size: cover; position: relative;
    .nj-header-kromi__container{  justify-content: flex-end; flex-wrap: wrap; flex-direction: column; padding-bottom: 10vh; }
    .nj-header-kromi__title{ width: 100%; color: #ffffff; }
    .nj-header-kromi__subtitle{ margin-bottom: 40px; margin-top: auto; width: 100%; color: #ffffff; @extend %title-font-reg; font-size: 20px; text-transform: uppercase; }
  }
  &--your-innovations{ height: 320px; background: url('../images/home-background.jpg') no-repeat center center; background-size: cover; position: relative;
    .nj-header-kromi__container{  justify-content: flex-end; flex-wrap: wrap; flex-direction: column; padding-bottom: 5vh; }
    .nj-header-kromi__title{ width: 100%; color: #ffffff; }
  }
  &--page-simple{ height: 350px; background: url('../images/image-edito.jpg') no-repeat top center; background-size: cover; position: relative;
    .nj-header-kromi__container{  justify-content: flex-end; flex-wrap: wrap; flex-direction: column; }
    .nj-header-kromi__title{ width: 100%; color: #ffffff; }
  }
  &--contentbuilder{ height: 400px; position: relative; overflow: hidden;
    .nj-header-kromi__container{  justify-content: flex-end; flex-wrap: wrap; flex-direction: column; padding-bottom: 100px; }
    .nj-header-kromi__title{ width: 100%; color: #ffffff; }
  }
  &--vivatech{ height: 350px; background: url('../images/vivatech2.jpg') no-repeat center center; background-size: cover; position: relative;
    .nj-header-kromi__container{  justify-content: flex-end; flex-wrap: wrap; flex-direction: column; }
    .nj-header-kromi__title{ width: 100%; color: #ffffff; }
  }
  &--apac{ height: 350px; background: url('../images/engie-apac.jpg') no-repeat top -250px center; background-size: cover; position: relative; }
  &--china{ height: 350px; background: url('../images/engie-china.png') no-repeat top -250px center; background-size: cover; position: relative; }
  &--latam{ height: 350px; background: url('../images/engie-factory-latam.jpg') no-repeat top -300px center; background-size: cover; position: relative; }
  &--personal-data{ height: 400px; background: url('../images/innovators-background.jpg') no-repeat center center; background-size: cover; position: relative; display: flex; justify-content: center; align-items: center; flex-direction: column;
    .nj-header-kromi__title{ color: #ffffff; }
    .nj-header-kromi__logo{ width: 142px; margin-bottom: 90px; }
  }
  &--trophies{ height: 200px;
    .nj-header-kromi__container{ padding-bottom: 0; }
    .nj-header-kromi__title{ margin-bottom: 5vh; }
  }
  &--submission-form{ height: 200px;
    .nj-header-kromi__container{ padding-bottom: 0; }
    .nj-header-kromi__title{ margin-bottom: 5vh; }
  }
  &__tabs{ display: flex; }
  &__tab{ background: $main-color; color: #ffffff; height: 60px; width: 160px; display: flex; align-items: center; justify-content: center; margin-left: 1px; @extend %title-font-reg; font-size: 18px;
    &.active{ background-color: #ffffff; box-shadow: 4px 4px 20px #0003; color: $main-color; @extend %title-font-bold; }
  }
  &--submission{ height: 200px; }
  &--admin-submission{ height: auto; padding-top: 200px;
    .nj-header-kromi__title{ font-size: 40px; }
  }
}

.nj-header--expand-lg{
  .nj-header__nav-link{ margin: 0 1rem;  }
  &.nj-header--sm{
    nav.container{ max-width: 100%;
      .nj-header__nav-link{ font-size: 0.75rem; }
    }
  }
}

@media screen and (max-height: 900px){
  .nj-header-kromi{


  }
}

@media screen and (max-height: 800px){
  .nj-header-kromi{

  }
}

@media screen and (min-width: 800px) and (max-width: 1340px){
  .nj-header-kromi{
    &--home{
      .nj-header-kromi__container{ width: 90%; }
    }
  }
}

@media screen and (max-width: 1250px){
  .nj-header-kromi{
    &__container{ width: 100%; padding: 0 20px 5vh; box-sizing: border-box; }
    &--home{ }
  }
  .nj-header-kromi:not(.nj-header-kromi--home){
    .nj-header-kromi__container{ width: 90%; padding: 0 0 20px 0; }
    &.nj-header-kromi--trophies{
      .nj-header-kromi__container{ flex-direction: column; justify-content: flex-end; align-items: flex-start; padding-bottom: 0; }
    }
    &.nj-header-kromi--edito, &.nj-header-kromi--page-simple{
      .nj-header-kromi__container{  padding: 0 0 80px 0;  }
    }
    &.nj-header-kromi--contentbuilder{
      .nj-header-kromi__container{  padding: 0 0 100px 0;  }
    }
    &.nj-header-kromi--notre-dispo{ height: 400px;
      .nj-header-kromi__container{ padding-bottom: 100px; }
      .nj-header-kromi__subtitle{ margin-bottom: 20px; }
    }
  }
}

@media screen and (max-width: 980px){
  .nj-header-kromi:not(.nj-header-kromi--home){
    .nj-header-kromi__container{ width: 100%; padding: 20px;  }
    &.nj-header-kromi--trophies{
      .nj-header-kromi__container{ padding-bottom: 0; }
    }
    &.nj-header-kromi--edito, &.nj-header-kromi--page-simple{
      .nj-header-kromi__container{  padding: 20px 40px 80px; }
    }
    .nj-header-kromi__subtitle{ margin-bottom: 15px; }
  }
}

@media screen and (max-width: 900px){
  .nj-header-kromi:not(.nj-header-kromi--home){ height: 230px;
    &.nj-header-kromi--page-simple, &.nj-header-kromi--edito{ height: 340px; }
    &.nj-header-kromi--notre-dispo{ height: 400px;
      .nj-header-kromi__container{ padding-bottom: 100px; }
    }
    &.nj-header-kromi--contentbuilder{ height: 400px;
      .nj-header-kromi__container{ padding: 0 20px 100px 20px; }
    }
    &.nj-header-kromi--submission{ height: 80px; }
    &.nj-header-kromi--submission-form{ height: auto;
      .nj-header-kromi__container{ flex-direction: column; padding-top: 120px; align-items: flex-start; }
    }
    &.nj-header-kromi--page-simple{ background: url('../images/image-edito.jpg') no-repeat top 80px center; }
    &.nj-header-kromi--apac{ background: url('../images/engie-apac.jpg') no-repeat top -200px center; }
    &.nj-header-kromi--china{ height: 350px; background: url('../images/engie-china.png') no-repeat top -250px left; }
    &.nj-header-kromi--latam{ height: 350px; background: url('../images/engie-factory-latam.jpg') no-repeat top -300px center; }
  }
  .nj-header-kromi--edito{ background: url('../images/image-edito.jpg') no-repeat top 80px center; }
}

@media screen and (max-width: 800px){
  .nj-header-kromi{
    &--home{ background: url('../images/home-background-800.jpg') no-repeat center center; background-size: cover;
      .nj-header-kromi__container{ width: 90%; }
      &.engie{ background: url('../images/home-background-engie.jpg') no-repeat top 80px left -350px; background-size: cover; }
    }
    &__title{ font-size: 40px; }
  }
}

@media screen and (max-width: 700px){
    .nj-header-kromi:not(.nj-header-kromi--home):not(.nj-header-kromi--submission):not(.nj-header-kromi--submission-form){ height: auto; padding-top: 120px;
      .nj-header-kromi__container{ flex-direction: column; align-items: flex-start;
        .nj-header-kromi__title{ margin-bottom: 20px; }
      }
    }
}

@media screen and (max-width: 670px){
  .nj-header-kromi{
    &--home{
      .nj-header-kromi__action{ width: 100%; padding: 0 40px;
        &--calls-for-projects{ margin-left: 0; box-sizing: border-box; }
      }
      .nj-header-kromi__action-container{ width: 100%;
        &:last-of-type{ margin-top: 30px; }
      }
    }
    &--personal-data{ padding-bottom: 30px;
      .nj-header-kromi__logo{ margin-bottom: 30px; }
      .nj-header-kromi__title{ text-align: center; }
    }
    &--vivatech{ background: url('../images/vivatech2.jpg') no-repeat bottom -100px center; background-size: cover; }
  }
}

@media screen and (max-width: 600px){
  .nj__header{
    &--home{ background: url('../images/home-background-600.jpg') no-repeat top right; background-size: cover; }
    &.nj-header-kromi--trophies{
      .nj-header-kromi__tabs{ width: 100%;
        .nj-header-kromi__tab{ width: calc(100% / 3); height: 45px; font-size: 16px; }
      }
    }
  }

}

@media screen and (max-width: 470px){
  .nj-header-kromi{
    &--home{
      .nj-header-kromi__title{ font-size: 50px; line-height: 50px; width: 100%;
        small{ font-size: 20px; line-height: 30px; white-space: normal; }
      }
      .nj-header-kromi__home-subtitle{ width: 100%; font-size: 24px; line-height: 28px; }
      .nj-header-kromi__container-left{ width: 100%; }
      .nj-header-kromi__content{ width: 100%;
        .nj-header-kromi__promise-title{ padding: 40px 30px 20px 30px; font-size: 16px; }
        .nj-header-kromi__promise-text{ padding: 30px; font-size: 14px; }
      }
      .nj-header-kromi__action{ padding: 0 20px; font-size: 15px;
      }
      &.engie{ background: url('../images/home-background-engie.jpg') no-repeat top -200px left -500px; background-size: cover; }
    }
    &--vivatech{ background: url('../images/vivatech2.jpg') no-repeat bottom center; background-size: cover; }
    &__container-left{ margin-top: 150px; }
    &__action{
      img{ display: none;  }
    }
    &__patch{ width: 100px; top: -16px; }
    &__container-right{padding-top: 60px; }
  }
}

@media screen and (max-width: 450px){
  .nj-header-kromi{
    &--home{
      &.engie{ background: url('../images/home-background-engie.jpg') no-repeat top 80px left -360px; background-size: cover; }
    }
  }
}

@media screen and (max-width: 400px){
  .nj-header-kromi{
    &--home{
      .nj-header-kromi__action{ font-size: 14px; }
      .nj-header-kromi__title{ font-size: 40px; padding: 0 10px; box-sizing: border-box; }
      &.engie{ background: url('../images/home-background-engie.jpg') no-repeat top 80px left -360px; background-size: cover; }
    }
  }
}

@media screen and (max-width: 361px){
  .nj-header-kromi{
    &--home{
      .nj-header-kromi__title{ font-size: 35px; line-height: 45px; }
      .nj-header-kromi__actions{ margin-top: 15px; }
      .nj-header-kromi__action{ font-size: 10px; }
      .nj-header-kromi__content{
        .nj-header-kromi__promise-title{ font-size: 17px; }
      }
    }
  }
}
