.emailing-status{ display: block; text-align: center;
  &--sent{ background-color: $vert-gradient; padding: 4px 8px; color: #fff; @extend %title-font-bold; font-size: 12px; }
  &--in_progress{ background-color: $orange; padding: 4px 8px; color: #fff; @extend %title-font-bold; font-size: 12px; }
}
.emailing-recap{ width: 100%; margin-top: 20px;
  li{ display: flex; flex-wrap: nowrap;
    span{ @extend %title-font-reg; margin-right: 5px; }
    span, strong{ white-space: nowrap; font-size: 12px; }
  }
}
.emailing-actions{ box-sizing: border-box;
  a{ width: 100%; box-sizing: border-box; text-align: center; justify-content: center; }
}

.emailings-unsubscribe{ display: flex; flex-direction: column; align-items: center; justify-content: center; padding-top: 60px;
  img{ margin-bottom: 20px; }
}

#emailings-preview{
  ol, ul{ margin: 0 40px; }
}