.nj-notifications{ background-color: #fff; border-left: 1px solid $gris-clair; z-index: 2001; height: 100vh; overflow: hidden; transition: right 300ms ease-in-out; width: 50vw; position: fixed; top: 0; right: -50vw;
  &__header{ display: flex; justify-content: space-between; padding: 30px 40px; }
  &__sender{ @extend %title-font-bold; display: block; font-size: 14px; }
  &__message{ font-size: 14px; @extend %title-font-reg; }
  &__title{ color: $main-color; }
  &__date{ color: $gris; @extend %title-font-reg; font-size: 12px; display: block; margin-top: 10px; }
  &__delete{ position: absolute; right: 20px; top: 20px;
    img{ width: 8px; }
  }
  &__content{ color: #000000;
    ul{ max-height: calc(100vh - 140px); overflow: auto;
      li{ padding: 20px 40px 20px 60px; @extend %title-font-reg; border-bottom: 1px solid $gris-clair; position: relative;
        &:hover{ background-color: $gris-tres-clair; }
        &:last-of-type{ border-bottom: none; }
        &:before{ content: ''; width: 10px; height: 10px; border-radius: 50%; position: absolute; display: block; background-color: $gris-clair; left: 40px; top: 25px; }
        &.unread{
          &:before{ background-color: $orange; }
        }
      }
    }
  }
  &.active{ right: 0; }
}

.nj-notifications-picto{ width: 45px; height: 45px; display: flex; align-items: center; justify-content: center; position: relative;
  img{ width: 30px; }
  &.unread{
    &:before{ content: ''; width: 10px; height: 10px; border-radius: 50%; position: absolute; display: block; background-color: $orange; left: 26px; top: 10px; }
  }
  &--home-display, &--pages-template1, &--users-login, &--users_settings-inscription, &--users-passwordforgotten, &--users-password, &--pages-load, &--submissions-home, &--submissions-result{
    img{ filter: invert(1); }
  }
  &.scrolled{
    img{ filter: invert(0); }
  }
}

.nj-notifications-overlay{ position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(15,30,41,0.5); z-index: 2000; display: none; align-items: center; justify-content: center;
  &.active{ display: flex;  }
}

@media screen and (max-width: 1100px){
  .nj-notifications{ width: 100vw; right: -100vw; }
}