.nj-login{ height: 83vh; padding-top: 140px; box-sizing: border-box; background: url('../images/innovators-background.jpg') no-repeat center center; background-size: cover; overflow: visible;  display: flex; justify-content: center; align-items: center;
  &__container{ display: flex; position: relative; width: 1120px; height: auto; justify-content: space-between; align-items: flex-start;  }
  &__form{ width: calc(55% - 60px); box-sizing: border-box; align-self: flex-start;
    h1{ font-size: 48px; @extend %title-font-bold; margin-bottom: 60px; color: #ffffff; line-height: 1.3; }
    h2{ @extend %title-font-black; font-size: 20px; color: #ffffff; }
    form.kromiforms-form{ display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: flex-start; position: relative; margin-top: 25px;
      .input_label{ font-size: 12px; @extend %title-font-bold; color: #ffffff; float: none; margin-bottom: 10px; display: block; }
      .input_area{
        .input_field{ font-size: 14px; width: 100%; background-color: rgba(255, 255, 255, 0.2); border: none; border-bottom: 1px solid #fff; color: #ffffff; float: none; border-radius: 0; height: 45px; box-sizing: border-box; padding: 0 24px; @extend %title-font-reg;
          &::placeholder{ color: #fff; }
        }
      }
      .input_line{ width: calc((100% - 20px) / 2);
        &.ligne_separation{ display: none; }
        &.remind{ float: none; display: flex; justify-content: flex-start; width: 70%; align-items: flex-start;
          .input_label{ margin: 0 20px 0 0; order: 2; }
          .input_area{ order: 1;
            .input_field{ width: 20px; height: 20px; margin-right: 10px; margin-top: 0; background: transparent; -webkit-appearance: none; border: 1px solid #fff; padding: 0;
              &:checked{ background-color: #fff; }
            }
          }
        }
        &.input_line_submits{ width: 30%;
          .btn{ margin-right: 0; width: 100%; text-align: center; justify-content: center; }
          .input_label{ display: none; }
        }
      }
      .btn-group{ display: flex; justify-content: flex-end; width: 100%;  }
      .btn{ height: 45px; line-height: 45px; font-size: 14px; @extend %title-font-bold; padding: 0 20px; line-height: 45px; float: none; display: flex; text-transform: none; border: none; -webkit-appearance: none; display: inline-flex; background-color: $main-color; color: #ffffff; border-radius: 0; }
    }
    #pass-forgotten{ color: #ffffff; text-decoration: underline; @extend %title-font-bold; font-size: 14px; margin-left: 45px; }
  }
  &__okta-container{ color: #fff; margin-top: 3vh;
    h2{ @extend %title-font-black; font-size: 20px; }
  }
  &__okta{ display: flex; color: #fff; justify-content: space-between; margin-top: 25px; align-items: center;
    span{ @extend %title-font-reg; font-size: 20px; width: 155px; }
    img{ width: 110px; filter: brightness(0) invert(1); margin: 0 40px; }
    a{ min-width: 190px; background-color: $bleu-okta; box-sizing: border-box; display: flex;
      label{ margin-left: 20px; }
      img{ width: 20px; margin: 0; }
      &:hover{
        label{ cursor: pointer; }
      }
    }
  }
  &__subscribe-container{ width: calc(45% - 60px);  box-sizing: border-box; display: flex; flex-direction: column; justify-content: flex-end; align-items: flex-end; margin-bottom: -16vh; align-self: flex-end; }
  &__subscribe{ padding: 50px 50px; box-sizing: border-box;background-color: #ffffff; box-shadow: 4px 4px 20px #0003;
    h2{ font-size: 32px; @extend %title-font-reg; color: $secondary-color; line-height: 1.3;  }
    p{ font-size: 14px; @extend %title-font-reg; margin: 20px 0; }
    h3{ font-size: 20px; @extend %title-font-bold; margin-top: 30px; line-height: 1.2; }
    hr{ border: none; border-top: 1px solid #E0E0E0; margin: 30px 0; }
    a{ margin-top: 20px; min-width: 200px; width: 50%; display: flex; justify-content: center; align-items: center;
      img{ width: 20px; margin: 0; margin-right: 20px; }
    }
  }
}
.nj-login-footer{ background-color: #fff; height: 17vh; }


@media screen and (max-height: 899px){
  .nj-login{
    &__form{
      h1{ margin-bottom: 20px; font-size: 40px; }
      form{ margin-top: 20px; }
    }
    &__okta{
      img{ width: 100px; }
    }
  }
}


@media screen and (max-width: 1250px){
  .nj-login{
    &__container{ width: 90%; }
    &__form{ width: 55%; padding-right: 20px; box-sizing: border-box; }
    &__subscribe-container{ width: 45%; padding-left: 20px; box-sizing: border-box; }
  }
}

@media screen and (max-width: 1150px){
  .nj-login{
    &__okta{
      span{ font-size: 16px; }
      a{
        label{ margin-left: 10px; font-size: 13px; }
      }
    }
  }
}

@media screen and (max-width: 980px){
  .nj-login{
    &__container{ width: 100%; padding: 20px; box-sizing: border-box; }
  }
}


@media screen and (max-width: 900px){
  .nj-login{ height: auto; padding-bottom: 80px;
    &__container{ flex-direction: column; margin: 20px 0; width: 80%; }
    &__form{ width: 100%; padding-right: 0; box-sizing: border-box;
        form.kromiforms-form{
          .btn-group{ margin-top: 30px; }
        }
    }
    &__subscribe-container{ width: 100%; padding-left: 0; box-sizing: border-box; margin-top: 50px; }
    &__subscribe{ padding: 50px; }
  }
}

@media screen and (max-width: 650px){
  .nj-login{
    &__okta{ flex-wrap: wrap;
      span{ font-size: 14px; width: calc(100% - 130px); }
      img{ max-width: 110px; margin: 0; }
      a{ width: 100%; margin-top: 20px; justify-content: center; }
    }
    &__form{
      form.kromiforms-form{
        .input_line{  width: 100%;
          &.remind{ width: 100%; }
          &.input_line_submits{ width: 100%;
            .btn-group{ margin-top: 0; margin-bottom: 20px; }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px){
  .nj-login{ height: auto; padding-top: 80px; }
}

@media screen and (max-width: 450px){
  .nj-login{
    &__container{ width: 100%; padding: 20px; }
    &__subscribe{ padding: 30px; }
    &__form{
      #pass-forgotten{ left: 20px; bottom: -20px; }
    }
  }
}