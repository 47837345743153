.nj-pre-footer{ background: url('../images/home-background.webp') no-repeat center center; background-size: cover; margin-top: 100px;
  &.collab{ background: none; background: url('../images/one-engie-awards-prefooter.webp') no-repeat center center; background-size: cover; height: 300px;
    .nj-pre-footer__overlay{ width: 100%; padding: 0; height: 100%;
      .container{ padding: 80px 0;  max-width: 700px;
        .nj-pre-footer__container{ flex-direction: column;
          .nj-pre-footer__text{ width: 100%; text-align: center; margin-bottom: 20px; }
        }
      }
    }
  }
  &__overlay{ background: rgba(15,30,41,0.5); width: 100%; padding: 30px 0; }
  &__container{ display: flex; justify-content: space-between; align-items: center; }
  &__text{ max-width: 60%;
    h3{ margin-bottom: 0; }
    h4{  }
  }
}


@media screen and (max-width: 960px){
  .nj-pre-footer{
    &__container{ display: flex; flex-direction: column; justify-content: center; align-items: center; }
    &__text{ text-align: center; margin-bottom: 20px; max-width: 80%; }
  }
}

@media screen and (max-width: 700px){
  .nj-pre-footer{
    &__text{ max-width: 80%; }
    &__overlay{ padding: 30px;  }
  }
}

@media screen and (max-width: 400px){
  .nj-pre-footer{
    &.collab{ background: none; background: url('../images/one-engie-awards-prefooter.webp') no-repeat center center; background-size: cover; height: 370px; }
  }
}