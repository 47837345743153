.nj-admin-submissions{
  &__list{ display: flex; flex-direction: column; margin-top: 20px;
    .nj-admin-submission{ display: flex; width: 100%; padding: 30px 0; margin-bottom: 20px; align-items: stretch; box-shadow: var(--nj-shadow-level-2-dp); flex-wrap: wrap;
      .nj-tag{ margin-top: 20px; }
      &__by{ width: 26%; padding-left: 30px; box-sizing: border-box; padding-right: 15px;
        span{
          &:nth-of-type(2){  }
        }
        .js-edit-depositary{
          img{ width: 12px; margin-left: 5px; }
        }
      }
      &__fav{ display: flex; font-size: 12px; @extend %title-font-reg; color: $gris; align-items: center; justify-content: center; align-self: flex-start; flex-direction: column;
        a{
          svg{ width: 20px; vertical-align: top;
            polygon{ stroke: $gris;  }
          }
          &:hover, &.active{
            svg polygon{ fill: $orange; stroke: $orange; }
          }
        }
        &--header{ color: $main-color; font-size: 14px; }
      }
      &__depositary-link{ display: flex; }
      &__projects-picto-container{ width: 70px; background-color: $gris-clair; display: flex; align-items: center; justify-content: center; height: 84px; margin-right: 20px;
        img{ width: 30px; }
      }
      &__startup{ @extend %title-font-bold; font-size: 14px; }
      &__depositary-idea{ @extend %title-font-bold; font-size: 14px; }
      &__depositary{ font-size: 12px; display: block; margin-bottom: 0; @extend %title-font-reg; color: $gris-medium; margin-top: 5px; }
      &__bu{ font-size: 12px;  margin-bottom: 0; @extend %title-font-reg; display: block; }
      &__entity{ font-size: 12px;  margin-bottom: 0; @extend %title-font-reg; display: block; color: $gris-medium; }
      &__title{ width: 22%; @extend %title-font-bold;
        span{ margin-top: 0; font-size: 12px; @extend %title-font-bold;
          &:last-of-type{ font-size: 14px; }
        }

      }
      &__admin-note{ background-color: $secondary-color; color: #fff; font-size: 14px; padding: 2px 5px; margin: 0 10px 5px 0;  }
      &__resume{ width: 35%; display: flex; justify-content: flex-start; padding-left: 20px; padding-right: 20px; box-sizing: border-box; flex-wrap: wrap; align-items: flex-start;
        a{
          img{ width: 20px; }
        }
        p{ font-size: 14px; @extend %title-font-reg;  }
      }
      &__description{ width: 100%; margin: 40px 20px 0 30px; display: none; flex-wrap: wrap; justify-content: flex-start; align-items: flex-start; box-sizing: border-box; font-size: 12px; @extend %title-font-reg; height: auto; max-height: 0; overflow: hidden;
        &.active{ max-height: 400px; display: flex; }
      }
      &__date{ display: block; font-size: 12px; @extend %title-font-reg; margin-top: 10px; color: $gris; }
      &__note{ width: 100%; display: flex; align-items: center; justify-content: center; align-self: flex-end;
        span{
          &:first-of-type{ color: var(--nj-color-base-blue-corporate); }
        }
        div{ margin: 0 10px; }
      }
      &__su-title{ font-size: 14px; @extend %title-font-reg; display: block; margin-top: 5px; }
      &__innovation{ color: $main-color; @extend %title-font-reg; text-transform: uppercase; font-size: 12px; }
      &__idea{ color: $violet; @extend %title-font-reg; text-transform: uppercase; font-size: 12px; }
      &__projects-procurement{ color: $color-procurement; @extend %title-font-reg; text-transform: uppercase; font-size: 12px; }
      &__cfp{ color: $secondary-color; @extend %title-font-reg; text-transform: uppercase; font-size: 12px; }
      &__comms{ display: flex; align-items: flex-start; position: relative; margin-right: 18px; margin-top: 1px;
        span{ background-color: white; display: block; width: 16px; height: 16px; position: absolute; color: var(--nj-color-base-blue-corporate); right: -9px; bottom: -3px; border: 1px solid var(--nj-color-base-blue-corporate); border-radius: 50%; display: flex; align-items: center; justify-content: center; font-size: 8px; }
      }
      &__messages{ width: 5%; display: flex; flex-direction: column; align-items: center; justify-content: center; font-size: 12px; @extend %title-font-reg;
        div{ display: flex; align-items: center; }
        img{ width: 20px; margin-right: 5px; }
        span{ }
      }
      &__messages-depositary{ position: relative; margin-right: 18px;
        a{ display: flex; align-items: flex-start; position: relative; }
        span{ background-color: white; display: block; width: 16px; height: 16px; position: absolute; color: var(--nj-color-base-blue-corporate); right: -9px; bottom: -5px; border: 1px solid var(--nj-color-base-blue-corporate); border-radius: 50%; display: flex; align-items: center; justify-content: center; font-size: 8px; }
        &--unread{
          &:before{ content: ''; width: 10px; height: 10px; z-index: 1; border-radius: 8px; background-color: $orange; display: block; position: absolute; left: -4px; top: -4px; }
        }
      }
      &__status-label{  border: 1px solid #000; display: inline-block; height: 20px; line-height: 20px; font-size: 12px; padding: 0 10px; margin-top: 10px; @extend %title-font-bold;
        &--STATUT_VALID, &--status_validated{ background-color: $vert-gradient; border: 1px solid $vert-gradient; color: #fff; }
        &--STATUT_REFUSE, &--status_refused{ background-color: $rouge-bis; border: 1px solid $rouge-bis; color: #fff;  }
        &--STATUT_INDOUBT, &--status_wait_for_treatment{ background-color: $orange; border: none; color: #fff; }
        &--STATUT_INCOMPLETE, &--status_to_complete{ background-color: $violet; border: none; color: #fff; }
        &--STATUT_FINALIST, &--STATUT_WINNER{ background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); border: none; color: #fff; }
        &--status_transferred_entity, &--status_transferred_bu{ background-color: $main-color; border: 1px solid $main-color; color: #fff; }
      }
      &__actions{ display: flex; flex-direction: row; align-items: center; justify-content: center;
        a{
          img{ height: 16px; }
          &:last-of-type{
            img{ height: 20px; }
          }
          &.edit-submission{
            img{ height: 14px; }
          }
        }
      }
      a.edit-submission{ margin-right: 8px; }
      &__deploy{ width: 3%; background-color: $gris-clair; color: $main-color; @extend %title-font-bold; font-size: 18px; display: flex; align-items: center; justify-content: center; box-sizing: border-box;
        a{ height: 100%; width: 100%; display: flex; justify-content: center; align-items: center; }
      }
      &__icons{ display: flex; width: 17%; align-items: flex-start; justify-content: flex-end; padding-right: 30px; flex-wrap: wrap;
        .js-add-to-favorites, .js-add-to-favorites-projects{ margin-right: 10px;
          i.md--yellow{ display: none; }
          i.md--lightgray{ display: block; }
          &.active, &:hover{
            i.md--yellow{ display: block; }
            i.md--lightgray{ display: none; }
          }
        }

      }
      &__content{ width: 100%; display: flex; align-items: stretch; flex-wrap: wrap; }
      &--header{ color: $main-color; @extend %title-font-reg; font-size: 14px; border-top: none; padding-bottom: 10px;
        .nj-admin-submission__title{ @extend %title-font-reg; }
      }
    }
    &--user{
      .nj-admin-submission{
        &__resume{ width: 42%; }
        &__icons{ width: 10%; }
      }
    }
  }

  .no-submissions{  @extend %title-font-reg; font-size: 16px; color: $gris; text-align: center; margin-top: 20px; }
}

.nj-export-submissions-csv{
  p{ font-size: 14px; }
}

.nj-admin-submissions__filters{ background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); padding-top: 0; overflow: hidden;
  .nj-admin-submissions__filters-header{ display: flex; justify-content: space-between; height: 100px; align-items: center;
    img{ width: 14px; transform: rotate(0); transition: transform 200ms linear; }
  }
  .nj__container{
    h2{ @extend %title-font-reg; font-size: 32px; color: #fff; display: block; text-transform: none; }
    span{ color: #fff; }
  }
  &.active{ overflow: visible;
    .submissions-form-filters{ max-height: 600px;  }
    .submissions-form-filters__actions{ opacity: 1; }
    .nj-admin-submissions__filters-header{
      img{ transform: rotate(90deg); }
    }
  }
}

.submissions-form-filters{ display: flex; flex-wrap: wrap; max-height: 0; height: auto; transition: max-height 300ms ease-in-out;
  &__quick-filters{ width: 30%; font-size: 14px; @extend %title-font-reg;
    span{ @extend %title-font-bold; font-size: 16px;}
  }
  &__fields{ width: 70%; display: flex; flex-wrap: wrap; justify-content: space-between;  }
  &__actions{ width: 100%; justify-content: flex-end; align-items: flex-end; display: flex; margin-top: 30px; opacity: 0; transition: opacity; transition-delay: 300ms; transition-duration: 300ms; transform-style: ease-in;
    input[type=submit]{
      &:hover{ cursor: pointer; }
    }
  }
  &__checkbox-line{ margin-top: 20px; display: flex; align-items: center;
    input[type=checkbox]{ margin-right: 10px; width: 20px; height: 20px; margin-top: 0; background: transparent; -webkit-appearance: none; border: 1px solid #fff;
      &:checked{ background-color: #fff; }
    }
    label{ @extend %title-font-reg; color: #fff; flex: 1;
      &.important{ @extend %title-font-bold; }
    }
    label[for=my_favorites]{ @extend %title-font-reg; color: #fff; flex: 1; display: flex; align-items: center;
      svg{ width: 15px; height: 15px; margin-right: 5px;
        polygon{ stroke: #fff; fill: #fff; }
      }
    }
  }
  &__field-line{ width: 32%; margin-bottom: 10px; display: flex; flex-direction: column; align-items: flex-start; box-sizing: border-box;
    select, input[type=text]{ width: 100%; background: transparent; color: #fff; font-size: 14px; border: none; border-bottom: 1px solid #fff; border-radius: 0;
      &.nj__select{ border: none; border-bottom: 1px solid #fff; border-radius: 0; -webkit-appearance: none; }
    }
    .chosen-container-single{ width: 100% !important; background: transparent; color: #fff; font-size: 14px; border: none; border-bottom: 1px solid #fff; border-radius: 0; height: 45px;
      .chosen-single{ width: 100%; background: transparent; padding-left: 0; color: #fff; font-size: 14px; border: none; border-bottom: 1px solid #fff; border-radius: 0; height: 45px; box-shadow: none;
        div{ display: none; }
      }
      .chosen-drop{ color: #000; }
      .chosen-search{ color: #000;
        input{ color: #000; }
      }
    }
    span{ font-size: 14px; @extend %title-font-bold; margin-bottom: 5px; color: #fff; }
    &--freesearch{ width: 100%;
      ::placeholder{ color: #fff; @extend %title-font-reg; }
      input[type=text]{ padding-left: 0; }
    }
    input[type=submit], input[type=submit]{
      &:hover{ cursor: pointer; }
    }
  }
}

.nj-submissions__status-actions{ display: flex; flex-direction: column;
  .nj__btn{ margin-bottom: 10px; }
}

.admin-note{ background-color: $secondary-color; @extend %title-font-bold; color: #fff; padding: 10px 20px; margin-top: 20px; }

.nj-detail-submission{ display: flex; justify-content: space-between;
  hr{ background: none; border: none; border-top: 1px solid #EEEEEE; margin: 20px 0 10px; }
  &__prev{ background-color: $gris-tres-clair; height: 60px; display: flex; align-items: center; justify-content: center; width: 60px; position: fixed; left: 0; top: auto; margin-top: 32px;
    &:hover{ background-color: $gris-clair; }
  }
  &__next{ background-color: $gris-tres-clair; height: 60px; display: flex; align-items: center; justify-content: center; width: 60px; position: fixed; right: 0; top: auto; margin-top: 32px;
    &:hover{ background-color: $gris-clair; }
  }
  &__date{ @extend %title-font-reg; font-size: 16px; color: $gris; }
  &__fav{ border: 1px solid $gris-clair; display: flex; height: 80px; justify-content: center; align-items: center; width: 60px; margin-right: 20px; box-sizing: border-box;
      svg{ width: 30px;
        polygon{ stroke: $gris-border;  }
      }
      &:hover, &.active{
        svg polygon{ fill: $orange; stroke: $orange; }
      }
  }
  &__startup{ @extend %title-font-bold; font-size: 48px; flex: 1; margin-top: 20px; margin-bottom: 30px; flex: 1; margin-right: 30px; line-height: 1.2; }
  &__idea-title{ @extend %title-font-bold; font-size: 48px; flex: 1; margin-top: 20px; margin-bottom: 30px; flex: 1; margin-right: 30px; line-height: 1.2;
    small{ color: $violet; display: block; @extend %title-font-reg; font-size: 20px; text-transform: uppercase; }
  }
  &__blue-project-title{ @extend %title-font-bold; font-size: 48px; flex: 1; margin-top: 20px; margin-bottom: 30px; flex: 1; margin-right: 30px; line-height: 1.2;
    small{ color: $color-procurement; display: block; @extend %title-font-reg; font-size: 20px; text-transform: uppercase; }
  }
  &__status{ box-sizing: border-box; height: 80px; position: relative; display: flex; align-items: center; justify-content: center; padding: 0 30px; border: 1px solid $gris-clair; @extend %title-font-reg; font-size: 20px; text-transform: uppercase;
    span{ @extend %title-font-reg; color: $main-color; text-transform: none; margin-right: 10px; }
    label{ @extend %title-font-reg; color: $gris; font-size: 16px; text-transform: none; margin-left: 10px; }
    .nj-detail-submission__old-status-date{ @extend %title-font-reg; font-size: 10px; color: $gris; }
    &:hover{
      .nj-detail-submission__status-history{ display: flex; }
    }
  }
  &__form-message{ border-top: 1px solid transparent; display: none; padding: 20px;
    &.active{ display: block; border-top: 1px solid var(--nj-color-base-primary); }
  }
  &__header{ display: flex; justify-content: space-between; width: 100%; align-items: center; margin-bottom: 30px; margin-top: 10px; }
  &__sidebar{ width: calc((100% - 40px)/3); }
  &__content{ width: calc((100% - 40px)/3*2);
    &--idea{
      &.nj-detail-submission__content--user{
        .nj-detail-submission__title-container{ display: none; }
      }
    }
  }
  &__content-subheader{ display: flex; justify-content: space-between;
    .nj-detail-submission__add-label{ flex: 1; height: 100%; height: 52px; display: flex; padding: 0 20px; justify-content: space-between;
      img{ margin-right: 0; height: 20px;}
      &.inactive{ background-color: $gris; pointer-events: none; }
    }
  }
  &__depositary{ background-color: $gris-clair;  padding: 40px; box-sizing: border-box; text-align: right; position: relative;
    h2{ color: $main-color; @extend %title-font-bold; font-size: 26px; }
  }
  &__add-admin-comm{ background-color: $secondary-color; margin: 20px 0 0 0; padding: 0 20px; @extend %title-font-bold; font-size: 16px; height: 45px; display: flex; align-items: center; justify-content: flex-end;
    img{ margin-left: 20px; }
  }
  &__add-contact{
    img{ width: 30px; margin-right: -5px; }
  }
  &__actions{ margin-top: 30px; }
  &__status-actions{ box-shadow: 4px 4px 20px #0003; display: flex; flex-direction: column;
    a{
      img{ margin-left: 20px; }
    }
  }
  &__other-actions{ display: flex; flex-direction: column; background-color: $gris-tres-clair;
    a {
      img { margin-left: 20px; }
    }
  }
  &__label-image{ position: absolute; bottom: -20px; right: 0; width: 30px; height: 30px; }
  &__labels{ display: flex; flex-direction: column; justify-content: flex-end; }
  &__label-type{ width: 100%; box-sizing: border-box; position: relative; background-color: $gris-tres-clair; padding: 10px 20px 5px; margin-bottom: 20px;
    h3{ margin-bottom: 30px; @extend %title-font-bold; font-size: 15px; color: $main-color; }
    .nj-detail-submission__contacts{ display: flex; justify-content: flex-start; }
    .nj-detail-submission__contact{ flex-direction: row; width: 100%; margin-bottom: 30px; display: flex; justify-content: flex-start;
      &:last-of-type{ margin-bottom: 0; }
    }
  }
  &__add-label{ margin-bottom: 0; @extend %title-font-bold; font-size: 14px; background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient);  color: #fff; display: inline-flex; align-items: center; justify-content: flex-start; height: 45px; padding: 0 10px;
    img{ width: 40px; margin-right: 10px; }
  }
  &__delete-label{ position: absolute; right: 0; top: 0;
    img{ width: 10px; }
  }
  &__label-slider{ padding-bottom: 40px; position: relative; }
  &__contact-work{ margin-top: 20px; @extend %title-font-reg; font-size: 12px; }
  &__evaluation-container{ border: 1px solid $gris-clair; margin-top: 20px; border-bottom: none; background-color: $gris-clair; padding: 0 30px 0 0; height: 50px; display: flex; justify-content: space-between; align-items: center; }
  &__deploy-comments{ margin-left: 20px; border: 1px solid $gris-clair; height: 50px; padding: 0 30px; display: flex; justify-content: center; align-items: center; @extend %title-font-bold; font-size: 14px;
    img{ margin-left: 20px; margin-right: 10px; }
    span{  @extend %title-font-reg; }
  }
  &__deploy-evaluations{ @extend %title-font-bold; font-size: 14px; display: flex; align-items: center; justify-content: center;
    span{ @extend %title-font-black; font-size: 17px; margin-left: 5px; }
  }
  &__deploy-admin-messages{ margin-left: 20px; display: flex; @extend %title-font-reg; font-size: 12px; text-decoration: underline; text-align: right; display: block; margin-top: 10px; }

  &__title-container{ display: flex; justify-content: space-between; margin-top: 0; }
  &__title{ width: calc(100% - 230px); font-size: 16px; @extend %title-font-reg; color: $gris; margin-bottom: 20px;
    span{ @extend %title-font-bold; font-size: 26px; color: $secondary-color; display: block; }
    &--project{ margin-bottom: 0; }
  }
  &__evaluations{ position: fixed; top: 0; right: -50vw; width: 50vw; height: 100vh; overflow: auto; transition: right 300ms ease-in-out; z-index: 2001; background-color: #fff; border-left: 1px solid $gris-border; padding: 30px 0; box-sizing: border-box;
    &.active{ right: 0; }
  }
  &__comments{ position: fixed; top: 0; right: -50vw; width: 50vw; height: 100vh; overflow: auto; transition: right 300ms ease-in-out; z-index: 2001; background-color: #fff; border-left: 1px solid $gris-border; padding: 0; box-sizing: border-box;
    &.active{ right: 0; }
  }
  &__idea-number-title{ color: #9E9E9E; }
  &__idea-number{ @extend %title-font-reg; font-size: 20px !important; color: $violet; }
  &__blue-project-number-title{ color: #9E9E9E; }
  &__blue-project-number{ @extend %title-font-reg; font-size: 20px !important; color: $color-procurement; }
  &__depositary-picto{ position: absolute; left: 40px; right: 40px; width: 40px; }
  &__depositary-name{ @extend %title-font-bold; font-size: 20px; margin-top: 20px; display: block; }
  &__depositary-function{ display: block; text-align: right; @extend %title-font-reg; font-size: 16px; }
  &__depositary-startup{ display: block; text-align: right; @extend %title-font-bold; font-size: 16px; }
  &__depositary-bu{ display: block; text-align: right; @extend %title-font-reg; font-size: 16px; }
  &__depositary-entity{ display: block; text-align: right; @extend %title-font-bold; font-size: 16px; }
  &__depositary-infos{ display: block; background-color: $main-color; color: #fff; margin-top: 30px; text-align: center; height: 45px; @extend %title-font-bold; font-size: 14px; display: flex; align-items: center; justify-content: center; }
  &__vcard-export{ display: block; background-color: $main-color; color: #fff; margin: 10px 0; text-align: center; height: 45px; @extend %title-font-bold; font-size: 14px; display: flex; align-items: center; justify-content: center; }
  &__depositary-contact{ display: block; color: $main-color; background-color: #fff; text-align: center; height: 45px; @extend %title-font-bold; font-size: 14px; display: flex; align-items: center; justify-content: center;
    img{ margin-right: 10px; height: 15px; }
  }
  &__evaluate{ background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient);  border: 1px solid $vert-gradient; align-self: stretch; padding: 0 20px; margin-left: 0; margin-right: auto; display: flex; align-items: center; color: #fff; @extend %title-font-bold; font-size: 14px; }
  &__note{ background-color: #fff; align-self: stretch; font-size: 14px; color: $gris; margin-left: 0; margin-right: 0; padding: 0 25px; @extend %title-font-reg; display: flex; align-items: center; border-bottom: 1px solid $gris-clair;
    &.active{ border-bottom: 1px solid #fff; }
    label{  @extend %title-font-reg; font-size: 20px; color: #000;
      &:first-of-type{  @extend %title-font-reg; font-size: 20px; color: $orange; margin-left: 20px; }
      &:nth-of-type(2){ margin: 0 5px; }
    }
  }
  &__average{ align-self: stretch;  color: $gris; margin-left: 0; margin-right: 0; padding: 0 25px; @extend %title-font-reg; display: flex; align-items: center; flex: 1; font-size: 14px;
    label{  @extend %title-font-reg; font-size: 20px;
      &:first-of-type{  @extend %title-font-reg; font-size: 20px; margin-left: 20px; }
      &:nth-of-type(2){ margin: 0 5px; }
    }
  }
  &__answers{
    h2{ color: $main-color; @extend %title-font-reg; font-size: 26px; margin-top: 30px; margin-bottom: 20px;
      p{ color: $main-color; @extend %title-font-reg; font-size: 26px;
        strong{ color: $main-color; @extend %title-font-reg; font-size: 26px;  }
      }
    }
    p{ @extend %title-font-reg; font-size: 16px; }
    h3{ @extend %title-font-reg; font-size: 26px; margin-top: 30px; margin-bottom: 20px; }
  }
  &__tags-title{ font-size: 16px; @extend %title-font-reg; color: $gris; margin-top: 0; display: flex; flex-wrap: wrap; align-items: flex-start; justify-content: space-between;
    h3{ width: 100%; display: block; margin-bottom: 10px; font-size: 16px; @extend %title-font-bold; }
  }
  &__tags{ display: flex; padding-bottom: 20px; flex-wrap: wrap; width: calc(100% - 300px);  }
  &__tag{ text-transform: uppercase; font-size: 11px; @extend %title-font-reg; color: $gris; margin-right: 20px; margin-top: 5px; }
  &__action-gradient{ background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); color: #fff; text-align: center; font-size: 16px; display: flex; align-items: center; justify-content: flex-end; }
  &__action-refuse{ color: $rouge-bis; background-color: #fff; text-align: center; font-size: 16px; display: flex; align-items: center; justify-content: flex-end; }
  &__action-complete{ background-color: $main-color; color: #fff; text-align: center; font-size: 16px; display: flex; align-items: center; justify-content: flex-end; margin-bottom: 1px; }
  &__action-close{ background-color: #fff; color: $main-color;  text-align: center; font-size: 16px; display: flex; align-items: center; justify-content: flex-end; }
  &__action-transfer{ background-color: $main-color; color: #fff; text-align: center; font-size: 16px; display: flex; align-items: center; justify-content: flex-end; }
  &__action-transfer-bu{ background-color: $main-color; color: #fff; text-align: center; font-size: 16px; display: flex; align-items: center; justify-content: flex-end; margin-bottom: 1px; }
  &__action-transfer-entity{ background-color: $main-color; color: #fff; text-align: center; font-size: 16px; display: flex; align-items: center; justify-content: flex-end; margin-bottom: 1px; }
  &__action-back{ background-color: $main-color; color: #fff; text-align: center; font-size: 16px; display: flex; align-items: center; justify-content: flex-end; margin-bottom: 1px;
    img{ transform: rotate(180deg); }
  }
  &__action-secondary{ text-align: center; height: 60px; padding-right: 20px; border-bottom: 1px solid $gris-border; font-size: 16px; display: flex; align-items: center; justify-content: flex-end; @extend %title-font-bold; font-size: 16px;
    &:last-of-type{ border-bottom: none; }
  }
  &__my-note{ display: flex; box-sizing: border-box; flex-direction: column; height: auto; max-height: 0; transition: all 300ms ease-in-out; overflow: hidden; border: 1px solid #fff; border-top: none;
    h4{ font-size: 18px; @extend %title-font-reg; color: $gris; margin: 40px 40px 0 40px; }
    &.active{ max-height: 950px; border: 1px solid $gris-clair; border-top: none; }
  }
  &__contacts{ display: flex; flex-wrap: wrap; align-items: center; justify-content: flex-start;
    &--reffering{ flex-direction: column; margin-top: 20px;
      .nj-detail-submission__contact{ width: 100%; background-color: $gris-clair; padding: 20px;
        figcaption{ margin-top: 10px; padding-left: 20px; }
        figure{ margin: 0; width: 70px; height: 70px;
          img{ width: 50%; }
        }
      }
    }
  }

  &__contact{ display: flex; flex-direction: row; align-items: flex-start; margin-bottom: 20px; width: calc(100% / 3); padding-right: 20px; box-sizing: border-box; position: relative;
    figure{ background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); margin: 0 10px 10px 0; display: flex; align-items: center; justify-content: center; height: 40px; width: 40px; min-width: 40px;
      img{ width: 20px; }
    }
    figcaption{ display: flex; flex-direction: column; width: calc(100% - 50px);
      span{ font-size: 12px; }
    }
    &:hover{ cursor: pointer; }
    .nj-detail-submission__contact-container{ display: flex; flex-direction: row; align-items: flex-start; flex-wrap: wrap; width: 200px;
      figcaption{ width: auto; }
      &:hover{ cursor: pointer; }
    }
  }
  &__sidebar-title{ color: $main-color; font-size: 20px; @extend %title-font-reg; text-align: left; margin-bottom: 10px; margin-top: 40px;}
  &__contact-quote{ display: flex; align-items: flex-start; margin-top: 5px; margin-left: 50px; width: calc(100% - 250px);
    img{ filter: grayscale(100%); width: 10px; margin-right: 10px; }
    p{ font-size: 16px; @extend %title-font; margin-top: 5px; }
  }
  &__contact-role{ color: $main-color; @extend %title-font-bold; }
  &__form-container{ height: auto; transition: all 300ms ease-in-out; overflow: auto; box-sizing: border-box; border: 1px solid #fff; border-top: none; }
  &__form-message-container{ display: flex; flex-direction: column;  }
  &__form-message{ width: 100%;
    .input_line{ display: flex; @extend %title-font-bold; color: $main-color; font-size: 16px; flex-direction: column;

      input[type=text]{ flex: 1; @extend %title-font-bold; border: 1px solid $gris; color: #000; height: 45px; font-size: 16px; line-height: 45px; padding: 0 20px; margin-bottom: 20px; margin-top: 10px;
        &::placeholder{ color: $gris-clair; }
      }
      .note-editor{ width: 100%; box-sizing: border-box;
        .note-editable{ height: 150px; color: #000; font-size: 14px; @extend %title-font-reg;  }
      }
    }
    .input_line_submits{ display: flex; justify-content: flex-end;
      input[type=submit]{ @extend %title-font-bold; }
    }
  }
  &__status-history{ position: absolute; top: 50%; left: 50%; min-width: 300px; transform: translate(-50%, -50%); width: auto; border: 1px solid $gris-clair; height: auto; padding: 20px; background-color: #fff; z-index: 2; display: none; flex-direction: column;
    h3{ @extend %title-font-bold; font-size: 16px; margin-bottom: 20px; }
  }
  &__old-status{ @extend %title-font-bold; font-size: 16px; color: $main-color; margin-top: 5px; }
}

.nj-detail-submission__admin-messages-close{ position: absolute; right: 20px; top: 20px; }
.nj-detail-submission__admin-messages{ display: none; position: fixed; box-sizing: border-box; width: 600px; max-height: 80vh; overflow: auto; background-color: #fff; z-index: 9999; padding: 20px; top: 5vh; left: 50%; transform: translateX(-50%);
  &.active{ display: block; }
  .pr-adm-msg{ border-bottom: 1px solid $nj-bleu; margin-bottom: 10px; padding-bottom: 10px;
    &__date{ font-size: 12px; display: block; @extend %title-font-reg; }
    &__sender{ font-size: 12px; @extend %title-font-bold; }
    &__receiver{ font-size: 12px; @extend %title-font-bold; color: $nj-bleu; }
    &__text{ font-size: 12px; margin-top: 20px; @extend %title-font-reg;
      p, span{ font-size: 12px; @extend %title-font-reg; }
    }
    &:last-of-type{ border-bottom: none; margin-bottom: 0; }
  }
}

.nj-files{ margin-top: 40px; }

.nj-file{ display: flex; width: 100%; justify-content: space-between; margin-bottom: 10px; align-items: flex-start;
  &__filename{ @extend %title-font-reg; font-size: 16px; }
  &__filesize{ @extend %title-font-reg; font-size: 14px; color: $gris; margin-right: 20px; margin-left: auto; }
  &__dl{ background-color: $main-color; display: flex; height: 30px; padding: 0 10px; align-items: center; justify-content: center; color: #fff !important; @extend %title-font-reg; font-size: 14px; }
}

.nj-criteria{ display: flex; width: 100%; border-bottom: 1px solid $gris-clair; padding: 10px 0 10px 0; box-sizing: border-box;
  &__name{ font-size: 12px; width: 175px; @extend %title-font-bold; }
  &__note{ display: flex; flex-direction: column; }
  &__comment{ font-size: 12px; padding: 0 10px 0 20px; box-sizing: border-box; }
  &__bullet{ width: 12px; height: 12px; display: block; border-radius: 50%; border: 1px solid #000; margin-right: 8px; }
  &__bullets{ display: flex; margin-top: 10px;
    &.noted1{
      .nj-criteria__bullet:nth-of-type(1){ background-color: $orange; border: 1px solid #000; }
    }
    &.noted2{
      .nj-criteria__bullet:nth-of-type(1), .nj-criteria__bullet:nth-of-type(2){ background-color: $orange; border: 1px solid #000; }
    }
    &.noted3{
      .nj-criteria__bullet:nth-of-type(1), .nj-criteria__bullet:nth-of-type(2), .nj-criteria__bullet:nth-of-type(3){ background-color: $orange; border: 1px solid #000; }
    }
    &.noted4{
      .nj-criteria__bullet:nth-of-type(1), .nj-criteria__bullet:nth-of-type(2), .nj-criteria__bullet:nth-of-type(3), .nj-criteria__bullet:nth-of-type(4){ background-color: $orange; border: 1px solid #000; }
    }
    &.noted5{
      .nj-criteria__bullet{ background-color: $orange; border: 1px solid #000; }
    }
  }
  &:last-of-type{ border-bottom: none; margin-bottom: 40px; }
}

.nj-form-note{ width: 100%; margin: 0; box-sizing: border-box;
  &__criteria{ @extend %title-font-bold; font-size: 12px; }
  &__bullets{ display: flex; margin-top: 10px;
      input[type=radio]{ width: 14px; height: 14px; margin-top: 0; display: block; margin-right: 8px; border-radius: 50%; background-color: $orange; border: 1px solid #000; -webkit-appearance: none;
        &:hover{ cursor: pointer; }
        &:checked{
          & ~ input[type=radio]{ background-color: transparent;  border: 1px solid #000; }
        }
      }
  }
  &__note{ border: 1px solid $gris; border-bottom: none; padding: 10px; border-right: none; margin-bottom: 0; width: 220px; box-sizing: border-box; line-height: 1; }
  &__comment{ padding: 10px; border: 1px solid $gris; border-bottom: none; padding: 10px; flex: 1;
    textarea{ border: none; height: 100%; width: 100%; @extend %title-font-reg; font-size: 12px; }
  }
  &__line{ display: flex; width: 100%; margin-bottom: 0; }
  &__actions{ border-top: 1px solid $gris; display: flex; justify-content: flex-end; }
}

.nj-comments{
  &__header{ display: flex; justify-content: space-between; }
}
.nj-evaluations{
  &__header{ display: flex; justify-content: space-between; padding: 30px 40px; }
  .nj-detail-submission__a-note{  }
  .nj-detail-submission__evaluation-note{ flex: 1;
    p{ @extend %title-font-reg; font-size: 16px; color: $gris; margin-bottom: 10px; }
    span{
      &:first-of-type{ @extend %title-font-reg; font-size: 20px; color: $orange; }
      &:last-of-type{ @extend %title-font-reg; font-size: 20px;  }
    }
  }
  .nj-detail-submission__evaluation-header{ display: flex;  width: 100;}
  .nj-detail-submission__evaluation{ border-bottom: 1px solid $gris; padding: 20px 40px;
    h4{ @extend %title-font-reg; font-size: 16px; color: $gris; margin-top: 20px; }
    .nj-criteria{ margin: 0; }
    .nj-evaluator{ display: flex; flex-direction: column; align-items: flex-end;
      &__title{ @extend %title-font-reg; font-size: 16px; color: $gris; }
      &__name{ @extend %title-font-reg; font-size: 22px; color: $main-color}
      &__function{ @extend %title-font-reg; font-size: 16px; color: $gris-fonce; }
    }
  }
}

.nj-depositary{
  &__container{ display: flex; margin-top: 20px;}
  &__header{ display: flex; }
  &__image{ border-radius: 50px; width: 50px; height: 50px; margin-right: 20px; }
  &__name{ @extend %title-font-bold; font-size: 18px; }
  &__content{ width: 50%;
    h3{ @extend %title-font-bold; font-size: 18px; margin-top: 10px; }
    p, ul{ @extend %title-font-reg; font-size: 16px; }
    ul{ list-style: disc; padding-left: 30px; list-style-image: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); @extend %title-font-reg; margin-top: 10px;
      li{ margin-bottom: 5px; font-size: 16px; }
    }
  }
}

form[name=duplicate-submissions-form]{ display: flex; flex-wrap: wrap; justify-content: flex-end;
  .chosen-container{ width: 100% !important; display: block; margin: 20px 0; font-size: 16px; @extend %title-font-reg; }
}
form[name=convert-submissions-form]{ display: flex; flex-wrap: wrap; justify-content: flex-end;
  .chosen-container{ width: 100% !important; display: block; margin: 20px 0; font-size: 16px; @extend %title-font-reg; }
}

form#submission-state-change, form#add-admin-comm, form#submission-edit-depositary{
  .obligatoire{ display: none; }
}

form#add-admin-comm{
  textarea{ @extend %title-font-reg; font-size: 16px; padding: 10px; }
}

.projects__admins-list{ margin-bottom: 20px; list-style: square; margin-left: 40px;
    li{ font-size: 12px; }
}

@media screen and (max-width: 1250px){
  .nj-admin-submissions{
    &__list{
      .nj-admin-submission{
        &--header{
          .nj-admin-submission__title{ padding-left: 30px; box-sizing: border-box; }
        }
        &--solution{
          .nj-admin-submission__title{ padding-left: 30px; box-sizing: border-box; }
          .nj-admin-submission__content{ flex-wrap: wrap; }

        }
      }
    }
  }
}

@media screen and (max-width: 1100px){
  .nj-detail-submission{ flex-direction: column;
    &__sidebar{ width: 100%; display: flex; flex-direction: row; margin-bottom: 40px; flex-wrap: wrap;
      .nj-detail-submission__sidebar-title{ width: 100%; }
    }
    &__depositary{ width: 50%; }
    &__actions{ width: 50%; margin-top: 0; }
    &__content{ width: 100%; }
    &__comments{ width: 100vw; right: -100vw;}
    &__evaluations{ width: 100vw; right: -100vw; }
    &__evaluation-container{ flex-wrap: wrap; height: auto; padding: 0; }
    &__note{ width: 50%; box-sizing: border-box; padding: 20px 0; display: flex; align-items: center; justify-content: center; }
    &__evaluate{ width: 50%; box-sizing: border-box; padding: 20px 0; display: flex; align-items: center; justify-content: center; }
    &__average{ width: 50%; box-sizing: border-box; padding: 20px 0; display: flex; align-items: center; justify-content: center; }
    &__deploy-evaluations{ width: 50%; box-sizing: border-box; padding: 20px 0; display: flex; align-items: center; justify-content: center; }
  }
}

@media screen and (max-width: 1000px) {
  .js-export-submissions-to-zip, .nj-export-submissions-csv { display: none; }
}
@media screen and (max-width: 850px){
  .nj-admin-submissions{
    &__filters{
      .submissions-form-filters{ flex-wrap: wrap;
        &__quick-filters{ width: 100%; flex-direction: row; display: flex; flex-wrap: wrap;
          span{ width: 100%; }
          .submissions-form-filters__checkbox-line{ width: 50%;

          }
        }
        &__fields{ width: 100%; margin-top: 40px; }
      }
    }
  }
}

@media screen and (max-width: 800px){
  .nj-admin-submissions{
    &__list{
      .nj-admin-submission{
          &__content{ flex-direction: column; }
          &__by{ width: 100%; }
          &__title{  width: 100%; margin-top: 10px; padding-left: 30px; padding-right: 30px; }
          &__resume{  width: 100%; padding-left: 30px; margin-top: 10px;  }
          &__icons{ width: 100%; }
          &__note{ margin-top: 20px; }
          &__description{ width: auto; }
      }
    }
  }
}

@media screen and (max-width: 700px){
  .nj-detail-submission{
    &__sidebar{ flex-wrap: wrap; }
    &__depositary{ width: 100%; }
    &__actions{ width: 100%; display: none; }
    &__content{ width: 100%; }
    &__title-container{ flex-wrap: wrap; }
    &__title{ width: 100%; }
    &__deploy-comments{ width: 100%; margin: 20px 0 0 0; }
    &__header{ flex-wrap: wrap; }
    &__status{ width: 100%; }
    &__tags{ width: 100%; }
    &__content-subheader{ flex-direction: column;
      a{ height: 50px; box-sizing: border-box;
        &.nj-detail-submission__add-label{ flex: none; margin-right: 0; }
      }

    }
  }
}

@media screen and (max-width: 620px){
  .nj-detail-submission{
    &__prev{ height: 40px; width: 40px; z-index: 10; margin-top: 5px; }
    &__next{ height: 40px; width: 40px; z-index: 10; margin-top: 5px; }
  }
  .nj-submission{
    &__content-header{ margin-top: 40px; font-size: 11px;
    }
  }
  .nj-discussion__form textarea{ border: 1px solid $gris; }
}

@media screen and (max-width: 550px){
  .nj-admin-submissions{
    &__filters{
      .submissions-form-filters{ flex-wrap: wrap;
        &__quick-filters{
          .submissions-form-filters__checkbox-line{ width: 100%; }
        }
        &__field-line{ width: 100%; }
      }
      &.active{
        .submissions-form-filters{ max-height: 950px; }
      }
    }
  }
  .nj-detail-submission{
    &__startup{ margin-right: 0; font-size: 30px; }
    &__status{ margin-top: 20px; height: 50px; font-size: 14px;
      span{ width: 80px; }
      label{ display: none; }
    }
    &__deploy-evaluations{ text-align: right;
      span{ margin-left: 20px; }
    }
    &__average{ width: 100%; }
    &__deploy-evaluations{ width: 100%; }
    .nj-form-note{
      &__line{ flex-wrap: wrap; }
      &__note{ width: 100%; border-right: 1px solid $gris; padding: 20px 40px; }
      &__comment{ width: 100%; }

    }
  }
  .nj-evaluations{
    &__header{ padding: 10px 20px; }
  }

}

@media screen and (max-width: 400px){
  .nj-detail-submission{
    &__status{ font-size: 12px; }
  }
}


