.nj-menu{ position: fixed; width: 100%; z-index: 20; padding-top: 20px; background-color: transparent; transition: background-color 300ms ease-in-out; top: 0;
  &__baseline{ @extend %title-font; color: $main-color; font-size: 16px; letter-spacing: 3.2px; }
  &__container{ position: relative; width: 1120px; margin: 0 auto; height: auto; display: flex; flex-direction: column; box-sizing: border-box; }
  &__top{ display: flex; justify-content: space-between; align-items: center; box-sizing: border-box; color: #000000; height: 70px; border-bottom: 1px solid $gris; position: relative; }
  &__logo-container{ align-self: flex-start; }
  &__top-right{ display: flex; flex-direction: row; align-items: center;  align-self: flex-end; height: 45px; margin-right: 45px;  }
  &__nav-container{  padding-top: 10px; display: flex; justify-content: space-between; position: relative; overflow: hidden; }
  &__nav{ transition: all 300ms ease-in-out;
    &.blur{ filter: blur(15px); opacity: .2; }
    > ul{ display: flex;
      li{
        &:first-of-type{ margin-left: 0; }
        a{ padding: 10px 0; display: inline-block; color: #000000; font-size: 15px; @extend %title-font-bold;
          &.active{ color: $main-color; }
        }
        img{ display: inline-block; filter: invert(1); }
        &.nav__item--submenu{
          a{ margin-right: 10px; }
        }
      }
      > li{ width: auto; margin-left: 40px;
        a{
          span{ text-shadow: none; }
        }
      }
    }
    .nj-submenu{
      li{
        a{
          span{ text-shadow: none !important; }
        }
      }
    }
    .nav__item{
      &:hover{
        .nj-submenu{ max-height: 400px; }
      }
    }
  }
  &__log-in{ height: 45px; z-index: 2; padding: 0 12px; background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); display: flex; justify-content: center; align-items: center; font-size: 14px; @extend %title-font; position: relative; box-sizing: border-box;
    img{ width: 20px; }
    span{ width: 0; overflow: hidden; color: #ffffff; transition: width 200ms ease-in-out; @extend %title-font-bold; box-sizing: border-box; text-align: center; }
  }
  &__log-in-container{ height: auto; position: absolute; right: 0; bottom: 0; z-index: 10; overflow: hidden; width: auto; box-sizing: border-box;
    &:hover, &.active{ overflow: visible;
      .nj-menu__subscribe{ top: 45px; height: 70px; right: 0; box-shadow: 4px 4px 20px #0003;
        &--visitor{ height: 150px; }
      }
      .nj-menu__log-in{
        span{ width: 150px; }
      }
    }
    &--1{
      &:hover, &.active{
        .nj-menu__subscribe{ height: 100px;
          a{ margin-bottom: 5px; }
        }
      }
    }
  }
  &__subscribe{ transition: height 300ms ease-in-out;  transition-delay: 300ms; display: block; color: #000000; position: absolute; top: 0; right: -194px; width: 194px; background: #ffffff; font-size: 12px; text-align: center; z-index: 1; flex-direction: column; display: flex; align-items: center; justify-content: center; height: 0; overflow: hidden; box-sizing: border-box;
    strong{ @extend %title-font-bold; }
    span{ text-decoration: underline; font-size: 12px; @extend %title-font; }
    a{ margin-bottom: 3px;
      &.nj-menu__subscribe__submissions-link{ background-color: $secondary-color; color: #ffffff; padding: 4px 5px; border-radius: 2px;}
    }
  }
  &__subscribe{ transition: height 300ms ease-in-out; transition-delay: 300ms; display: block; color: #000000; position: absolute; top: 0; right: -194px; width: 194px; background: #ffffff; font-size: 12px; text-align: center; z-index: 1; flex-direction: column; display: flex; align-items: center; justify-content: center; height: 0; overflow: hidden; box-sizing: border-box;
    strong{ @extend %title-font-bold; }
    span{ text-decoration: underline; font-size: 12px; @extend %title-font; }
    a{ margin-bottom: 3px;
      &.nj-menu__subscribe__submissions-link{ background-color: $secondary-color; color: #ffffff; padding: 4px 5px; border-radius: 2px;}
    }
    &--visitor{
      a{ display: block; margin-bottom: 0; }
      strong{ display: block; margin-bottom: 4px; }
      span{ width: 120px; }
      hr{ border: none; border-top: 1px solid #E0E0E0; width: calc(100% - 40px); margin: 20px 20px; }
      .nj-menu__okta-login-container{ display: flex; align-items: center;
        &--EN{
          span{ width: 75px; }
        }
      }
    }
  }
  &--home, &--pages-template1, &--users-login, &--users_settings-inscription, &--users-passwordforgotten, &--users-password, &--pages-load, &--submissions-home, &--submissions-result{
    .nj-menu__nav{
      > ul{
        li{
          a{ color: #ffffff; }
          img{ filter: invert(0); }
        }
        > li{
          a{
            span{ text-shadow: #000 0px 0px 5px; }
          }
        }
      }
    }
    .nj-menu__top{ border-bottom: 1px solid #ffffff; color: #ffffff; }
    .nj-menu__baseline{ color: #ffffff; }
    .nj-langswitcher{
      .nj__lang{ color: #ffffff; }
    }
    .nj__search-toggle{
      img{ filter: invert(1); }
    }
    .nj__search{
      input[type="text"]{ color: #ffffff; }
      input[type="text"]::placeholder { color: #ffffff; }
    }
  }
  &.scrolled{ background-color: #ffffff;
    .nj-menu__top{ border-bottom: 1px solid $gris-clair; color: #000000; }
    .nj-menu__nav{
      ul{
        li{
          a{ color: #000000; }
          img{ filter: invert(1); }
        }
        > li{
          a span{ text-shadow: none; }
        }
      }
    }
    .nj-langswitcher{
      .nj__lang{ color: #000000; }
    }
    .nj-menu__log-in{ color: #ffffff; }
    .nj__logo{ filter: none; }
    .nj-menu__baseline{ color: $main-color; }
    .nj__search{
      input[type="text"]{ color: $gris-fonce; }
      input[type="text"]::placeholder { color: $gris; }
    }
    .nj__search-toggle{
      img{ filter: invert(0); }
    }
  }
}

.nj__logo{ width: 142px; position: fixed; left: 50%; transform: translateX(-50%); }

.nj-search{ position: absolute; top: -50px; left: 0; width: calc(100% - 50px); display: flex; justify-content: space-between; overflow: hidden; transition: top 300ms ease-in-out;
  &.active{ top: 10px; }
  input[type="text"]{ background: transparent; border-radius: 0; flex: 1; border: none; border-bottom: 1px solid $nj-bleu-ciel; color: $gris-fonce; @extend %title-font-bold; font-size: 14px; }
  input[type="text"]::placeholder { color: $gris-fonce; }
}

.nj__search-toggle{ padding-right: 10px;
  img{ width: 15px; }
}

.nj-langswitcher{ padding: 0 10px; font-size: 14px;
  &__separator{ margin: 0 5px; }
  .nj__lang{ background: none; -webkit-appearance: none; border: none; color: #000000; @extend %title-font; outline: none;
    &:hover{ cursor: pointer; }
    &.nj__lang--active{  @extend %title-font-black; }
  }
}
.nj-account{
  &__icon{ width: 25px; height: 25px; display: block; }
}

.nj-submenu{ display: block; position: fixed; top: auto; left: auto; margin-left: -20px; max-height: 0; background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); backdrop-filter: blur(4px); height: auto; box-sizing: border-box; overflow: hidden; transition: max-height 300ms ease-in-out;
  &:before{ content: ""; display: block; height: 2px; background-color: $main-color; }
  li{
    a{ padding: 10px 20px !important; display: block; margin: 0;
      span{ color: #ffffff; @extend %title-font-bold; }
      &:hover{
        span{ color: #000000; }
      }
    }
    &:first-of-type{
      a{ padding-top: 20px !important; }
    }
    &:last-of-type{
      a{  padding-bottom: 20px !important; }
    }
  }
}

.nj-mobile-menu{ display: none; position: fixed; width: 100%; z-index: 21; box-sizing: border-box; height: auto; padding: 0; background-color: transparent; transition: background-color 300ms ease-in-out; top: 0; background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient);
  &__container{ position: relative; width: 100%; margin: 0 auto; height: 100%; display: flex; height: 80px; justify-content: space-between; align-items: center; box-sizing: border-box; }
  .nj__logo{ width: 87px; top: 20px; }
  &__hamburger{ display: flex; flex-direction: row; align-items: center; justify-content: center; align-self: flex-end; height: 100%; width: 80px; border-left: 1px solid #ffffff; }
  &__baseline{ width: 100px; margin-left: 30px; font-size: 10px; @extend %title-font; color: #ffffff; letter-spacing: 3.2px; display: block; }
  &__content{ height: calc(100vh - 80px); width: 100%; overflow: auto; position: absolute; top: auto; left: -100%; padding: 0 50px; background-color: #ffffff; box-sizing: border-box; transition: left 400ms ease-in-out; display: flex; flex-direction: column; justify-content: space-between;
    &.active{ left: 0;
      &:before{ width: 100%; }
      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }
  &.active{ background: #ffffff; }
  &__nav{ margin-top: 20%;
    ul{
      li{ @extend %title-font-black; color: $gris-fonce; font-size: 24px; margin-bottom: 7%;
        &.submenu-submissions{
          a{ background-color: $secondary-color; color: #fff; padding: 5px 10px; border-radius: 5px; }
        }
      }
    }
    .js-deploy-mobile-submenu{ margin-left: 30px; }
    .nj-submenu{ display: block; position: relative; top: auto; left: auto; background: transparent; margin-left: 0; max-height: 0; height: auto; box-sizing: border-box; overflow: hidden; transition: max-height 300ms ease-in-out;
      &.active{ max-height: 400px; }
      &:before{ display: none; }
      li{ margin-bottom: 10px; font-size: 16px;
        &:first-of-type{ margin-top: 30px; }
        a{ padding: 5px 20px !important; display: block; margin: 0;
          span{ color: $gris-fonce; @extend %title-font; }
        }
      }
    }
  }
  .nj-langswitcher{ display: flex; justify-content: flex-end; position: absolute; right: 30px; top: 30px; font-size: 30px; }
  &__logout{ width: auto; display: block; margin-bottom: 80px; text-decoration: underline; @extend %title-font-reg; color: $main-color; font-size: 12px; }
}

.nj-mobile-menu-bis{ width: 0; position: fixed; z-index: 21; box-sizing: border-box; height: 80px; padding: 0; background-color: #ffffff; top: 0; transition: width 400ms ease-in-out; display: flex; justify-content: space-between; align-items: center; overflow: hidden;
  &.active{ width: 100%; position: fixed; }
  &:after{ content: ''; width: 100%;height: 1px; display: block; background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); position: absolute; left: 0; bottom: 0; }
  &__close{ display: flex; flex-direction: row; align-items: center; align-self: flex-end; justify-content: center; height: 100%; width: 80px; border-left: 1px solid #ffffff; margin-left: 20px; }
  form{ position: relative; top: -70px; left: 20px; width: auto; flex: 1;
    &.active{ top: 0; }
  }
  &__login{ margin-left: 40px; }
  &__search-toggle{ margin-left: 40px; margin-right: auto;
    img{ height: 20px; }
  }
  .nj-search{
    input[type=submit]{ width: 30px; padding: 0; display: block; text-align: center; }
  }
}

@media screen and (max-width: 1250px){
  .nj-menu{
    &__container{ width:90%; padding: 20px 0 0 0; box-sizing: border-box; }
  }
}

@media screen and (max-width: 980px){
  .nj-menu{
    &__container{ width: 100%; padding: 20px 20px 0 20px; box-sizing: border-box; }
  }
}

@media screen and (max-width: 900px){
  .nj-menu{ display: none; }
  .nj-mobile-menu{ display: block; }
}


@media screen and (max-width: 450px){
  .nj-mobile-menu-bis{
    &__login{ margin-left: 20px; }
    &__search-toggle{ margin-left: 15px; }
    .nj-search{
      input[type=text]{ flex: none; width: 200px; }
    }
  }
}

@media screen and (max-width: 375px){
  .nj-mobile-menu-bis{
    .nj-search{
      input[type=text]{ width: 150px; }
    }
  }
}

@media screen and (max-width: 350px){
  .nj-mobile-menu-bis{
    .nj-search{
      input[type=text]{ width: 100px; }
    }
  }
  .nj-mobile-menu{
    .nj__langswitcher{ right: 10px; top: 10px; }
    &__hamburger{ width: 70px; }
    &__baseline{ margin-left: 20px; }
    &__content{ padding: 0 30px; }
    &__nav{
      ul{
        li{ font-size: 16px; }
      }
    }
  }
}