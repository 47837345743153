.nj-modal{
  &--log-in{
    .nj-modal__dialog{  }
    .nj-modal__content{
      &:after{ display: block; width: 100%; height: 3px; background: linear-gradient(135deg,#0af,#23d2b5); content: ""; }
    }
  }
  &--evaluations{
    .nj-modal__content{ max-height: 95vh; overflow: auto; }
  }
}

.nj-header{
  &__logo{ margin-left: calc(50% - 75px - 66px); margin-right: auto; }
  &--simple{
    &.nj-header.nj-header--fixed{ height: 6rem; }
    .container{ display: none; }
    .nj-header__logo{ margin-left: calc(50% - 64px - 50px); }
  }
  &__nav-link{ font-size: 0.85rem; }
}

.nj-card{
  &--news, &--trophies, &--cfp{
    .nj-card__body{ flex: 1; display: flex; justify-content: space-between; }
    .nj-card__title{ margin-top: 0; }
    .nj-card__action{ margin-top: 20px; margin-bottom: 0; }
    .nj-card__text{ margin-top: 0; margin-bottom: auto; }
  }
  &--trophies{ min-height: 390px;
    .nj-card__bu{ margin-top: 20px; }
    .nj-card__action{ margin-top: 20px; margin-bottom: 0; }
  }
  &--cfp{ min-height: 540px; }
}

.nj-header{
  .nj-header-reduced__langswitcher-container{ display: none; }
  &.nj-header--sm{
    .nj-header-reduced__langswitcher-container{ width: 80px; display: block; margin-right: 50px;
      form{ width: 80px; }
    }
  }
}

.nj-form-group{
  .nj-form-counter{ text-align: right; font-size: 0.75rem; margin-top: 5px; color: var(--nj-color-base-gray-600); }
  &--big{ padding-top: 2.25rem !important; margin-top: 25px; }
}

h2.nj-sustainable-theme{ font-size: 1.5rem; color: var(--nj-color-base-primary) !important; }
.mtauto{ margin-top: auto; }

.nj-label-static{
  &--wrap{ white-space: pre-wrap !important; top: -10px !important; }
}

body.is_mobile{
  .nj-header--expand-lg .nj-header__nav--panel { padding: 0; min-height: calc(100vh - 4rem);
    .nj-header__nav-item{ padding-left: 2rem; padding-right: 2rem; }
  }
}

.nj-trophies__grid{
  .col-md-4{
    &:last-of-type{ justify-self: flex-start; margin-right: auto; }
  }
}

.material-icons{
  &__trend{ font-size: 50px;
    &--high{ color: var(--nj-color-base-green) !important; }
    &--active{ transform: rotate(45deg); color: var(--nj-color-base-green-riogrande) !important; }
    &--wip{ transform: rotate(90deg); color: var(--nj-color-base-blue-allports) !important; }
    &--decrease{ transform: rotate(180deg); color: var(--nj-color-base-yellow) !important; }
  }
}

.nj-subscription, .nj__form--subscription{
  #input_line_us_valid_cgv, #input_line_us_valid_personal_data{
    a{ color: var(--nj-color-base-blue-corporate); }
  }
}

.nj-icon:before{
  -webkit-background-clip: text !important;
}

#input_line_op_sponsor{
  .input_area{ margin-top: 10px; }
}

@media screen and (max-width: 1200px){
  .row{
    .nj-card{
      &--horizontal{
        .nj-card__img{ width: 100%; }
        .nj-card__body{ width: 100%; }
      }
    }
  }
}

@media screen and (max-width: 992px){
  .nj-header{
    &__logo{ margin-left: calc(50% - 32px); margin-right: auto; }
  }
}

@media screen and (max-width: 991px){
  .nj-header{
    &--simple{
      &.nj-header.nj-header--fixed{ height: 4rem;
        .nj-header__group{ height: 4rem; }
      }
      .container{ display: block; }
      .nj-header__head{ background-color: white;
        > div{ display: block; }
        .nj-header__link{ display: block; margin-left: 10px;}
      }
    }
  }
}

@media screen and (max-width: 800px){
  .nj-header{
    &__search{
      &.show{ height: auto !important; flex-wrap: wrap; box-shadow: var(--nj-shadow-level-2-dp); padding-bottom: 20px;
        input[type=text]{ width: 100%; margin: 0 !important; }
        .nj-form-group{ margin-left: 0 !important; }
        .nj-header__close{ position: absolute; top: 20px; right: 20px; z-index: 2; }
      }
    }
  }
}

@media screen and (max-width: 800px){
  .nj-header{
    &.nj-header--sm{
      .nj-header-reduced__langswitcher-container{ margin-left: 20px; }
    }
  }
}

