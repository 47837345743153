.nj-submissions-list{ width: 100%;
  .nj-submission{ border-bottom: 1px solid $gris; padding: 30px 0; display: flex; justify-content: space-between; align-items: center; width: 100%;
    &__title{ @extend %title-font-reg; font-size: 24px; }
    &__date{ @extend %title-font-reg; font-size: 14px; color: $gris; display: block; margin-top: 24px; margin-bottom: 15px; margin-left: 0; margin-top: 20px; }
    &__status{ @extend %title-font-bold; font-size: 14px; height: 60px; line-height: 1.3; display: flex; justify-content: center; align-items: center; width: auto; text-align: center; border: 1px solid $gris-clair; box-sizing: border-box; }
    &__type{ @extend %title-font-reg; font-size: 20px; color: $secondary-color; text-transform: uppercase;
      &--1{ color: $main-color; }
      &--projects{ color: $violet; }
      &--projects_procurement{ color: $color-procurement; }
    }
    &__messages{ height: 60px; background-color: $gris-clair; padding: 0 40px; display: flex; justify-content: center; align-items: center; max-height: 60px; position: relative;
      span{ @extend %title-font-reg; font-size: 14px; margin-left: 10px; }
      &--new{
        &:before{ content: ''; width: 10px; height: 10px; border-radius: 8px; background-color: $orange; display: block; position: absolute; left: 35px; top: 17px; }
      }
    }
    &__update{ height: 60px; display: flex; font-size: 14px; align-items: center; justify-content: center; min-width: 60px; width: auto; padding: 0 20px; color: #fff; @extend %title-font-bold; background-color: $main-color;
      img{ width: 15px; margin-left: 10px; }
    }
    &__actions{ display: flex; }
    &__infos{  }
    &:last-of-type{ border-bottom: none; }
  }
  &__empty{ margin: 40px 0; @extend %title-font-reg; font-size: 14px; }
}

.nj__submission{
  .kromi-form{
    .page-header{ display: none; }
  }
}

.nj-submission{
  &__header{ background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); height: 75px;
    .nj-submission__container{ display: flex; justify-content: space-between; align-items: center;
      a{ color: #ffffff; @extend %title-font-bold; font-size: 16px; }
    }
  }
  &__title{ @extend %title-font-bold; font-size: 48px; flex: 1; margin-top: 20px; margin-bottom: 30px; flex: 1; margin-right: 30px; line-height: 1.2; }
  &__title-container{ display: flex; justify-content: space-between; width: 100%; align-items: center; margin-bottom: 30px; }
  &__update{ height: 45px; padding: 0 20px; display: flex; color: #fff; @extend %title-font-bold; font-size: 16px; align-items: center; justify-content: center; width: auto; background-color: $main-color;
    img{ width: 15px; margin-left: 20px; }
  }
  &__date{ @extend %title-font-reg; font-size: 18px; color: $gris; display: block; margin-right: 35px; margin-left: 20px; }
  &__status{ @extend %title-font-bold; padding: 0 25px; font-size: 18px; height: 90px; line-height: 90px; width: auto; border: 1px solid $gris-clair; box-sizing: border-box;
    span{ color: $secondary-color; text-transform: none; margin-right: 15px; }
  }
  &__depositary-actions-container{ justify-content: flex-end; display: flex; margin-bottom: 30px; width: 100%; }
  &__type{ @extend %title-font-reg; font-size: 20px; color: $secondary-color; text-transform: uppercase;
    &--1{ color: $main-color; }
    &--projects_procurement{ color: $color-procurement; }
    &--projects{ color: $violet; }
  }
  &__answers{ width: 100%; }
  &__container{ position: relative; width: 1120px; margin: 0 auto; height: 100%; display: flex; }
  &__content{ width: 100%; }
  &__content-header{ display: flex; justify-content: flex-start; width: 100%; align-items: center; margin-top: 30px; }
  &__messages-display{ height: 100%; padding: 0 25px; width: auto; box-sizing: border-box; display: flex; align-items: center; justify-content: space-between;  @extend %title-font-reg; font-size: 24px; color: $main-color;
    &.active{
      .nj-msg-display__arrow{ transform: rotate(0); }
    }
  }
  &__messages{ position: fixed; top: 0; right: -50vw; width: 50vw; height: 100vh; overflow: auto; transition: right 300ms ease-in-out; z-index: 2001; background-color: #fff; border-left: 1px solid $gris-border; padding: 0; box-sizing: border-box;
    &.active{ right: 0; }
  }
  &__messages-close{ position: absolute; top: 20px; right: 20px; z-index: 999; }
  &--detail{
    .nj-msg-display__mail-img{ filter: brightness(0) invert(1); }
    .nj-msg-display__arrow{ filter: brightness(0) invert(1); }
  }
}

.nj__messages{ display: flex; flex-direction: column; padding: 20px;
  .nj__messages__list{ width: 100%; display: flex; flex-direction: column; }

}

.nj-message{ width: 100%; border-radius: 2px; border: 1px solid var(--nj-color-base-primary); padding: 0; display: block; box-sizing: border-box; margin-bottom: 20px;
  &__sender-name{ @extend %title-font-bold; font-size: 1.25rem; display: block; color: $main-color; }
  &__sender-role{ display: block; @extend %title-font-reg; font-size: 1rem; }
  &__date{ @extend %title-font-reg; font-size: 16px; color: $gris; margin-top: 0; display: block; }
  &__content{ width: 100%; overflow: auto; padding: 0; box-sizing: border-box; position: relative; background-color: #fff; max-height: 0; transition: max-height 300ms ease-in-out;
    &.active{ max-height: 100vh;  }
  }
  &__header{ padding: 10px 20px; }
  &__deploy{ border: 1px solid var(--nj-color-base-primary); display: flex; justify-content: center; align-items: center; height: 40px; width: 60px; }
}
.nj-mailbox{ padding: 0 20px 20px 20px;
  &__picto{ background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); width: 50px; height: 50px; display: flex; align-items: center; justify-content: center; }
  &__header{ display: flex; justify-content: space-between; align-items: center;
    &:first-of-type{
      .nj-submission__messages-close{ display: block; }
    }
  }
  &__sender{ display: flex; flex-direction: column; margin-left: 20px; margin-right: auto; }
  &__sender-name{ font-size: 18px; display: block; }
  &__sender-role{ display: block; font-size: 16px; }
  &__headline{ display: flex; align-items: center; justify-content: space-between; margin-top: 0; font-size: 0.75rem; border-top: 1px solid var(--nj-color-base-primary); padding-top: 10px;  }
  &__content{ font-size: 1rem !important; margin-top: 10px; margin-bottom: 10px; padding-bottom: 10px;
    strong, b{ @extend %title-font-bold; }
    &:last-of-type{ border-bottom: none; }
  }
  &__message{ @extend %title-font-reg; font-size: 0.75rem !important;
    > *{ font-size: 0.75rem !important; }
  }
  &__date{ @extend %title-font-reg; font-size: 16px; color: $gris; margin-top: 0; display: block; font-size: 0.75rem; }
  &__receiver{ @extend %title-font-bold; font-size: 16px; color: $main-color;
    span{ color: $gris; }
  }
  &__subject{ @extend %title-font-bold; margin-bottom: 10px;  font-size: 0.75rem; }
  &__reply{ @extend %title-font-bold; font-size: 16px; color: $main-color; text-transform: uppercase; margin: 20px 0; }
  #commentaire-form{ width: 100%;
    .ligne_separation{ margin: 0; }
    .input_line{ margin-bottom: 0; }
    button[type=submit]{ box-shadow: 4px 4px 20px #0003; height: 45px; border-radius: 0; font-size: 16px; padding: 0 20px; line-height: 45px; background-color: $main-color; color: #ffffff; @extend %title-font-bold; border: none; -webkit-appearance: none; display: inline-flex; margin-right: 0; margin-left: auto; }
    .input_line_submits{
      .btn-group{  display: flex; flex-direction: column; justify-content: flex-end; }
    }
    .panel-body{ min-height: 100px; }
  }
  &__backlink{ display: none;
    img{ transform: rotate(90deg); width: 15px; margin-right: 20px;  }
  }
}

.nj-answer{ display: flex; border: 1px solid $gris-clair; margin-bottom: 30px;
  &__picto{ height: 60px; width: 60px; margin-right: 20px; line-height: 60px; text-align: center; background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); display: block; color: #ffffff; flex-shrink: 0; @extend %title-font-bold; font-size: 20px; }
  &__question{  width: 40%; padding:0 20px 20px 0; display: flex;
    div{ margin: 20px; }
    p{ @extend %title-font-reg; font-size: 20px; color: $main-color;
      strong{ @extend %title-font-bold; }
    }
  }
  &__value{ width: 60%; height: 100%; padding: 0 60px 0 30px; box-sizing: border-box; padding: 30px; @extend %title-font-reg; font-size: 16px;
    a{ margin: 0 30px 30px 0; display: inline-block; }
  }
}

.nj-msg-display{ align-items: center;
  &__text{ @extend %title-font-reg; font-size: 18px; display: flex; align-items: center; position: relative; margin-right: 20px;
    img{ margin-right: 20px; }
    &--unread{
      &:before{ content: ''; width: 10px; height: 10px; z-index: 1; border-radius: 8px; background-color: $orange; display: block; position: absolute; left: -4px; top: 0; }
    }
  }
  &__mail-img{ width: 20px; height: auto;

  }
  &__right-container{ display: flex; justify-content: space-between; align-items: center; }
  &__number{ margin: 0 30px 0 20px; color: #ffffff; }
  &__arrow{ transform: rotate(-90deg); transition: transform 100ms ease-in-out; }
}

.nj-admin-submission{
  h1{ font-size: 40px; @extend %title-font-bold; line-height: 1;  }
  &__container{ display: flex; padding-top: 20px; }
  &__date-container{ display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px;
    .date{ @extend %title-font-reg; font-size: 16px; margin-left: 0; }
  }
  &__profile{ display: flex;
    .profile-picture{
      img.opportunite, img.savoir-faire{ border: 3px solid $main-color; }
    }
    .nom-fonction{
      strong{ color: $main-color; }
      p{
        &:last-of-type{
          strong{ color: $gris-fonce; }
        }
      }
    }
  }
  &__depositary{ @extend %title-font-bold; font-size: 16px; margin-bottom: 10px !important; }
  h3{ @extend %title-font-bold; color: $secondary-color; font-size: 18px; margin-top: 20px; margin-bottom: 5px;
    p{ @extend %title-font-bold; color: $secondary-color; font-size: 18px;
      strong{ color: $secondary-color;  font-size: 18px; }
    }
  }
  .intro-exchanges{ @extend %title-font-reg; font-size: 16px;
    h3{ margin-top: 0; margin-bottom: 20px; }
    h4, ul, li, p{ @extend %title-font-reg; font-size: 16px; }
    ul{ list-style: disc; padding-left: 20px; }
  }
  aside{ width: 30%;
    .received-messages{ padding-top: 0;
      &.cote-intervenant{ background-color: $gris-tres-clair; color: $main-color; }
      p.empty{ color: $gris-fonce; font-size: 12px; @extend %title-font-reg; margin: 20px 20px 0 20px;}
    }
    a{ @extend %title-font-bold; font-size: 14px; }
    .buttons-valid-depot{
      a{ background-color: $main-color; }
    }
    .data-btns{ padding: 20px 8px;
      a{ width: 100%; box-sizing: border-box; margin-bottom: 15px;
        &:last-of-type{ margin-bottom: 0; font-size: 14px; }
      }
    }
    .open-exchange{ color: #ffffff; padding: 20px;
      &#send-message{ background-color: $main-color; @extend %title-font-bold; display: flex; justify-content: space-between;
        img{ width: 8px; }
      }
    }
    .messages-area__header{ padding: 20px; margin: 0; @extend %title-font-bold; background-color: $gris-clair; color: $gris-fonce;
      img{ margin-right: 10px; width: 12px; }
    }
    .liste-intervenants{ border-top: 1px solid $nj-bleu-ciel;
      strong{ color: $main-color; }
      p{ font-size: 12px; color: $gris-fonce; }
      li{
        a{ border-top: 1px solid $gris-clair; }
        &:first-of-type{
          a{ border-top: none;  }
        }
        a.intervenant{
          &.deploye{ border-top: 1px solid $nj-bleu-ciel; }
        }
      }
    }
  }
  .main-section{ width: 70%;
    p, div{ @extend %title-font-reg; font-size: 16px; color: #000000; }
    .zone-gauche{ width: 100%; }
  }
}

.nj__container{
  .breadcrumb{ font-size: 12px; margin-top: 20px; display: block; margin-bottom: 20px; }
  .nj__submission{
    .page-header{
      h1{
        strong{ color: $gris-fonce; }
      }
    }
  }
}

@media screen and (max-width: 1250px){
  .nj-submission{
    &__container{ width: 90%; padding: 0; box-sizing: border-box; }
  }
  .nj-admin-submission{ width: 90%; padding: 0; box-sizing: border-box; }
  .nj-submissions-list{
    .nj-submission{ flex-direction: column; align-items: flex-start; }
  }
  .nj-submission__header{
    .nj-submission__container{ padding-top: 0; }
  }
}

@media screen and (max-width: 980px){
  .nj-submission{
    &__container{ width: 100%; padding: 20px 20px 0 20px; box-sizing: border-box; }
  }
  .nj-admin-submission{ width: 100%; padding: 20px 20px 0 20px; box-sizing: border-box; }
}

@media screen and (max-width: 900px){
  .nj-submission{
    &__title-container{ flex-wrap: wrap; justify-content: flex-start; align-items: flex-start; }
    &__title{ width: 100%; margin-right: 0; flex: none; }
    &__header{ background: $gris-tres-clair;
      &.active{ background-color: #ffffff; position: fixed; top: 80px; z-index: 2; width: 100%; }
      .nj-submission__container{
        a{ color: $main-color; }
        .nj-msg-display__mail-img{ filter: none; }
        .nj-msg-display__arrow{ filter: none; width: 12px; }
      }
    }
    &--detail{
      .nj-submission__messages{ box-shadow: none;
        .nj-message{ 
          &__sender-name{ font-size: 16px; }
          &__sender-role{ font-size: 14px; }
          &__date{ font-size: 14px; }
        }
      }
    }
  }
  .nj-mailbox{
    &__backlink{ display: block; }
  }
}

@media screen and (max-width:800px){
  .nj-submission--detail{
    .nj-submission__messages, .nj-detail-submission__messages{ position: fixed; top: 65px; height: 100vh; z-index: 3000; max-height: 100vh; width: 0; background-color: #ffffff;
      &.active{ width: 100%;
        &.content-active{ top: 0; }
        .nj__messages__list{ width: 100%; }
        .nj__messages__content{ position: fixed; top: 0; height: 100vh; max-height: 100vh; width: 100%; z-index: 21; left: -100%; background-color: #ffffff; overflow: auto; transition: left 300ms ease-in-out;
          &.active{ left: 0; z-index: 21; top: 80px !important;  }
        }
      }
    }
  }
  .nj-answer{ flex-direction: column;
    &__question{ width: 100%; padding-bottom: 0; }
    &__value{ width: 100%; }
  }
}

@media screen and (max-width:600px){
  .nj-submissions-list{
    .nj-submission{ flex-direction: column; align-items: flex-start;
      &__status{ display: flex; justify-content: flex-start; align-items: center; line-height: 1; padding: 0 20px; text-align: left; flex-grow: 1; }
      &__messages{ padding: 0 20px; }
      &__actions{ justify-content: stretch; width: 100%; }
    }
  }
  .nj-profile{
    &__info-container{ flex-direction: column; }
    &__identity{ width: auto; margin: 20px 0; align-self: center; text-align: center; }
  }
  .nj-answer{
    &__question {
      p { font-size: 16px; }
      div{ margin: 20px 20px 10px 10px; }
    }
    &__picto{ height: 40px; width: 40px; line-height: 40px; font-size: 16px; }
    &__value{ padding: 15px 30px; }
  }
  .nj-submission__container{
    &--detail{
      .nj-submission__status{ font-size: 16px; line-height: 1; display: flex; justify-content: center; align-items: center; height: 60px;
        span{ min-width: 60px; }
      }
      .nj-submission__title{ font-size: 30px; }
      .nj-submission__update{ height: 60px; }
      .note-editor{
        .note-toolbar{ display: none; }
      }
    }
  }
  .nj-submission{
    &--detail{
      .nj-submission__content-header{ flex-direction: column; align-items: flex-start;
        .nj-submission__date{ margin-left: 0; margin-top: 5px; }
      }
      .nj-submission__header{ height: auto;
        .nj-submission__container{ flex-direction: column; align-items: flex-start; padding: 20px; }
        .nj-msg-display{ padding-left: 0; margin-top: 20px; width: 100%; padding-right: 0;
          span{ flex: 1; font-weight: 700; justify-content: flex-start; }
          &__right-container{ color: $main-color; }
          &__number{ color: $main-color; }
        }
        .nj-submission__backlink{ font-weight: 500; font-size: 12px; }
      }
    }
  }
}

@media screen and (max-width: 450px){
  .nj-mailbox{
    &__header{  }
    &__picto{ width: 40px; height: 40px; }
    &__sender-name{ font-size: 16px; }
    &__sender-role{ font-size: 14px; }
  }
  .nj__messages{
    .nj__messages__content{ padding: 20px; }
  }
}