.nj-wide-card{ height: auto; display: flex; align-items: flex-end; justify-content: flex-end; position: relative; margin-bottom: 50px;
  &__image{ object-fit: cover; width: 100%; height: 100%; }
  &__image-container{ position: absolute; top: 0; left: 0; z-index: 0; height: calc(100% - 40px); width: 75%; display: block; overflow: hidden; }
  &__title{ @extend %title-font-bold; font-size: 32px; line-height: 40px; margin-top: 25px; text-transform: none; margin-bottom: 0; }
  &__actions{ display: flex; justify-content: flex-end; }
  &__content-container{ width: 75%; z-index: 1; margin-top: 0; box-sizing: border-box; display: flex; flex-direction: column; margin-top: 80px; border: 1px solid var(--nj-color-border-subtle);
  }
  &__content{ background: #ffffff; padding: 25px 40px 50px 40px; box-sizing: border-box; min-height: 315px; justify-content: space-between;
    &:hover{
      .nj-wide-card__link{ background-color: $main-color; color: #ffffff;
        img{
          filter: brightness(0) invert(1);
        }
      }
    }
  }
  &__link{ background-color: $main-color; box-shadow: 4px 4px 20px #0003; color: #ffffff;  }
  &__header{ color: $main-color; display: flex; justify-content: flex-start; align-items: flex-start; }
  &__description{ @extend %title-font-reg; font-size: 16px; }
  &__sponsor{ @extend %title-font-bold; font-size: 16px; color: $gris; margin-right: 20px;
    strong{ color: $gris; @extend %title-font-bold; }
  }
  &__theme{ margin: 20px 0; }
  &__date{ @extend %title-font-reg; font-size: 16px; color: $gris; display: flex; align-items: center;
    small{ font-size: 16px; margin-right: 5px; }
    img{ margin-right: 10px; }
  }
  &--home{ margin-bottom: 0;
    .nj-wide-card__content-container{ margin-top: 10%; }
    .nj-wide-card__content{ min-height: 360px; }
    .nj-wide-card__image-container{ top: 0; }
    .nj-wide-card__image{ top: 0; height: calc(90% - 45px); }
    .nj-wide-card__link{ color: $main-color; box-shadow: 4px 4px 20px #0003; background-color: #ffffff;  }
  }
}

@media screen and (max-width: 800px){
  .nj-wide-card{ flex-direction: column; box-sizing: border-box; width: 100%;
    &__image-container{ width: 100%; position: relative; height: 300px; box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.1), 0px 24px 18px rgba(0, 0, 0, 0.06), 0px 9px 16px rgba(0, 0, 0, 0.06);
      img{ object-fit: cover; height: 100%; width: 100%; }
    }
    &__content-container{ width: 100%; margin-top: 0;  }
    &__content{  }
  }
}

@media screen and (max-width: 500px){
  .nj-wide-card{ padding: 2px;
    &__header{ flex-direction: column; }
    &__date{ margin-top: 20px; font-size: 16px; }
    &__content{ padding: 20px; box-shadow: var(--nj-shadow-level-2-dp); }
    &__image-container{ box-shadow: var(--nj-shadow-level-2-dp); }
    &__theme{ margin-top: 100px; }
    &__title{ max-height: 120px; text-overflow: ellipsis; overflow: hidden; }
  }
}