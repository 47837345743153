.nj-event-dates{ padding: 5px; font-weight: bold;
  small{ font-weight: light; }
}

// grid
.nj-events{
  &__container{ position: relative; width: 1120px; margin: 0 auto; height: auto; display: flex; overflow: visible; align-items: flex-start; }
  &__grid-container{ display: flex; flex-direction: row; width: 100%; max-height: 100%; overflow: auto; flex-wrap: wrap; padding-right: 0; box-sizing: border-box; transition: width 300ms ease-in-out; justify-content: space-between; align-items: flex-start; overflow: auto; }
  &__map-container{ width: 0; position: relative; height: 100%; transition: width 300ms ease-in-out; overflow: hidden; }
  &__map{ position: absolute; top: 0; left: 0; z-index: 10; height: 100%; width: 50vw; }
  &__filters{ padding: 25px 0; position: relative; width: 1120px; margin: 0 auto; height: auto; display: flex; flex-direction: column;
    .nj__field{ flex-grow: 1; margin-right: 20px; border-radius: 0; background: transparent; -webkit-appearance: none; }
    .nj__select{ width: 250px; border-radius: 0; background: transparent; -webkit-appearance: none; }
    .nj__btn{ width: 150px; text-align: center; justify-content: center; border-radius: 0; -webkit-appearance: none; }
  }
  &__filters-bis{ display: flex; justify-content: space-between; margin-top: 20px; }
  &__filters-form{ width: auto; display: flex; justify-content: space-between;
    .nj__select{ margin-right: 20px; }
  }
  &__filters-switch-legend{ @extend %title-font-bold; font-size: 14px; color: $gris-fonce; margin-right: 10px; }
  &__filters-switch-container{ display: flex; justify-content: space-between; }
  .nj__event{ width: 550px;  }
  &.map{
    .nj-events__map-container{ width: 50%; overflow: visible; }
    .nj-events__grid-container{ width: 50%; flex-direction: row; flex-wrap: wrap; justify-content: flex-start; align-items: flex-start; }
    .nj-events__container{ height: 60vh; }
  }
  &__reset-filters{ display: block; text-decoration: underline; @extend %title-font-reg; font-size: 14px; color: $main-color; }
  &__btn{ margin: 0 auto 100px; }
}

// card
.nj-event{ display: flex; border: none; margin-bottom: 30px; flex-wrap: wrap; height: 175px; box-sizing: border-box; transition: width 300ms ease-in-out; margin-top: 0;
  &__actions{ position: absolute; top: 175px; left: 0; display: flex; flex-direction: column; width: 100%; transition: top 300ms ease-in-out; justify-content: flex-end; height: 90px; }
  &__action{ height: 45px; width: 100%; display: flex; align-items: center; justify-content: center; @extend %title-font-bold; font-size: 14px;
    &--detail{ color: $main-color; background-color: #ffffff; }
    &--subscribe{ background-color: $main-color; color: #ffffff; }
    &:hover{ box-shadow: 4px 4px 20px #0003; }
  }
  &__image-container{ width: 30%; height: 100%; position: relative; overflow: hidden; }
  &__image{ width: 100%; height: 100%; object-fit: cover; }
  &__content{ width: 70%; padding: 0 15px 15px 30px; display: flex; flex-direction: column; justify-content: space-between; box-sizing: border-box; }
  &__theme{ height: 45px; @extend %title-font-black; background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); align-items: center; box-sizing: border-box; justify-content: center; width: 45px; color: #ffffff; display: flex; font-size: 13px; }
  &__date{ width: auto; display: flex; height: 45px; align-items: center; box-sizing: border-box; border: 1px solid $gris-clair; justify-content: center; @extend %title-font-black; font-size: 13px; padding: 0 20px; color: $nj-bleu-ciel; text-transform: uppercase; }
  &__header{ display: flex; align-items: center; }
  &__footer{ display: flex; justify-content: space-between; align-items: center; margin-right: 20px; }
  &__country{ @extend %title-font-reg; font-size: 16px; text-transform: uppercase; display: flex; align-items: center;
    img{ margin-right: 5px; }
  }
  &__by{ color: lightgrey; @extend %title-font-bold; }
  &__title{ @extend %title-font-bold; font-size: 16px; }
  &__gradient-border{ background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); height: 4px; width: 100%; }
  &--highlight{ background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient);
    .nj-event__title{ color: #ffffff; }
    .nj-event__country{ color: #ffffff;
      img{ filter: brightness(0) invert(1); }
    }
    .nj-event__by{ color: #ffffff; }
    .nj-event__theme{ border: 1px solid #ffffff; border-right: none; background: transparent; }
    .nj-event__date{ border: 1px solid #ffffff; color: #ffffff; }
  }
  &:hover{
    .nj-event__actions{ top: 85px; }
  }
}

@media screen and (max-width: 1250px){
  .nj-events{
    &__container{ width: 90%;
      .nj-events__grid-container{ width: 100%;
        .nj-event{ width: calc((100% - 20px) / 2); }
      }
      .nj-events__map-container{ display: none; }
    }
    &__filters{ width: 90%; box-sizing: border-box; }
    &__filters-switch-container{ display: none; }
    &.map{
      .nj-events__grid-container{ width: 100%; }
      .nj-events__container{ height: auto; }
    }
  }
}

@media screen and (max-width: 990px){
  .nj-events{
    &__container{
      .nj-events__grid-container{
        .nj-event{ width: 100%; }
      }
    }
  }
  .nj-event{
    &__image-container{ width: 40%; }
    &__content{ width: 60%; }
  }
}

@media screen and (max-width: 980px){
  .nj-events{
    &__container, &__filters{ width: 100%; padding: 20px; box-sizing: border-box; }
  }
}


@media screen and (max-width: 750px){
  .nj-events{
   &__filters-form{ flex-wrap: wrap;
     .nj__select{ width: auto; width: calc((100% - 20px) / 2); margin-right: 0; margin-bottom: 20px;
        &:last-of-type{ margin-right: 0; margin-left: 20px; }
     }
   }
  }
}

@media screen and (max-width: 700px){
  .nj-event{
    &__image-container{ width: 30%; }
    &__content{ width: 70%; }
  }
}

@media screen and (max-width: 520px){
  .nj-event{
    &__image-container{ width: 0; }
    &__content{ width: 100%; }
  }
}

@media screen and (max-width: 450px){
  .nj-events{
    &__filters-form{ flex-wrap: wrap;
      input[type="text"]{ width: 100%; margin: 0 0 20px 0; }
    }
  }
}

@media screen and (max-width: 420px){
  .nj-event{ height: auto; min-height: 175px;
    &__footer{ flex-direction: column; }
    &__by{ width: 100%; }
    &__country{ width: 100%; margin-top: 10px; }
    &__content{ height: auto; min-height: 175px; }
  }
}