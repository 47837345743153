.nj-detail{
  &__label{ height: 45px; line-height: 45px; padding: 0 15px; border: 1px solid lightgrey; box-sizing: border-box; background-color: lightgrey; color: white; display: inline-block; text-transform: uppercase; @extend %title-font-med; }
  &__theme{ font-size: 20px; @extend %title-font-reg; color: $secondary-color; text-transform: uppercase; }
  &__status-container{ display: flex; align-items: center; margin-top: 20px; margin-bottom: 32px; }
  &__theme-container{ display: flex; align-items: center; justify-content: space-between; width: 100%; }
  &__date{ font-size: 20px; @extend %title-font-reg; color: $gris; }
  &__year{ height: 60px; line-height: 60px; border: 1px solid lightgrey; box-sizing: border-box; padding: 0 20px; font-size: 20px; @extend %title-font; color: $main-color;
    &--trophy{ height: 30px; line-height: 30px; }
  }
  &__country-container{ display: flex; justify-content: space-between; align-items: flex-end; width: 100%; margin-bottom: 0; margin-top: auto; }
  &__country{ font-size: 16px; @extend %title-font-reg; max-width: 200px; text-transform: uppercase; margin-top: 20px; margin-bottom: 0; display: flex; align-items: center; justify-content: center;
    img{ margin-right: 10px; }
  }
  &__trend{ background-color: var(--nj-color-base-gray-100); padding: 15px 20px; margin-bottom: 1px;
    &.active{ background-color: var(--nj-color-base-gray-300);
      span{ font-weight: bold; font-size: 0.85rem; }
    }
  }
  &__address{ font-size: 16px; @extend %title-font-reg; display: flex; align-items: center; margin-top: 20px; margin-bottom: auto;
    img{ margin-right: 10px; }
  }
  &__know-more{ background-color: $gris-clair; text-align: right; padding: 40px; display: block; margin-bottom: 30px;
    p{ text-decoration: underline; font-size: 16px; @extend %title-font-reg; margin-top: 20px; }
    span{ @extend %title-font-bold; font-size: 24px; color: $main-color; }
  }
  &__discover-iw{ background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); @extend %title-font-bold; font-size: 24px; color: #ffffff; display: block; padding: 40px 20px; display: flex; justify-content: flex-end;
    span{ max-width: 200px; margin-right: 20px; text-align: right; }
    .arrow{  }
  }
  &__vote-btn{ display: flex; align-items: center; @extend %title-font-bold; height: auto; box-sizing: border-box;
    svg{ height: 30px; width: auto;
      .path1{ stroke: $gris; fill: transparent !important; }
      .path2{ fill: $gris; }

    }
    &:hover, &.active{
      svg .path1{ fill: $orange !important; }
      svg .path2{ fill: #fff; }

    }
    &.active{
      &:hover{
        svg .path1{ fill: transparent !important; }
        svg .path2{ fill: $main-color; }
      }
    }
    &:hover{ cursor: pointer;
      .label-hover{ display: block; }
      .label-default{ display: none; }
    }
  }
  &__votes-container{ display: flex; flex-direction: column; align-items: center; justify-content: center; }
  &__event-type{ height: 45px; line-height: 45px; background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); padding: 0 25px; color: #ffffff; @extend %title-font-black; font-size: 14px; z-index: 2; display: flex; align-items: center; justify-content: center; }
  &__dates{ height: 45px; line-height: 45px; border: 1px solid lightgrey; box-sizing: border-box; padding: 0 20px; font-size: 20px; @extend %title-font-black; color: $main-color; text-transform: uppercase; }
  &__dates-container{ display: flex; }
  &__header{ display: flex; width: 100%; height: 45vh; }
  &__image-container{ width: 50%; height: 100%;
    &--innovationweek{ display: flex; align-items: center; justify-content: center; }
  }
  &__image{ object-fit: cover; width: 100%; height: 100%;
    &--contain{ object-fit: unset; width: auto; height: 80%; }
  }
  &__title-container{ width: calc(1120px / 2); padding: 30px 30px 0 30px; display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start; }
  &__title{ font-size: 36px; line-height: 1.3; @extend %title-font-bold; color: $gris-fonce; }
  &__container{ display: flex; position: relative; width: 1120px; margin: 50px auto; height: auto; justify-content: space-between; }
  &__aside{ width: 33%; box-sizing: border-box;
    a{
      img{ max-width: 100%; }
    }
    .nj-discussion__chat{ padding: 20px;
      textarea.nj-form-control{ border: none; }
    }
  }
  &__status{ height: 60px; line-height: 60px; background: $secondary-color; padding: 0 20px; color: #ffffff; @extend %title-font-reg; font-size: 20px; z-index: 2; display: flex; align-items: center; justify-content: center;
    &--laureat{ background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); }
    &--select, &--nominee{
      &.color2020{ background-color: #23d2b5;  }
    }
    &--trophy{ height: 30px; line-height: 30px; }
    img{ margin-right: 10px; }
  }
  &__sponsor{ @extend %title-font-bold; font-size: 18px; margin-top: 20px; text-transform: uppercase; color: $gris; }
  &__bu{ @extend %title-font-bold; font-size: 16px; margin-top: 20px; text-transform: uppercase; color: $gris; }
  &__closure-date{ background-color: $gris2; margin-top: 0; margin-bottom: 30px; padding: 20px 30px; text-align: right; @extend %title-font-reg; color: #ffffff; font-size: 20px; display: flex; justify-content: flex-end;
    span{ display: block; font-size: 24px; letter-spacing: 4px; }
    img{ margin-left: 20px; display: block; }
    &--active{ background-color: $secondary-color; }
  }
  &__team-project{ background-color: $gris-clair; text-align: right; padding: 25px 20px;
    p{ @extend %title-font-bold; font-size: 24px; margin-bottom: 10px; color: $main-color; }
    ul{ list-style: none;
      li{ font-size: 16px; @extend %title-font-reg; margin-bottom: 10px; }
    }
  }
  &__winners{ background-color: $secondary-color; color: #ffffff; padding: 40px; text-align: right;
    h3{ @extend %title-font-bold; font-size: 24px; margin-bottom: 25px; }
    ul{
      li{ @extend %title-font-bold; font-size: 16px; margin-top: 5px; }
    }
  }
  &__finalists{ background-color: $main-color; color: #ffffff; padding: 40px; text-align: right;
    h3{ @extend %title-font-bold; font-size: 24px; margin-bottom: 25px; }
    ul{
      li{ @extend %title-font-bold; font-size: 16px; margin-top: 5px; }
    }
  }
  &__previsional-schedule{ background-color: $gris-clair; padding: 40px; text-align: right; margin-top: 30px;
    h3{ color: $main-color;  @extend %title-font-bold; font-size: 24px; margin-bottom: 25px; }
    p{ @extend %title-font-bold; font-size: 16px; margin-top: 10px; }
    span{ @extend %title-font-reg; font-size: 16px; }
  }
  &__actions-container{ display: flex; justify-content: space-between; width: 100%; align-items: flex-end; justify-self: flex-end; margin-top: auto; margin-bottom: 0; }
  &__content-image{ width: 100%; margin-bottom: 20px; }
  &__content{ width: calc(67% - 30px); box-sizing: border-box;
    span, p, h4, blockquote{ font-size: 16px !important; margin-bottom: 20px; @extend %title-font-reg;
      strong, b{ @extend %title-font-bold; font-size: 16px; }
      span{ font-size: 16px;  font-family: inherit !important; }
    }
    img, iframe{ max-width: 100%; }
    ul{ list-style-type: square; padding-left: 30px; overflow: hidden;
      ::marker{ color: var(--nj-color-palette-teal-600); }
      li{ margin-bottom: 5px; }
    }
    ol{ padding-left: 30px; list-style: decimal;
      li{ margin-bottom: 5px; }
    }
    blockquote{ padding: 10px 20px;  margin: 0 0 20px;  border-left: 5px solid #eeeeee; font-style: italic;  }
    h2{ line-height: 1.2; @extend %title-font-reg; color: $main-color; margin-bottom: 24px; margin-top: 32px; font-size: 24px !important; line-height: 24px;
      &:first-of-type{ margin-top: 0; }
      span{ font-size: 24px; line-height: 1.2; @extend %title-font-reg; color: $main-color; margin-bottom: 24px; margin-top: 32px; font-size: 24px !important; line-height: 24px; }
    }
    h3{ font-size: 18px !important; line-height: 1.2; @extend %title-font-bold; color: $secondary-color; margin-bottom: 10px; margin-top: 20px;
      &:first-of-type{ margin-top: 0; }
      span{ @extend %title-font-bold; color: $secondary-color; font-size: 18px !important; }
    }
    table{
      tr{
        td{ vertical-align: top; padding: 8px; line-height: 1.428571429; border: 1px solid #dddddd; font-size: 0.9rem !important;
          p{ display: table-cell; font-size: 0.9rem !important; }
          img{ vertical-align: middle; border: 0; max-width: none; }
        }
      }
    }
    a{ color: $main-color !important;
      span{ color: $main-color !important; }
      &.nj__btn--answer-cfp{ color: #ffffff !important; }
      &.nj__btn--teams{ margin-top: 20px; color: #ffffff !important; background-color: $main-color; }
      &.nj-detail__media-link{ color: #ffffff !important; }
      &.nj__btn--primary { justify-self: flex-end; margin-top: 20px;
        span {  color: #ffffff !important;  @extend %title-font-bold; font-size: 14px !important; }
        img{ margin-left: 20px; }
      }
      &.nj-file__dl{ color: #fff !important; }
    }
    .nj-detail__bibliography{
      blockquote,p,a,b,strong,span,label{ font-size: 11px !important; }
    }
    &--sustainable{
      h2{ font-size: 2rem !important; line-height: 1.1; }
    }
    &--emerging1{ width: 100%; padding-left: 20px; padding-right: 20px; }
  }
  &__content-btn-container{ display: flex; justify-content: flex-end;
    a.nj-btn--primary{ color: white !important; }
  }
  &__sources-container{ display: flex; margin-top: 50px; align-items: flex-start; flex-direction: column;
    a{ margin-bottom: 0;  }
  }
  &__media-link{ @extend %title-font-bold; background-color: $secondary-color; color: #ffffff !important; display: block; padding: 0 20px; height: 45px; line-height: 45px; font-size: 14px; margin-top: 10px; }
  &__source{ font-size: 16px; @extend %title-font-reg;
    a{ @extend %title-font-bold; color: $secondary-color !important; }
  }
  &--cfp{
    .nj-detail__title{ margin-top: 30px; }
  }
  &--news{
    .nj-detail__title{ margin-top: 30px; margin-bottom: auto; }
  }
  &--event{
    .nj-detail__title{ margin-top: 30px; }
  }
  .nj-aside{
    &__title{ font-size: 24px; line-height: 1.3; @extend %title-font-reg; color: $gris-fonce; margin-bottom: 30px; text-align: right; margin-top: 0; }
    &__keyphrase{ background-color: $secondary-color; @extend %title-font-reg; font-size: 24px; color: #ffffff; text-align: right; padding: 40px; display: block; }
    &__photo-interview{ margin-top: 40px; margin-bottom: 0;
      img{ max-width: 100%; }
    }
    &__citation{ background-color: $gris-clair; align-items: flex-end; padding: 40px; margin-top: 40px; display: flex; flex-direction: column;
      img{ height: 52px; width: auto; align-self: flex-start; margin-bottom: 20px; }
      p{ @extend %title-font-reg; font-size: 20px; text-align: right; }
      footer{ background: transparent; margin-top: 20px;
        cite{ font-style: italic; }
      }
    }
  }
  .nj-discussion{
    &__chat{ background-color: $gris-blanc; margin-top: 40px;
      span.new{ margin-bottom: 0; @extend %title-font-bold; color: #000; }
      textarea{ background-color: transparent; border: 1px solid $gris3; }
    }
    &__message{ border-top: 1px solid $gris3; border-bottom: none;
      &:first-of-type{ border-top: none; }
    }
    &__messages-empty{ text-align: center; padding-bottom: 20px; font-size: 12px; }
    &__message-content{ font-size: 14px; }
    &__message-author{ font-size: 14px; }
    &__message-date{ font-size: 12px; }
  }
}


.note-editor { 
  ul { list-style-type: square; padding-left: 30px; list-style-image: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); overflow: hidden;
    li { margin-bottom: 5px;  }
  }
  ol {  padding-left: 30px; list-style: decimal;
    li { margin-bottom: 5px;  }
  }
}

#links-clicked{
  ul{ list-style-type: square; margin-top: 30px; padding-left: 30px; list-style-image: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); overflow: hidden;
    li{ margin-bottom: 5px;
      a{ font-size: 12px; }
      p{ display: inline-block; margin-left: 30px; @extend %title-font-bold; }
    }
  }
}

@media screen and (max-height: 800px) and (min-width: 1000px){
  .nj-detail{
    &__title{ font-size: 2vw; }
    &__winners{ padding: 20px 30px; }
  }
}

@media screen and (max-width: 1250px){
  .nj-detail{
    &__container{ width: 100%; }
    &__title-container{ width: 50%; box-sizing: border-box; }
    &__aside{ padding-left: 20px; }
    &__content{ padding-right: 20px; }
  }
}

@media screen and (min-width: 1000px) and (max-width: 1250px){
  .nj-detail{
    &__title{ font-size: 2.8vw; }
  }
}

@media screen and (max-width: 1000px){
  .nj-detail{
    &__header{ flex-direction: column; height: auto; }
    &__image-container{ width: 100%; height: 300px; }
    &__title-container{ width: 100%; }
    .nj__socials-share-container{ margin-top: 30px; }
    &__aside{ padding-left: 20px; }
  }
}

@media screen and (max-width: 700px){ 
  .nj-detail{
    &__container{ flex-wrap: wrap; }
    &__aside{ width: 100%; padding: 0 20px; text-align: left; }
    &__title-container{ padding: 20px 20px 0 20px; }
    &__content{ width: 100%; padding: 0 20px; margin-top: 30px;
      table{
        tr{ display: flex; flex-direction: column;
          td{ width: 100%; }
        }
      }

    }
    .nj-aside{
      &__title{ text-align: left; }
    }
  }

}

@media screen and (max-width: 500px){
  .nj-detail{
    &__dates-container{ flex-direction: column; justify-content: stretch; width: 100%; }
    &__event-type{ text-align: left; justify-content: flex-start; font-size: 16px; }
    &__dates{ font-size: 16px; }
    &__actions-container{ flex-direction: column-reverse; align-items: flex-start; box-sizing: border-box;
      .nj__socials-share-container{ margin-top: 10px; }
    }
    .nj__btn--answer-cfp{ width: 100%; margin-top: 20px; box-sizing: border-box; }
  }
}