.nj-admin-form{ display: flex; width: 100%; justify-content: space-between; flex-wrap: wrap; align-items: flex-start;
  h3{ width: 100%; }
  .input_line{ width: 100%;
    &.fourth{ width: calc((100% - 150px) / 4); }
    &.third{ width: calc((100% - 100px) / 3); }
    &.three-fourth{ width: calc((100% - ((100% - 150px) / 4)) - 50px); }
    &.mid{ width: calc((100% - 50px) / 2); }
    label{ position: absolute; display: inline-block; margin-bottom: .5rem; white-space: nowrap; top: 1rem; left: 0; font-size: .75rem; }
  }
  .legend_obligatoire{ display: none; }
  .btn-group:not(.note-btn-group){ padding: 0; margin-top: 20px; }
  .order-buttons{ position: absolute; right: -30px; top: 0; background-color: #fff;
    .order-button{
      &:last-of-type{ margin-top: 1px; }
      &:hover{ cursor: pointer; }
      &.bg-primary{
        &:hover{ background: var(--nj-color-base-primary-hover) !important; }
      }
    }
  }
  .duplicate-button{ position: absolute; right: 1px; top: 0;
    &:hover{ cursor: pointer; }
  }
  .pages-sections-sections{ width: 100%; }
  .pages-sections-section{ display: flex; position: relative; width: 100%; justify-content: space-between; flex-wrap: wrap; align-items: flex-start;
    .note-editor.note-frame .note-editing-area .note-editable{ max-height: 350px; }
  }
}

.analytics{
  &__filters{
    .nj-form-item{ margin-right: 20px; }
    .nj-form-item__field-wrapper{
      .nj-form-item__field.datepicker{ padding: inherit; margin-top: 0; padding-top: var(--nj-fc-field-wrapper-pad-top); }
    }
    .nj-btn{ margin-top: -1px; }
  }
}

.nj-admin-filters{ overflow: hidden;
  &.hide{ padding: 0 !important; }
}

.nj-dashboard-admin{
  h2{ color: $main-color; margin-top: 40px;
    &:first-of-type{ margin-top: 0; }
  }
  &__row{ display: flex; justify-content: space-between; margin-top: 20px;
    &--two{ justify-content: flex-start;
      .nj-dashboard-admin__widget{
        &:first-of-type{ margin-right: 20px; }
      }
    }
  }
  &__widget{ width: calc((100% - 40px) / 3); display: flex; height: 80px; }
  &__main-link{ background-color: $gris-tres-clair; color: $gris-fonce; @extend %title-font-bold; padding: 20px; flex: 1;
    &:hover{ background-color: $gris-clair; color: #000; @extend %title-font-black; }
  }
  &__secondary-links{ display: flex; flex-direction: column; height: 100%; width: 45px; }
  &__secondary-link{ background-color: $main-color; color: #fff; flex: 1; display: flex; align-items: center; justify-content: flex-start; padding: 0 15px; box-sizing: border-box;
    img{ width: 15px; }
    span{ @extend %title-font-bold; font-size: 12px; }
    &:last-of-type{ background-color: #fff; color: #000; box-shadow: 4px 4px 20px #0003;
      img{ width: 17px; }
    }
    &--submissions{ background-color: $secondary-color !important;
      img{ filter: invert(1); }
    }
  }
}

.nj-admin{ position: relative; width: 1120px; margin: 0 auto; height: 100%;
  &--emailings{ width: 100%; }
  .download-pdfs{ text-align: center; display: block; margin: 0 auto;
    i{ background: url('../images/min/download-pdfs.svg') no-repeat center center; background-size: contain; width: 50px; height: 64px; display: block; margin: 0 auto;  }
  }
  .chosen-container{ height: 45px;
    .chosen-choices{ height: 45px !important; }
  }
  .newsletter-actions{
    .nj__link{ font-size: 12px; }
  }

  .note-editor{
    .note-editable{ max-height: calc(100vh - 350px); overflow: auto; }
  }

  select[name=KromiFormLangSwitcher]{ margin: 0 20px 20px 20px; height: 45px; }
  .kromi-list{ display: flex; flex-wrap: wrap; justify-content: flex-end; width: 100%;
    .row-fluid{ width: 100%;
      #kromiforms-sidebar{ max-height: 800px; transition: max-height 300ms ease-in-out; background-color: $gris-tres-clair;
        &.hide{ max-height: 0; }
        form{ display: flex; flex-wrap: wrap; padding: 20px; justify-content: space-between;
          .form-group{ width: calc(50% - 20px); margin: 0; box-sizing: border-box; display: flex; flex-direction: column; margin-bottom: 20px;
            label{ width: 100%; @extend %title-font-bold; margin-bottom: 10px; }
            .chosen-container{ width: 100%; height: 45px; border: none;
              .chosen-single{ height: 100%; -webkit-appearance: none; border: none; box-shadow: none; border-bottom: 1px solid $gris; background: transparent; border-radius: 0; background-color: transparent;
                span{ height: 100%; }
              }
            }
            input[type=text], textarea, select{ width: 100%; height: 45px; box-sizing: border-box; background-color: transparent; -webkit-appearance: none; border: none; border-bottom: 1px solid $gris; border-radius: 0; }
          }
          .nj-admin-filters__btns{ width: 100%; display: flex; align-items: center; justify-content: flex-end;
            input[type=submit]{  }
          }
        }
      }
      #kromiforms-list{ margin-top: 40px;
        table{
          th{
            a{ @extend %title-font-black; }
          }
          tr{

          }
          th{ text-align: left;
            &:last-of-type{ padding: 0; }
          }
          td, th{ border: 1px solid $gris-clair; padding: 10px 20px; @extend %title-font-reg; font-size: 14px; }
          td.list-actions-buttons{ width: 120px; text-align: center;
            a{ display: inline-block; text-align: center; width: 40px; height: 40px; box-sizing: border-box; vertical-align: top;
              img{ height: 15px; display: inline-block; margin-top: 10px; }
              &.modif{ background-color: $main-color; border: 1px solid $main-color;
                img{ height: 15px; }
              }
              &.visual{ background-color: $secondary-color; border: 1px solid $secondary-color;
                img{ height: 14px; margin-top: 12px; }
              }
              &.delete{ border: 1px solid $gris-clair;
                img{ margin-top: 12px; }
              }
            }
          }
        }
      }
    }
    .page-header{ width: 100%; }
    &__actions{ width: 100%; display: flex; justify-content: flex-end; flex-direction: row;
      .btn__primary{ display: block; margin-left: auto; margin-right: 0; }
      #toggle-filtres{ margin-left: 0; margin-right: auto; }
    }
  }
}

.kromiforms{
  .page-header{ display: flex; margin: 40px 0;
    h1{ font-size: 30px; line-height: 30px; @extend %title-font; }
  }
  .btn.pull-right{ float: none !important; }
  .nj__back-to-list{ position: absolute; right: 0; top: 0; text-decoration: underline; font-size: 13px; }
  .input_line.input_line_submits{ width: 100%; padding: 0;
    button[type=submit]{ margin-right: 20px; }
  }
}

.rgpd{
  h2{ margin-top: 40px; @extend %title-font-reg; color: $main-color; line-height: 1.3;
    strong{ @extend %title-font-reg; }
  }
  h3{ @extend %title-font-reg; margin-top: 30px; margin-bottom: 10px; display: block; }
}

.js-element-list{
  .cell-title{ @extend %title-font-reg; color: #000; font-size: 14px; background-color: $gris-clair; padding: 0 20px; }
  .dragHandle{ width: 40px; height: 40px; background-color: $gris-tres-clair; text-align: center; vertical-align: middle;
    img{ width: 14px; }
  }
  .drag{ padding-bottom: 20px; @extend %title-font-reg; color: #000; }
  .js-delete-element{ width: 40px; height: 40px; background-color: $gris-tres-clair; display: flex; align-items: center; justify-content: center;
    img{ width: 10px; }
  }
}

.nj__admin{
  #innovations_newsletters{
    .kromiforms-form {
      .ligne_separation { margin-bottom: 0;
        h3 { margin-top: 0 !important; line-height: 1; }
      }
      #input_line_ids_opportunities_select{ margin-bottom: 0;  }
      .nl-element-list{ margin-bottom: 30px;
        &.active{ margin-top: 10px; }
        thead{ display: none; }
      }
      .input_legend{ display: none; }
    }
  }
}

#input_ids_submissions_select_chosen, #input_ids_events_select_chosen, #input_ids_articles_select_chosen, #input_ids_opportunities_select_chosen{ width: 100% !important; }

#newsletter-send{ display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 50px 0; margin: 50px 0;
  h3{ margin-bottom: 30px;  @extend %title-font-reg; }
}

#emailings-preview{ background-color: #f2f2f2;
  table{ margin: 0 auto; }
}

.emailing-test-form-section{ display: flex; padding: 20px 0; background-color: #f2f2f2; justify-content: center;
  &__container{ display: flex; padding: 20px 0; width: 640px; justify-content: space-between; }
  form{ display: flex; align-items: center;
    .nj__field{ border: 1px solid $gris; }
  }
  .nj__btn{ margin-right: 0; margin-left: auto;
    &:last-of-type{ margin-left: 10px; display: flex; align-items: center;
      img{ height: 20px; margin-left: 20px; }
    }
  }
}

#newsletter-preview{ display: flex; flex-direction: column; align-items: center; background-color: #f2f2f2; padding: 50px 0; margin: 50px 0;
 h3{ margin-bottom: 30px; }
}

.mail-preview{ display: flex; justify-content: center; background-color: #f2f2f2; padding: 40px 0; }

@media screen and (max-width: 1250px){
  .nj-admin{ width: 90%; padding: 0; box-sizing: border-box;
    &--emailings{ width: 100%; }
  }
}

@media screen and (max-width: 980px){
  .nj-admin{ width: 100%; padding: 20px 20px 0 20px; box-sizing: border-box; }
}

#admin-dashboard { margin: 0 auto; padding-top: 30px; position: relative;
  h1 { margin: 30px 0; display: block; color: $main-color;}
  h2 {display: block; }
  h3 {font-size: 14px; line-height: 45px; }
  .set{ display: flex; justify-content: flex-start; flex-wrap: wrap; margin: 20px 0 40px; }
  .bloc { border: 1px solid $main-color; display: inline-block; width: 31%; margin-bottom: 20px; margin-right: 20px; display: block; text-align: center; padding: 0 20px; height: 45px; line-height: 45px; box-sizing: border-box;

    h3 { color: $main-color; @extend %title-font-black; }
    &.green{ border: 1px solid $nj-vert;
      a{
        h3{ color: $nj-vert; }
      }
      &:hover{ background-color: $nj-vert;
        h3 { color: #fff; }
      }
    }
    &:hover { background-color: $main-color;
      h3 { color: #fff; }
    }
  }
  .version { clear: both; text-align: center; margin: 10px 0; font-size: 13px; }
  .breadcrumb { position: absolute; top: 5px; left: 0; font-size: 12px; color: #5f5f5f; }
}


