h2{  font-size: 30px; line-height: 30px; @extend %title-font;
  strong{ @extend %title-font-bold; }
}

h3 { font-size: 20px; line-height: 20px; @extend %title-font;
  strong{ @extend %title-font-bold; }
}

body{
  a{ text-decoration: none; color: inherit;
    &:hover{ text-decoration: none; color: inherit; }
  }
}

.nj__socials-share-container{ margin-top: auto; margin-bottom: 0; }
.nj__socials-share{ display: flex; align-items: center; list-style: none;
  li{
    a{ margin-right: 10px;
      img{ filter: invert(0.5); height: 20px; width: auto;  }
      &:hover{
        img{ filter: invert(1); }
      }
    }
    &:last-of-type{
      a img{ height: 13px; margin-top: 4px; margin-left: 5px; }
    }
  }
}

.new-content-area{
  h4{ font-size: 1.125rem; font-weight: bold; margin-top: 2rem; }
  h3{ font-size: 1.25rem; color: var(--nj-color-base-blue-corporate); font-weight: bold; margin-top: 2rem; }
  h2{ font-size: 1.5rem; color: var(--nj-color-base-blue-corporate); font-weight: bold; margin-top: 2rem; }
  ul{ list-style-type: square; padding-left: 30px; list-style-image: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); overflow: hidden;
    li{ margin-bottom: 5px; }
  }
  ol{ padding-left: 30px; list-style: decimal;
    li{ margin-bottom: 5px; }
  }
  article{
    p{
      a{ color: var(--nj-color-base-blue-corporate); }
    }
  }
}

.d-flex-wrap{ flex-wrap: wrap; }

.stepped-form-hidden { display: none !important; }

.nj-icon::before{ background: var(--nj-color-palette-ultramarine-900) !important; }

.nj-opportunity{
  &__prev{ background-color: $gris-tres-clair; height: 55px; display: flex; align-items: center; justify-content: center; width: 55px; position: fixed; left: 0; top: auto; margin-top: 80px;
    &:hover{ background-color: $gris-clair; }
  }
  &__next{ background-color: $gris-tres-clair; height: 55px; display: flex; align-items: center; justify-content: center; width: 55px; position: fixed; right: 0; top: auto; margin-top: 80px;
    &:hover{ background-color: $gris-clair; }
  }
}

.admin-list{
  table{
    th{
      a{ @extend %title-font-black; }
    }
    tr{

    }
    th{ text-align: left;
      &:last-of-type{ padding: 0; }
    }
    td, th{ border: 1px solid $gris-clair; padding: 10px 20px; @extend %title-font-reg; font-size: 14px; }
    td.list-actions-buttons{ width: 180px; text-align: center;
      a{ display: inline-block; text-align: center; width: 40px; height: 40px; box-sizing: border-box; vertical-align: top;
        img{ height: 15px; display: inline-block; margin-top: 10px; }
        &.modif{ background-color: $main-color; border: 1px solid $main-color;
          img{ height: 15px; }
        }
        &.visual{ background-color: $secondary-color; border: 1px solid $secondary-color;
          img{ height: 14px; margin-top: 12px; }
        }
        &.delete{ border: 1px solid $gris-clair;
          img{ margin-top: 12px; }
        }
      }
    }
  }
}


.nj-special-banner{ height: 450px; position: relative; margin-bottom: 150px;
  &__cover{ height: 100%; width: 100%; position: absolute; top: 0; left: 0;  }
  &__image{ display: block; object-fit: cover; height: 100%; width: 100%; }
  &__block{ position: absolute; bottom: -100px; right: 0; box-sizing: border-box; height: auto; width: 400px; padding: 30px 40px; background-color: #fff; border: 1px solid var(--nj-color-border-subtle);  }
  .container{ height: 100%; }
}

/* The switch - the box around the slider */
.nj-switch {position: relative;display: inline-block; width: 50px; height: 20px;
  &__checkbox{ opacity: 0; width: 0; height: 0;
    &:checked + .nj-switch__slider { background: $secondary-color; }
    &:focus + .nj-switch__slider { box-shadow: 0 0 1px #2196F3; }
    &:checked + .nj-switch__slider:before { border-radius: 50%; -webkit-transform: translateX(30px); -ms-transform: translateX(30px); transform: translateX(30px); }
  }
  &__slider { position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #ccc; -webkit-transition: .4s; transition: .4s; border-radius: 20px;
    &:before { position: absolute; content: ""; height: 16px; width: 16px; left: 2px; bottom: 2px; background-color: white; -webkit-transition: .4s; transition: .4s; border-radius: 50%; }
  }
}

.nj-list-filters{ box-shadow: var(--nj-shadow-level-2-dp); padding: 20px;
  .nj-form-group{ overflow: hidden; text-overflow: ellipsis; }
}

.nj-sub-filters{ box-shadow: var(--nj-shadow-level-2-dp); padding: 20px; position: relative;
  &__loader-text{ margin-top: 10px; }
  &__loader{ position: absolute; z-index: 2; background: rgba(255, 255, 255, 0.8); width: 100%; top: 0; bottom: 0; left: 0; display: none; flex-direction: column; align-items: center; justify-content: center;
    &.active{ display: flex; }
    .nj-spinner{ height: 40px; width: 40px; }
  }
  &__hidden-filters{ max-height: 0; overflow: hidden;
    &.active{ max-height: 100px; overflow: visible; }
  }
  &__opportunity{ display: none;
    &.active{ display: block; }
  }
  &__startup{ display: none;
    &.active{ display: block; }
  }
  &__bu{ display: none;
    &.active{ display: block; }
  }
  &__button{ display: none !important;
    &.show{ display: block !important; }
  }
  label.label-disabled{ opacity: 0.3; }
  label.label-disabled + span{ opacity: 0.3; }
  select:disabled + div + label{ opacity: 0.3; }
}

.js-total-results{ display: none;
  &.active{ display: block; }
}

.js-post-results{ display: none;
  &.active{ display: block; }
}

.js-sub-search-deploy-filters{
   span, i{ display: none;
     &.active{ display: inline-block; }
   }
}

.js-trophies-search-deploy-filters{ 
  span, i{ display: none;
    &.active{ display: inline-block; }
  }
}

.nj-trophies-reset-filters{ background: none; border: none; position: absolute !important; bottom: -40px; right: 200px;
  &--fr{ right: 160px; }
  &--en{ right: 120px; }
}

.nj-techno-reset-filters{ background: none; border: none; position: absolute !important; bottom: -40px; right: 0; }

.nj-sub-reset-filters{ background: none; border: none; position: absolute !important; bottom: -40px; right: 200px;
  &--fr{ right: 160px; }
  &--en{ right: 120px; }
}

.nj-search-categories{ width: 300px; }

.nj__container{ position: relative; width: 1120px; margin: 0 auto; }

.nj-settings{
  .nj__form--subscription.inscription .input_line{ margin: 0; }
  .nj-tab{ width: 80%; }
  .checkbox-group{
    label{ margin-bottom: 0; }
    .nj-checkbox{ padding-top: 18px !important; }
  }
}

.nj-detail-deposit{
  h2{
    small{ display: block; font-size: 1rem; }
    label{
      p{ margin: 0; }
    }
  }
  img{ max-width: 100%; }
}

.nj-sub-filters{
  .nj-form-group{
    .chosen-container{ margin-top: 10px; width: 240px !important;
      .chosen-single{ background: transparent; border: none; height: auto; padding-bottom: 5px; box-shadow: none; border-bottom: 1px solid grey; border-radius: 0; color: #000; font-size: 1rem; font-weight: 400;
        > div{  display: none; }
      }
      .chosen-drop{ border: none;

      }
    }
  }
}

.page404 { text-align: center;
  a#link-back-home { display: inline-block; color: white; position: relative; margin-top: 20px; }
}

#sustainable_technologies_download{
  .kromi-list{
    .kromi-list__actions{
      a{ display: none; }
    }
  }

}

.nj-header .nj-header__menu-title+.nj-header__nav--panel li:first-child{ display: block; }

@media screen and (max-width: 1250px){
  .nj__container{ width: 100%; padding: 20px 20px 0 20px; box-sizing: border-box; }
}

@media screen and (max-width: 1200px){
  .nj-sub-filters{
    .sub-chosen-filters{
      .nj-form-group{  width: calc((100% - 4.5rem)/4);
        .chosen-container{ margin-top: 10px; width: 100% !important; }
      }
    }
    &__hidden-filters{
      .nj-form-group{  width: calc((100% - 4.5rem)/4);
        .chosen-container{ margin-top: 10px; width: 100% !important; }
      }
    }
  }
}

@media screen and (max-width: 992px){
  .nj-list-filters{ flex-wrap: wrap;
    .nj-form-group{ width: 100%; margin-right: 0 !important; margin-left: 0 !important; }
    .nj-btn{ align-self: flex-end; margin-right: 0; margin-left: auto; margin-top: 20px; }
  }
  .js-export-submissions-to-zip, .nj-export-submissions-csv{ display: none !important; }
}

@media screen and (max-width: 800px){
  .nj-sub-filters{
    .sub-chosen-filters{ flex-wrap: wrap;
      .nj-form-group{  width: 100%;
        .chosen-container{  }
      }
    }
    .nj-sub-filters__types{ flex-wrap: wrap;
      .nj-checkbox{ width: 100%; margin-left: 0 !important; }
    }
  }
}

@media screen and (max-width: 600px){
  .nj-detail-deposit{
    &__header{ flex-wrap: wrap;
      a{ align-self: flex-end; margin: 20px 0; }
    }
    h1{ font-size: 2.5rem; }
  }
  .nj-special-banner{
    &__cover{ height: 100%; width: 100%; position: absolute; top: 0; left: 0;  }
    &__image{ display: block; object-fit: cover; height: 100%; width: 100%; }
    &__block{  width: calc(100% - 40px); }
    .container{ height: 100%; }
  }
}

@media screen and (max-width: 350px){
  .nj-list-filters{
    .nj-btn{ width: 100%; margin-left: 0 !important; }
  }
}