.nj-section-header{ margin-top: 50px; margin-bottom: 10px;
  &__container{ display: flex; }
  &__title{  }
  &__subtitle{ margin-bottom: 5px; margin-top: 15px; }
  &__content{ margin-left: 20px; }
  .nj-icon{ font-size: 9rem;
    &.nj-icon-enlighten{ font-size: 6rem; }
  }
  &--small-margin{ margin-top: 5px; }
}


@media screen and (max-width: 800px){
  .nj-section-header{
    &__container{ flex-wrap: nowrap; }
    .t-tera{ font-size: 2.5rem; }
    .t-mega{ font-size: 1.75rem; }
    &__content{ margin-left: 0; }
    .nj-icon{ font-size: 6rem; }
  }
}

@media screen and (max-width: 500px){
  .nj-section-header{
    .nj-icon{ font-size: 4rem; }
    .t-tera{ font-size: 2rem; }
    .t-mega{ font-size: 1.25rem; }
  }
}