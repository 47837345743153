.nj-profile{
  &__container{ position: relative; width: 1120px; margin: 0 auto; height: auto; }
  &__header{ background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); color: #ffffff; padding: 25px 0; }
  &__name{ @extend %title-font-reg; font-size: 24px; display: flex; align-items: center; }
  &__startup{ @extend %title-font-bold; font-size: 16px; }
  &__bu{ @extend %title-font-bold; font-size: 16px; }
  &__entity{ @extend %title-font-reg; font-size: 16px; }
  &__info-container{ display: flex; justify-content: space-between; align-items: center;
    &--expert-access{ align-items: flex-start; justify-content: flex-start;
      h1{ @extend %title-font-reg; width: 350px; display: block; font-size: 26px;
        strong{ @extend %title-font-black; }
      }
      p{ flex: 1; font-size: 16px;}
    }
  }
  &__main-actions{ display: flex; justify-content: center; position: relative; width: 1120px; margin: 0 auto; height: auto; padding: 40px 0;

  }
  &__main-action{ display: flex; flex-direction: column; align-items: center; padding: 0 40px; width: 50%;
    h2{ @extend %title-font-black; font-size: 20px; }
    p{ text-align: center; }
    a{ margin-top: 20px; font-size: 14px; }
  }
  &__ea-list{ display: flex; flex-direction: column; width: 100%;
    a{ padding: 40px 0; border-bottom: 1px solid $gris-clair; display: flex; justify-content: space-between; align-items: center;
      &:last-of-type{ border: none; }
      span{  }
      label{ flex: 1; padding-right: 40px;}
    }
  }
  &__identity{ margin-left: 30px; margin-right: auto; display: flex; flex-direction: column; justify-content: space-between; align-self: stretch; }
  &__picture{ border-radius: 50%;  }
}

.nj-subheader{ background-color: $gris-clair;
  &__container{ position: relative; width: 1120px; margin: 0 auto; height: auto; display: flex; justify-content: space-between; align-items: center;
    h1{ display: block; @extend %title-font-reg; font-size: 32px; color: $gris-fonce; padding: 30px 0; }
  }
  &__actions{
    a{
      &:first-of-type{ margin-right: 20px; }
    }
  }
}

.nj-accordion{  background-color: $gris-clair;
  &__container{ position: relative; width: 1120px; margin: 0 auto; height: auto; display: flex; justify-content: space-between; }
  .nj-accordion__item{
    .nj-accordion__container{
      img{ transform: rotate(-90deg); transition: transform 100ms ease-in-out; }
    }
  }
  &__content{ max-height: 0; height: auto; overflow: hidden; background-color: #ffffff; }
  &__item{ display: block; @extend %title-font-reg; font-size: 32px; color: $gris-fonce; padding: 30px 0;
    &.active{
      + .nj-accordion__content{ max-height: 999999px; }
      .nj-accordion__container{
        img{ transform: rotate(0); }
      }
    }
    .nj-accordion__container{
      img{ transform: rotate(-90deg); transition: transform 100ms ease-in-out; }
    }
  }
}

@media screen and (max-width: 1250px){
  .nj-accordion, .nj-profile, .nj-subheader{
    &__container{ width: 90%; padding: 0; box-sizing: border-box; }
  }
}

@media screen and (max-width: 980px){
  .nj-accordion, .nj-profile, .nj-subheader{
    &__container{ width: 100%; padding: 20px 20px 0 20px; box-sizing: border-box; }
  }
}

@media screen and (max-width: 800px){
  .nj-subheader{
    &__container{ flex-direction: column; }
  }
}

@media screen and (max-width: 500px){
  .nj-subheader{
    &__actions{ display: flex; flex-wrap: wrap;
      a{ width: 100%;
        &:first-of-type{ margin-right: 0; margin-bottom: 20px; }
      }
    }
  }
}