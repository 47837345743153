#modal-box{
  form{
    button{
      &.btn-primary{ background-color: $main-color; }
    }
  }
  .content-modale{
    a{ border-radius: 0; height: 45px; line-height: 45px; }
    a, input[type=submit], button[type=submit]{ font-size: 14px; text-transform: none; @extend %title-font-bold; }
    select, input{ height: 35px; line-height: 35px; }
    .btn-group{
      button[type=submit]{  }
    }
  }
}

.nj-overlay{ position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(15,30,41,0.5); z-index: 2000; display: none; align-items: center; justify-content: center;
  &.active{ display: flex;  }
}

.nj-modal-kromi{ position: fixed; width: 800px; height: auto; max-height: calc(100vh - 80px); overflow: auto; align-items: stretch; display: none; background-color: #fff; z-index: 1003; flex-direction: column;
  &__close{  }
  &__header{ background-color: $main-color; color: #fff; @extend %title-font-bold; font-size: 18px; height: 60px; display: flex; align-items: center; justify-content: space-between; padding: 0 20px; }
  &__content{ padding: 20px; @extend %title-font-reg; font-size: 16px; height: auto; overflow: auto;
    .input_area{ box-sizing: border-box; }
    .input_label{ @extend %title-font-bold; font-size: 14px; margin-bottom: 5px; display: block; }
    .input_line{ box-sizing: border-box; margin-bottom: 20px; }
    input[type=text]{ width: 100%; height: 45px; line-height: 45px; padding: 0 20px; @extend %title-font-reg; font-size: 14px; box-sizing: border-box; }
  }
  &.active{ display: flex; }
  #submissions_change_status{
    input[type=text], .note-editable{ font-size: 13px; }
    #input_line_send_mail{ display: flex; align-items: center; justify-content: flex-end; flex-direction: row-reverse;
      input[type=checkbox]{ width: 15px; height: 15px; z-index: 0; position: relative; opacity: 1; margin-right: 5px; }
      .nj-checkbox{ padding-left: 0; }
    }
  }
  #projects_message_form{
    input, textarea, .note-editable, p{ font-size: 12px; }
    p.keyphrase{ font-size: 14px; }
    .input_label{ font-size: 14px; }
    h3{ font-size: 16px; margin: 30px 0 10px; color: $main-color; @extend %title-font-bold; }
    .checkbox-group{ display: flex;
      input{ margin-top: 0; margin-right: 10px; }
      label{ font-size: 12px;  }
    }
    .modale__actions{ margin-top: 30px; display: flex; justify-content: flex-end; }
  }
}

#modal-box { background-color: white; position: fixed; top: 40%; max-width: 600px; display: none; left: 50%; transform: translateX(-50%); z-index: 130;
  &.open { display: block; }
  h2 { margin-left: 20px; margin-right: 20px; }
  p { margin-bottom: 10px; }
  .form_change_password, .form_change_email, .stat-cfp{ padding: 20px;
    .input_label{ margin-bottom: 5px; @extend %title-font-reg; display: block; font-size: 14px; }
    .input_line{ margin-bottom: 20px; }
    .input_field{ padding: 0 10px; }
    .obligatoire{ font-size: 12px; }
    .btn-group{ display: flex; justify-content: flex-end; }
    .btn-primary{ border: none; height: 45px; margin-right: 0; display: flex; align-items: center; justify-content: center; padding: 0 20px; @extend %title-font-bold; color: #fff; background-color: $main-color; }
  }
  .header-modale{ background-color: $main-color; padding: 20px; color: #fff; @extend %title-font-bold; }
  .close_modale{ display: block; position: absolute; top: auto; margin-top: 20px; right: 20px; width: 20px; height: 20px; background: url('../images/croix-blanche.svg') no-repeat center center; }
  &.modal--choose-opportunity{
    header{ background-color: $main-color; padding: 10px 0;
      h2 p{ color: white; font-size: 22px; margin-bottom: 0; }
    }
    p{ font-size: 14px; }
    div.pr20.pl20.pb20.pt20{ padding: 20px; }
    select{ margin: 20px 0; }
    .legend_obligatoire{ display: none; }
    .kromiform_submit{ color: white; }
    select{ width: 100%; }
  }
  &.stat-opportunity{
    .close_modale{ margin-top: 0; }
  }
  .content-modale { min-width: 400px;
    button[type=submit], input[type=submit] { }
    #invite_mail { padding: 20px;
      .legend_obligatoire { display: none; }
      button[type=submit], input[type=submit] { float: right; padding: 5px 10px; border: none; }
    }
    div.token-input-dropdown { width: 450px !important; }
    .form_change_email, .form_change_password {
      input[type=text], input[type=password] { box-sizing: border-box; }
      .input_line { box-sizing: border-box; width: 100%; }
      .input_area { box-sizing: border-box; }
    }
  }
}
#modal-shadow { display: none; position: fixed; top: 0; bottom: 0; left: 0; right: 0; background-color: black; opacity: 0.5; z-index: 120;
  &.show { display: block; }
}

@media screen and (max-width: 800px) {
  .nj-modal-kromi { width: 100vw; height: 100vh; overflow: auto; }
}