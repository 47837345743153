.nj-statistics{
  &__title{ color: $main-color; margin: 30px 0 20px; }
  &__row{ display: flex; flex-wrap: wrap; justify-content: space-between; }
  &__block-header{ display: flex; justify-content: space-between; align-items: center; margin-bottom: 30px; }
  &__block-important-number{ color: $main-color; font-size: 20px; }
  &__sub-block{ margin-bottom: 20px; background-color: $gris-tres-clair; flex: 1; padding: 20px; position: relative;
    &--important{  background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); color: #fff;
      .nj-statistics__block-important-number{ color: #fff; @extend %title-font-black; }
      ul{
        li{
          strong{ @extend %title-font-black; }
        }
      }
      .nj-statistics__export-btn{
        img{ filter: invert(1); }
      }
    }
    &--exportable{ padding: 20px 20px 60px 20px; }
    &:last-of-type{ margin-bottom: 0; }
  }
  &__block{ margin-bottom: 20px; background-color: $gris-tres-clair; padding:  20px; box-sizing: border-box; position: relative;
    h3{ font-size: 20px; @extend %title-font-bold;
      span{ font-size: 12px; margin-left: 10px; }
    }
    hr{ margin: 20px 0; border: none; background-color: #fff; height: 1px; }
    ul{
      li{ font-size: 12px; @extend %title-font-reg; display: flex; justify-content: space-between; }
    }
    &--small{
      .nj-statistics__block-header{ margin-bottom: 0; }
    }
    &--valign-center{ display: flex; align-items: center; justify-content: center; }
    &--full{ width: 100%; }
    &--two-thirds{ width: calc(((100% - 20px) / 3) * 2); }
    &--mid{ width: calc((100% - 20px) / 2); }
    &--third{ width: calc((100% - 40px) / 3); }
    &--fourth{ width: calc((100% - 60px) / 4); }
    &--deployable{ max-height: 70px; overflow: hidden;
      &.active{ max-height: 1500px; overflow: auto; }
      .nj-statistics__block-header{ margin-top: 5px; }
    }
    &--exportable{ padding-bottom: 60px; }
    &--important{ background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); color: #fff;
      .nj-statistics__block-important-number{ color: #fff; @extend %title-font-black; }
      ul{
        li{
          strong{ @extend %title-font-black; }
        }
      }
      .nj-statistics__export-btn{
        img{ filter: invert(1); }
      }
    }
    &--double{ background-color: transparent; padding: 0; display: flex; flex-direction: column; }
  }
  &__lists-container{ display: flex; flex-wrap: wrap; justify-content: flex-start; padding: 0 30px; align-items: flex-start;
    &--mid{
      ul{ width: calc((100% - 40px)/2); margin-right: 40px; position: relative;
        &:nth-of-type(2n){ margin-right: 0; }
        li{
          &.letter{ @extend %title-font-black; position: absolute; top: auto; left: -15px; margin-top: -16px; color: $main-color;
            &:first-of-type{ margin-top: 0; }
          }
          &:hover{ background-color: $gris-clair; }
          strong{  }
        }
      }
    }
    &--third{
      ul{ width: calc((100% - 80px)/3); margin-right: 40px; position: relative;
        &:nth-of-type(3n){ margin-right: 0; }
        li{
          &.letter{ @extend %title-font-black; position: absolute; top: auto; left: -15px; margin-top: -16px; color: $main-color;
            &:first-of-type{ margin-top: 0; }
          }
          &:hover{ background-color: $gris-clair; }
          strong{  }
        }
      }
    }
  }
  .highcharts-tooltip + text{ display: none; }
  &__export-btn{ position: absolute; bottom: 20px; right: 20px;
    &--deploy{ right: 60px; top: 24px; bottom: auto; }
  }
}
