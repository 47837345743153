.rate {
  display: flex;
  flex-direction: row-reverse;
}

.rated{ display: flex; flex-direction: row;
  label{
    i{ color: var(--nj-color-base-yellow); }
  }
  label.active {
    i { color: var(--nj-color-base-yellow); }
    ~ label{
      i{ color: #757575; }
    }
  }
  &--0{
    label{
      i{ color: #757575; }
    }
  }
}
.rate:not(:checked) > input {
  position:absolute;
  top:-9999px;
  visibility: hidden;
}
.rate:not(:checked) > label {
  width:1em;
  overflow:hidden;
  white-space:nowrap;
  cursor:pointer;
  font-size:30px;
  color:#ccc;
}
.rate:not(:checked) > label:before {
}
.rate > input:checked ~ label i{
  color: var(--nj-color-base-yellow)!important;
}
.rate:not(:checked) > label:hover i,
.rate:not(:checked) > label:hover ~ label i {
  color: var(--nj-color-base-yellow)!important;
}
.rate > input:checked + label:hover i,
.rate > input:checked + label:hover ~ label i,
.rate > input:checked ~ label:hover i,
.rate > input:checked ~ label:hover ~ label i,
.rate > label:hover ~ input:checked ~ label i{
  color: var(--nj-color-base-yellow)!important;
}

.stars-evaluation-mark{
  &:hover{ cursor: pointer; }
  label, i{
    &:hover{ cursor: pointer; }
  }
}