// TROPHIES
.nj-trophies{
  &__container{ position: relative; width: 1120px; margin: 0 auto; height: auto; display: flex; flex-direction: column; }
  &__grid{ display: flex; justify-content: space-between; flex-wrap: wrap; }
  &__title{ margin-bottom: 40px; color: $main-color;
    strong{  }
  }
  &__filters{ display: flex; padding: 40px 0 10px;
    .nj-header__filter{ border: 1px solid $gris; height: 45px; line-height: 45px; padding: 0 20px; border-radius: 0; box-sizing: border-box; background: transparent; }
    input[type=text]{ margin-left: 20px; margin-right: 20px; flex: 1; color: $main-color; @extend %title-font-reg; font-size: 14px; border-radius: 0; -webkit-appearance: none; }
    select{ color: $main-color; @extend %title-font-reg; font-size: 14px; border-radius: 0; -webkit-appearance: none;
      &:first-of-type{ margin-right: 20px; }
    }
    .nj__btn{ width: 150px; align-items: center; justify-content: center; box-shadow: 4px 4px 20px #0003; border-radius: 0; -webkit-appearance: none;
      &:hover{ cursor: pointer; }
    }
  }
  &__reset-filters{ display: block; text-decoration: underline; @extend %title-font-reg; margin-bottom: 40px; font-size: 14px; color: $main-color; }
}


@media screen and (max-width: 1250px){
  .nj-trophies{
    &__container{ width: 90%; }
  }
}

@media screen and (max-width: 980px){
  .nj-trophies{
    &__container{ width: 100%; padding: 20px; box-sizing: border-box; }
    &__grid{ justify-content: center; }
  }
}

@media screen and (max-width: 880px){
  .nj-trophies{
    &__filters{ width: 100%; padding: 0; box-sizing: border-box; flex-wrap: wrap;
      select{ width: calc((100% - 20px) / 2); margin-bottom: 20px; }
      input[type=text]{ margin-left: 0; }
    }
  }
}

@media screen and (max-width: 470px){
  .nj-trophies{
    &__title{ font-size: 24px; line-height: 24px; }
  }
}

@media screen and (max-width: 450px){
  .nj-trophies{
    &__filters{
      select{ width: calc((100% - 20px) / 2); margin-bottom: 20px; }
      input[type=text]{ margin-right: 0; width: 100%; flex: none; margin-bottom: 20px; }
      input[type=submit]{ flex: 1; }
    }
  }
}

@media screen and (max-width: 361px){
  .nj-trophies{
    &__link{ font-size: 11px; }
  }
}