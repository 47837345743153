.nj-intro{ display: flex; position: relative; width: 1120px; margin: 30px auto 0; height: auto; align-items: flex-start;
  &__title{ font-size: 36px; width: 34%; @extend %title-font; line-height: 1; color: $secondary-color;
    strong{ font-size: 36px; }
  }
  &__text{ width: 66%; font-size: 18px; padding-left: 20px; box-sizing: border-box; @extend %title-font-reg; display: flex; flex-direction: column; align-items: flex-start;}
  &__btn{ background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); color: white; padding: 0 20px; height: 45px; margin-top: 20px; font-size: 16px; line-height: 45px; display: flex; align-items: center; justify-content: space-between;  @extend %title-font-bold; align-self: flex-end;;
    img{ height: 12px; margin-left: 20px; }
  }
}


@media screen and (max-width: 1250px){
  .nj-intro{ width: 90%; }
}

@media screen and (max-width: 1150px){
  .nj-intro{ flex-direction: column; margin: 30px auto;
    &__title{ width: 100%; margin-bottom: 20px; }
    &__text{ width: 100%; padding: 0; }
  }
}

@media screen and (max-width: 980px){
  .nj-intro{ width: 100%; padding: 20px 20px 0 20px; }
}

@media screen and (max-width: 600px){
  .nj-intro{
    &__btn{ width: 100%; box-sizing: border-box; }
  }
}