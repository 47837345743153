#alertmessage{ position: fixed; z-index: 3000; width: 100%; bottom: 0; left: 0;
  .nj-alert{ margin-bottom: 0; padding-top: 10px; padding-bottom: 10px;
    ul{ list-style: none; margin: 0; }
  }
}

.nj__alert-messages{ position: fixed; z-index: 3000; width: 100%; bottom: 0; left: 0;
  .nj-alert{ margin-bottom: 0; padding-top: 10px; padding-bottom: 10px;
    ul{ list-style: none; margin: 0; }
  }
}


.nj__cookies-politic{ background-color: $main-color; padding: 20px 0; position: fixed; bottom: 0; left: 0; z-index: 101; width: 100%;
  .nj__container{ display: flex; justify-content: space-between; align-items: flex-start; color: #ffffff; @extend %title-font; font-size: 14px;
    p{ max-width: 85%;
      a{ text-decoration: underline; }
    }
  }
}


