.nj-your-innovations{
  &__container{ position: relative; width: 1120px; margin: 0 auto; height: auto; }
  &__title{  margin-top: 0; text-align: left; font-size: 30px; @extend %title-font; line-height: 1.3; max-width: 100%; color: $main-color; margin: 80px 0 10px;
    strong{ @extend %title-font-bold; }
  }
  &__text{ @extend %title-font-reg; font-size: 16px; }
  &__btn-container{ display: flex; align-items: center; justify-content: center; margin-top: 40px; }
  &__btn{ box-shadow: 4px 4px 20px #0003;  }
}

@media screen and (max-width: 1250px){
  .nj-your-innovations{
    &__container{ width: 100%; padding: 20px 20px 0 20px; box-sizing: border-box; }
  }
}