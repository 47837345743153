.nj-card{
  &__icon-text{ font-size: 12px; position: absolute; bottom: 20px; right: 50px; display: none; }
  &:hover{
    .nj-card__icon-text{ display: block; }
  }
  &__overlay{ width: 100%; }
}


.nj-bloc{ width: calc((100% - 60px) / 3); margin-bottom: 30px; position: relative; overflow: hidden; display: flex; height: 435px; box-sizing: border-box; border: 1px solid transparent; border-bottom: 4px solid $main-color; transition: border 300ms ease-in-out;
  &__image{ width: 100%; height: 100%; object-fit: cover; }
  &__status{ position: absolute; top: 0; right: 0; height: 45px; line-height: 45px; background: $secondary-color; padding: 0 20px; color: #ffffff; @extend %title-font-bold; font-size: 14px; z-index: 2; display: flex; align-items: center; justify-content: center;
    &--laureat{ background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); }
    img{ margin-right: 10px; }
    &--status-2020{ background: #23D2B5; border: 5px solid #fff; height: 36px; line-height: 36px;
      &.nj-bloc__status--laureat{ background: #00BCFD; }
    }
  }
  &__image-container{ width: 100%; height: 285px; }
  &__content{ padding: 20px 20px 40px 20px; background: #ffffff; display: flex; flex-direction: column; justify-content: flex-start; position: absolute; top : 285px; overflow: auto; transition: top 300ms ease-in-out; height: 100%; z-index: 1; box-sizing: border-box; }
  &__header{ display: flex; }
  &__title{ @extend %title-font-bold; font-size: 16px; margin-top: 15px;
    &--trophies{ height: 45px; }
  }
  &__description{ @extend %title-font-reg; font-size: 16px; line-height: 1.5; color: transparent; opacity: 0; margin-top: 15px; }
  &__theme{ @extend %title-font-reg; text-transform: uppercase; font-size: 14px; color: $secondary-color; }
  &__date{ @extend %title-font-reg; font-size: 14px; color: $gris; }
  &__dates{ position: absolute; top: 0; left: 0; height: 45px; line-height: 45px; text-transform: uppercase; background: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); @extend %title-font-bold; font-size: 14px; color: #ffffff; display: block; padding: 0 20px; }
  &__country{ @extend %title-font; font-size: 12px; color: $gris; text-transform: uppercase;  }
  &__country-event{ @extend %title-font-reg; font-size: 16px; text-transform: uppercase; display: flex; align-items: center; margin-top: 10px;
    img{ margin-right: 10px; }
  }
  &__sponsor{ @extend %title-font-bold; font-size: 15px; color: $gris; text-transform: uppercase; margin-top: 8px; }
  &__separator{ margin: 0 10px; font-size: 10px; color: $gris; }
  &__btn{ background: $main-color; position: absolute; bottom: 0; right: 0; height: 40px; padding: 0 20px; line-height: 40px; color: #ffffff; @extend %title-font-bold; font-size: 14px;
    &--vote{ box-sizing: border-box; background-color: transparent; display: flex; align-items: center; position: relative; bottom: auto; height: auto;
      svg{ height: 20px; width: auto;
        .path1{ stroke: $gris; fill: transparent !important; }
        .path2{ fill: $gris; }

      }
      &:hover, &.active{
        svg .path1{ fill: $orange !important; }
        svg .path2{ fill: #fff; }

      }
      &.active{
        &:hover{
          svg .path1{ fill: transparent !important; }
          svg .path2{ fill: $main-color; }
        }
      }
    }
  }
  &__btn-container{ position: absolute; bottom: 0; right: 0; height: 40px; display: flex;
    .nj-bloc__btn{ position: relative; }
  }
  &__votes-container{ justify-content: space-between; display: flex; flex-direction: column; align-items: center;
    .js-vote-heart{ height: 15px; width: auto; display: none;
      &.active{ display: block;
        .path1{ fill: $orange !important; }
        .path2{ fill: $gris-clair; }
      }
    }
  }
  &__votes{ }
  &__votes-number{ margin-right: 4px;  }
  &__flex-container{ display: flex; justify-content: space-between; align-items: flex-end; }
  &:hover, &.active{
    .nj-bloc__content{ top: 0;  }
    .nj-bloc__description{ color: #000000; opacity: 1; }
  }
  &:not(.nj-bloc--events){
    &:hover{ border: 1px solid $gris-clair; border-bottom: 4px solid $main-color; }
  }
  &--trophies{
    .nj-bloc__image{ object-position: right top; }
    &:hover{
      .nj-bloc__content{ padding: 60px 20px 20px 20px; }
    }
  }
  &--events{  flex-direction: column; border: 4px solid; border-image-source: linear-gradient(to bottom right, $bleu-gradient, $vert-gradient); border-image-slice: 1; transition: border-left 300ms ease-in-out; transition: border-right 300ms ease-in-out; transition: border-top 300ms ease-in-out; border-left: 1px solid transparent; border-right: 1px solid transparent; border-top: 1px solid transparent;
    &:hover{ border-left: 1px solid; border-right: 1px solid; border-top: 1px solid; }
    .nj-bloc__content{ padding: 50px 20px 20px 20px; top: 245px; }
  }
}

@media screen and (max-width: 1080px){
  .nj-bloc{ width: calc((100% - 30px) / 2);
    &:nth-of-type(2n){ margin-left: 30px; }
  }
}

@media screen and (max-width: 750px){
  .nj-bloc{ width: 100%;
    &:nth-of-type(2n){ margin-left: 0; }
  }
}

@media screen and (max-width: 500px){
  .nj-bloc{
    &__description{ font-size: 14px; }
  }
}
