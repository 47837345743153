
.kr-slider{
  &--home{ height: calc(100vh - 160px - 100px);
    .swiper-wrapper{ background: url('../images/home-background.webp')no-repeat top center; background-size: cover;
      .kr-slide{ background: none; }
    }
    &.collab {
      .swiper-wrapper { background: url('../images/background-futur-energy-fr.jpg') no-repeat top center; background-size: cover;
        .kr-slide {
          h1 { margin-top: 80px; }
        }
      }
    }
    .kr-slide{ height: 100%; background: rgba(15,30,41,0.5);
      &__logo{ width: 15%; position: absolute; left: 0; top: 50%; transform: translateY(-50%); }
      &__content{ height: 100%; z-index: 0;
        h1{ max-width: 800px; line-height: 1; font-size: 3rem; }
      }
      &__image{ object-fit: cover; object-position: center center; height: 100%; width: 100%; position: absolute; top: 0; left: 0; z-index: -1; }
      &__text{ max-width: 45%; }
    }
  }
  &--calls-for-projects{ padding-bottom: 60px;
    .kr-slide{ padding: 0 30px 0 30px; }
    .kr-slider__nav{ position: absolute; bottom: 0; display: flex; left: 50%; z-index: 2; margin-left: - 60px; }
    .kr-slider__additional-link{ position: absolute !important; right: 30px; bottom: 0;  }
  }
  &--events{ padding-bottom: 60px;
    .kr-slide{ height: 450px; position: relative; display: flex;
      &__image{ object-fit: cover; height: 100%; width: 50%; }
      &__title{ margin-top: 20px; }
      &__text{ margin-top: 5px; margin-bottom: auto; }
      &__date{ display: inline-block; padding: 2px 6px; border-radius: 2px; font-size: 16px; margin-top: 15px; text-transform: uppercase; }
      &__content{ background: var(--nj-color-base-blue-corporate); display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start; height: 100%; width: 50%; z-index: 1; position: relative; padding: 40px 30px; box-sizing: border-box; }
      h3{  }
    }
    .kr-slider__nav{ position: absolute; bottom: 80px; display: flex; right: 20px; z-index: 2;
      .kr-slider__swipe{ background: white; }
    }
    .kr-slider__additional-link{ position: absolute !important; right: 0; bottom: 0; }
  }
  &__swipe{ position: relative; border-collapse: collapse; border: 1px solid black; width: 60px; height: 60px; left: 0; right: auto; background: none; display: flex; align-items: center; justify-content: center;
    &:first-of-type{  }
    &:last-of-type{ left: -1px }
  }
}

@media screen and (max-height: 850px){
  .kr-slider{
    &--home {
      .kr-slide{
        &__content{
          h1.t-peta{ font-size: 3rem; max-width: 800px; }
          h2.t-giga{ font-size: 1.75rem; }
        }
        &__text{ font-size: 1rem; }
      }
    }
  }
}

@media screen and (max-width: 1300px){
  .kr-slider{
    &--home {
      .kr-slide{
        &__content{
          h1{ max-width: 800px; font-size: 3rem; }
        }
      }
    }
  }
}

@media screen and (max-width: 1100px){
  .kr-slider{
    &--home {
      .kr-slide{
        &__content{
          h1{ max-width: 800px; font-size: 3rem; }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px){
  .kr-slider{
    .kr-slide{
      &__logo{ top: auto; transform: none; bottom: 50px; }
    }
    &--home {
      .kr-slide{
        &__content{
          h1{ max-width: 750px; font-size: 3rem !important; }
        }
      }
    }
  }
}

@media screen and (max-width: 991px){
  .kr-slider{
    &--events {
      .kr-slide{ height: 420px;
        &__content{  }
        &__image{ }
        &__title{
          &.t-giga{ font-size: 1.75rem;  }
        }
        &__text{
          &.t-deci{ font-size: 0.75rem; }
        }
      }
    }
  }
}

@media screen and (max-width: 900px){
  .kr-slider{
    .kr-slide{
      &__logo{ width: 20%; }
    }
    &--home {
      .kr-slide{
        &__content{
          h1{ max-width: 700px; }
        }
      }
    }
  }
}


@media screen and (max-width: 1000px){
  .kr-slider{
    &--home {
      .kr-slide{
        &__text{ max-width: 70%; }
      }
    }
  }
}

@media screen and (max-width: 800px){
  .kr-slider{
    &--calls-for-projects{ padding-bottom: 100px;
      .kr-slider__nav{ margin-bottom: 60px; }
    }
  }
}


@media screen and (max-width: 768px){
  .kr-slider{

    &--events {
      .kr-slide{
        &__content{ padding: 15px; }
        &__image{ }
        &__title{
          &.t-giga{ font-size: 1.5rem;  }
        }
        &__text{
          &.t-deci{ font-size: 0.75rem; }
        }
      }
    }
  }
}

@media screen and (max-width: 700px){
  .kr-slider{
    &--home{ height: calc(100vh - 64px - 50px);
      .kr-slide{ height: 100%;
        &__content{
          h1.t-peta{ font-size: 3rem !important; }
          h2.t-giga{ font-size: 1.75rem; }
        }
        &__text{ max-width: 90%; }
        &__logo{ width: 25%; }

      }
      &.collab{
        .kr-slide__content{
          h2.t-giga{ font-size: 1.2rem; }
        }
      }
    }
  }
}

@media screen and (max-width: 500px){
  .kr-slider{
    &--home{
      .swiper-wrapper{ background: url('../images/home-background-mobile.webp')no-repeat center center; background-size: cover; }
      .kr-slide{
        &__text{ font-size: 1rem;}
        &__content{
          h1.t-peta{ font-size: 2.1rem !important; max-width: 90%; line-height: 1.2; }
        }
        &__text{ max-width: 90%; font-size: 1rem; }

      }
      &.collab{
        .swiper-wrapper{ background: url('../images/background-futur-energy-fr.jpg')no-repeat center center; background-size: cover; }
      }
    }

    &--calls-for-projects{ padding-bottom: 0;
      .kr-slider__additional-link{ z-index: 2; }
      .kr-slide{ padding: 0; }
      .kr-slider__nav{ left: 0; margin-left: 0; top: 575px; bottom: auto; width: 100%; justify-content: space-between; z-index: 4; }
    }
    &--events{
      .kr-slide{ flex-wrap: wrap; height: 600px;
        &__content{ width: 100%; height: 300px; }
        &__image{ width: 100%; height: 300px; }
      }
    }
  }
}

