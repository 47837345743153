.nj__form{
  .nj__field{ height: 45px; line-height: 45px; border: 1px solid $gris-clair; padding: 0 20px; border-radius: 0; box-sizing: border-box; }
  .nj__select{ height: 45px; line-height: 45px; border: 1px solid $gris-clair; padding: 0 20px; border-radius: 0; box-sizing: border-box; }
  &--admin{ display: flex; flex-direction: row; flex-wrap: wrap;
    .nj__field{ width: 100%; }
    button[type=submit]{ background-color: $nj-bleu; color: #ffffff; border-radius: 0; outline: none; height: 45px; font-size: 16px; padding: 0 20px; box-shadow: 4px 4px 20px #0003; line-height: 45px; @extend %title-font-med; display: flex; border: none; -webkit-appearance: none; display: inline-flex; }
    .input_line{ margin-bottom: 20px; padding: 0 20px; box-sizing: border-box; width: 100%; height: auto;
      &.full{ width: 100%; }
      &.mid{ width: 50%; }
      &.third{ width: 33%; }
      &.fourth{ width: 25%; }
      &.ligne_separation{ width: 100%; height: auto; }
    }
    .input_label{  @extend %title-font-bold; font-size: 14px; margin-bottom: 5px; display: block; }
    .input_legend{  @extend %title-font; font-size: 14px; margin-bottom: 5px; display: block; }
    &.nj__form--emailings{
      h3{ @extend %title-font-bold; font-size: 18px; color: $secondary-color; margin-bottom: 20px; }
      h4{ @extend %title-font-reg; font-size: 16px; color: $main-color; margin-bottom: 20px; }
    }
  }
  &--contact{
    .input_line.input_line_submits{ display: flex; justify-content: flex-end; padding-right: 20px;
      button[type=submit]{ margin: 0; }
    }
  }
  &--subscription.inscription{ display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 0;
    h3{ width: 100%; display: block; margin-top: 0; }
    .easy-autocomplete{ width: 100% !important; }
    button[type=submit]{ background-color: $nj-bleu; color: #ffffff; border-radius: 0; outline: none; height: 45px; font-size: 16px; padding: 0 20px; box-shadow: 4px 4px 20px #0003; line-height: 45px; @extend %title-font-med; display: flex; border: none; -webkit-appearance: none; display: inline-flex; }
    .input_line{ box-sizing: border-box; width: 100%; height: auto; margin: 0 40px;
      &.full{ width: 100%; }
      &.mid{ width: 50%; }
      &.third{ width: 33%; }
      &.fourth{ width: 25%; }
      &.ligne_separation{ width: 100%; height: auto; }
      .easy-autocomplete input{ border-top: none; box-shadow: none; border: none; }
      .radioList{ display: flex;
        li{ width: auto; display: flex; margin-right: 30px; padding-left: 0;
          .input_field{ width: 20px; height: 20px; margin: 0; margin-right: 10px; }
          label{ @extend %title-font-reg; font-size: 14px; }
        }
      }
      &.input_line_submits{ margin: 0; background-color: var(--nj-color-base-gray-100); padding: 40px 30px 20px 30px; display: flex; justify-content: center;
        .legend_obligatoire{ display: none; }
        button{ margin: 0; }
      }
      &.ligne_separation{ margin: 0;
        .nj-alert{ margin: 0 30px; }
      }
    }
    .input_label{ @extend %title-font-reg; font-size: 14px; display: block; }
    .input_legend{  @extend %title-font; font-size: 12px; margin-top: 5px; display: block; }
    input[type="file"]{ height:50px;  }
    input[type="file"]::-webkit-file-upload-button{ height:50px; width: 150px; }
    .btn-group{
      button[type=submit]{ border-radius: 0; text-transform: none; height: 45px; line-height: 45px; @extend %title-font-bold; font-size: 14px; padding: 0 20px; }
    }
  }
  &--subscription.pass-forgotten{ display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 20px;
    .input_field, select{ width: 100%; border-radius: 0; border: none; border-bottom: 1px solid $gris2; height: 45px; box-sizing: border-box; line-height: 45px; padding: 0 20px 0 0; }
    .input_line{ margin-bottom: 20px; padding: 0; box-sizing: border-box; width: 100%; height: auto;}
    .input_label{ @extend %title-font-reg; font-size: 14px; margin-bottom: 5px; display: block; }
    .btn-group{ display: flex; justify-content: flex-end; width: 100%;
      button[type=submit]{ border-radius: 0; text-transform: none; height: 45px; line-height: 45px; @extend %title-font-reg; font-size: 14px; padding: 0 20px; }
    }
  }
  &--submissions{
    .ligne_separation h3{ font-size: 1rem; }
    textarea.nj-form-control{ min-height: 200px; }
  }
}

.token-input-token{
  p{ margin-bottom: 0; }
}

.analytics-filters{
  .chosen-container{ width: 100% !important; height: auto; background: none; min-height: 40px;
    .chosen-choices{ height: auto !important; min-height: 40px; overflow: auto; background: none; border: none; padding: 10px;
    }
  }
  .chosen-wrapper{ padding-right: 0; width: 100% !important; }
  .field-fullwidth{ width: 100% !important; }
  .actions{ justify-content: flex-end; align-items: center; }
  select[name=lang]{ min-width: 100px; }
  span.t-deci{ min-width: 250px; text-align: right; }
}

form{
  textarea{ @extend %title-font-reg; padding: 10px; box-sizing: border-box; }
  .easy-autocomplete input{ border-top: none; box-shadow: none; border: none; }
}

#input_line_pr_confidential{
  ul{
    li{ display: flex; justify-content: flex-start; align-items: center; margin-bottom: 20px;
      input, label{  width: auto; }
      input[type=radio]{ margin-right: 10px; }
      label{ font-size: 13px; }
    }
  }
}

.gtrad_field{ font-size: 14px; text-decoration: underline; color: $main-color; margin: 0 0 20px; display: block; }

.go-to-blue-projects{ display: inline-flex; background-color: $color-procurement; color: #fff; align-items: center;
  img{ width: auto; height: 30px; margin-right: 10px; }
  span{ font-size: 12px; }
}

#admin-buttons-area{ display: flex; margin-top: 1px; flex-wrap: wrap;
  a{ margin-right: 1px; }
}

#input_line_su_tags, #input_line_pr_question_tags, #input_line_op_tags, #input_line_ar_tags, #input_line_pa_tags, #input_line_ev_tags, #input_line_us_tags, #input_line_ea_tags{
  .input_area{ border-radius: 0; border: none; padding: 0; }
  ul.token-input-list{ float: none; border-radius: 0; border: 1px solid $gris-clair; display: flex; padding: 10px; align-items: center; justify-content: flex-start; flex-wrap: wrap; height: auto; line-height: 1; height: auto !important;
    .token-input-token{ height: 30px; line-height: 1; margin: 10px !important; @extend %title-font-reg; padding: 0 10px !important; border: 1px solid $main-color; color: $main-color; float: none; display: flex; align-items: center; justify-content: center; background-color: #fff;
      .token-input-delete-token{ color: $main-color; margin-left: 10px; }
      p{ font-size: 12px; }
    }
    .token-input-input-token{ height: 30px; line-height: 1; border: 1px solid $main-color; margin: 10px; float: none; display: flex; align-items: center; justify-content: center;
      input[type=text]{  height: 30px !important; line-height: 1 !important; color: $main-color; @extend %title-font-reg; padding: 0 20px; }
    }
  }
}

.tags__container{ padding-top: 10px; margin-top: 20px;
  .tags__label{ display: block;
    h5{ color: black; text-transform: none; font-size: 14px; margin-top: 10px; margin-bottom: 5px; }
    span{ font-size: 14px; margin-left: 5px; }
  }
  .tags__tag{ padding: 4px 10px; font-size: 13px; margin-right: 10px; margin-bottom: 9px; color: $main-color; border: 1px solid $main-color; line-height: 1; display: inline-block;
    &.active{ display: inline-block;  }
    &.selected{ background-color: $main-color; color: white; }
  }
}

.token-input-dropdown{ background-color: #fff; font-size: 12px; padding: 20px; box-shadow: 4px 4px 20px #0003; }

.nj__admin{
  .kromiforms-form{
    input[type=text], input[type=password]{ height: 45px; line-height: 45px; border: 1px solid $gris-clair; padding: 0 20px; border-radius: 0; box-sizing: border-box; }
    textarea{ border: 1px solid $gris-clair; padding: 0 20px; border-radius: 0; box-sizing: border-box; }
    select{ height: 45px; line-height: 45px; border: 1px solid $gris-clair; padding: 0 20px; border-radius: 0; box-sizing: border-box; }
    .input_label{ @extend %title-font-bold; font-size: 14px; }
    .input_legend{ font-size: 12px; @extend %title-font-reg; }
    .input_line{ margin-bottom: 20px; }
    button[type=submit], input[type=submit]{ background-color: $nj-bleu; color: #ffffff; border-radius: 0; outline: none; height: 45px; font-size: 16px; padding: 0 20px; box-shadow: 4px 4px 20px #0003; line-height: 45px; @extend %title-font-med; border: none; -webkit-appearance: none; display: inline-flex; }
    .obligatoire{ font-size: 12px; color: $rouge-bis; }
    .ligne_separation{
      h3{ @extend %title-font-bold; margin-top: 20px; margin-bottom: 10px; color: $secondary-color; }
    }
  }
  .kromiforms{
    .page-header{
      h1{ color: $main-color; @extend %title-font-bold; }
    }
  }
}

#innovations_newsletters{
  #input_line_ids_articles_select, #input_line_ids_events_select, #input_line_ids_submissions_select{ margin-bottom: 0;
    + .ligne_separation{ margin-bottom: 0;
      h3{ margin-top: 0; }
      + .ligne_separation{
        h3{ margin-top: 0; }
      }
    }
  }
}



@media screen and (max-width: 1150px) {
  #admin-buttons-area{ flex-direction: column;
    a{ margin-bottom: 1px; }
    .go-to-blue-projects{ line-height: 1; }
  }
}

@media screen and (max-width: 980px){
  .nj__form{
    &--subscription.inscription{ padding-left: 0; padding-right: 0; margin-top: 0; padding-top: 0;
      .input_line{  }
    }
  }
}

@media screen and (max-width: 750px){
  .nj__form{
    &--subscription.inscription{ padding-left: 0; padding-right: 0; margin-top: 0; padding-top: 0;
      .input_line{ padding: 0;
        &.mid, &.third, &.full, &.fourth{ width: 100%; }
      }
    }
    &--submissions{
      .input_line{ flex-direction: column;
        .input_label{ width: 100%; }
        .input_area{ width: 100%; padding-left: 80px; box-sizing: border-box;
          textarea{ height: auto; max-height: 400px; resize: vertical; }
        }
      }
    }
  }
}

@media screen and (max-width: 500px){
  .nj__form{
    &--submissions{ margin-top: 30px;
      .input_line{ padding: 0 20px 20px 0;
        .input_label{ padding-right: 0; padding-bottom: 0; align-items: center;
          span{ height: 40px; width: 40px; line-height: 40px; font-size: 16px; align-self: flex-start; }
          p{ font-size: 16px; margin: 10px 20px 0 0; box-sizing: border-box; }
        }
        .input_area{ padding-left: 20px; padding-right: 0; margin-top: 10px;
          textarea{ box-sizing: border-box; }
        }
        &.ligne_separation{
          h3{
            .file_line{ padding: 0; flex-direction: column;
              span{ height: 40px; width: 40px; line-height: 40px; font-size: 16px;  }
              p { font-size: 16px; margin: 10px 20px 0; box-sizing: border-box;  }
            }
          }
        }
        &.file{
          .input_area{ padding-left: 20px; margin-top: 10px; }
        }
      }
    }
  }
  .nj__submission{
    .page-header{ margin: 0; margin-top: 10px; }
  }
}
