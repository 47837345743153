.nj-section{ position: relative;
  &--grey-background{ z-index: 1;
    &:after{ content:''; display: block; background-color: var(--nj-color-base-gray-100); height: 390px; width: 90%; margin: 0 5%; position: absolute; bottom: 0; z-index: -1; }
  }
}

@media screen and (max-width: 1400px) {
  .nj-section{
    &--grey-background{
      &:after{ width: 100%; margin: 0; }
    }
  }
}