 .nj__btn{ height: 60px; font-size: 16px; padding: 0 20px; line-height: 60px; @extend %title-font-bold; border: none; -webkit-appearance: none; display: inline-flex;
   &--primary{ background-color: $main-color; color: #ffffff; }
   &--secondary{ color: $main-color; background: #ffffff 0% 0% no-repeat padding-box; box-shadow: 4px 4px 20px #0003; }
   &--okta{ color: $bleu-okta; }
   &--download{ height: 60px; line-height: 60px; display: inline-block; font-size: 18px; @extend %title-font-bold; background-color: $secondary-color; color: #ffffff; display: flex; align-items: center; justify-content: space-between;
      img{ margin-left: 50px; }
   }
   &--download-pdf{ height: 60px; line-height: 60px; font-size: 18px; @extend %title-font-bold; background-color: $secondary-color; color: #ffffff; display: inline-flex; align-items: center; justify-content: space-between;
      img{ margin-left: 50px; }
   }
   &--third{ background: $secondary-color; color: #ffffff; }
   &--small{ height: 45px; line-height: 45px; font-size: 14px; @extend %title-font-bold; }
   &--supersmall{ height: 30px; line-height: 30px; font-size: 11px; @extend %title-font-bold; }
   &--answer-cfp{ background: $secondary-color; color: #ffffff !important; justify-content: space-between;
      img{ margin-left: 50px; width: 20px; }
   }
   &--subscribe-event{ background: $secondary-color; color: #ffffff; white-space: nowrap;
      img{ margin-left: 50px; width: 20px; }
   }
   &--search{ height: 31px; line-height: 30px; background-color: $main-color; color: #ffffff; font-size: 12px; }
   &--delete{ color: $main-color; background: #ffffff 0% 0% no-repeat padding-box; box-shadow: 4px 4px 20px #0003; height: 45px; line-height: 45px; font-size: 14px; @extend %title-font-bold; color: $rouge-bis; }
   &--edit-admin{ position: fixed; top: auto; right: 0; background-color: $secondary-color; z-index: 10;
      img{ width: 15px; }
   }
   &--export-admin{ position: fixed; top: auto; margin-top: 45px; right: 0; background-color: $gris-clair; z-index: 10; width: 55px; display: flex; padding: 0; align-items: center; justify-content: center;
      img{ width: 30px; }
   }
   &--backlink{ position: fixed; top: auto; left: 0; background-color: $gris-fonce; z-index: 10; display: flex; width: 45px; height: 45px; align-items: center; justify-content: center;
      img{ width: 15px; transform: rotate(90deg); }
   }
   &--submissions{ height: 35px; line-height: 35px; font-size: 14px; @extend %title-font-bold; }
   &--teams{ background-color: $gris-clair; color: $main-color; }
}

.nj__link{ text-decoration: underline; color: $main-color; }

.nj-btn{
  &--primary, &--outline-primary{
    &:hover{ color: white; }
  }
}

body.is_mobile{
   .nj__btn--edit-admin{ display: none; }
}

@media screen and (max-width: 500px){
   .nj__btn{
      &--download{ font-size: 16px; display: flex; align-items: center; justify-content: center; line-height: 1.4; height: auto; padding: 20px;
         img{ margin-left: 10px; }
      }
      &--subscribe-event{ margin: 20px 0;

      }
   }
}

