.nj-my-deposits{
  &__deposit{ position: relative; }
  &__actions{ position: absolute; right: 20px; top: 20px; z-index: 1; display: flex; flex-wrap: nowrap; }
  &__action{ border: 1px solid var(--nj-color-base-blue-corporate); background-color: white; width: 35px; height: 35px; display: flex; align-items: center; justify-content: center; position: relative;
    i{ font-size: 17px; }
    &:first-of-type{ margin-right: 20px; }
    .nj-badge{ position: absolute; bottom: -10px; right: -20px;
      &--outline{ border: 1px solid var(--nj-color-base-blue-corporate); color: var(--nj-color-base-blue-corporate); background-color: white; }
    }
    &--detail{ width: auto; padding: 0 10px; }
  }
}


@media screen and (max-width: 768px){
  .header-text-button{
    &.my-6{ margin: 20px 0 !important;  }
    p.t-hecto{ max-width: 50%; font-size: 1.2rem; }
  }
}


@media screen and (max-width: 740px){
  .header-text-button{ flex-wrap: wrap;
    p.t-hecto{ max-width: 100%; width: 100%; }
    a{ margin-top: 20px; }
  }
}
