.nj-rgpd{
  &__title{ width: 100%; display: block; color: $main-color; margin-bottom: 10px; margin-top: 30px; border-bottom: 1px solid $main-color; @extend %title-font-reg; padding-bottom: 10px; font-size: 20px; }
  &__text{
    p{ @extend %title-font-reg; margin-top: 20px; font-size: 14px; }
  }
}

.rgpd{ display: flex;
  &__section{ width: 50%; }
  h2{  }
  h3{  }
  h4{ margin-top: 20px; margin-bottom: 5px; }
}