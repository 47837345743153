* { margin: 0; padding: 0; }
a { text-decoration: none; color: inherit; }
p { font-size: 16px;  }
ul{ list-style: none; }

body { @extend %title-font-reg; }
h1, h2, h3, h4, h5, h6 { font-weight: 300; }

input, label, select { behavior: none; outline: none; }

body { font-size: 1em; line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
  height: device-height;
  user-scalable: 0;
}

@-o-viewport {
  width: device-width;
  height: device-height;
  user-scalable: 0;
}

@viewport {
  width: device-width;
  height: device-height;
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

::-webkit-scrollbar-track {
  border-color: black;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
}

body {font-size: 1em; line-height: 1.4;
  -webkit-font-smoothing:antialiased;
  -moz-font-smoothing:antialiased;
  -o-font-smoothing:antialiased;
  -ms-font-smoothing:antialiased;
  font-smoothing:antialiased;
  -webkit-text-size-adjust:100%;
  -moz-text-size-adjust:100%;
  -ms-text-size-adjust:100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);}

/*
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection declarations have to be separate.
 * Customize the background color to match your design.
 */

::-moz-selection {background: #b3d4fc; text-shadow: none; }
::selection {background: #b3d4fc; text-shadow: none; }



/* ==========================================================================
   Chrome Frame prompt
   ========================================================================== */

.chromeframe { margin: 0.2em 0; background: #ccc; color: #000; padding: 0.2em 0; }

input[type="radio"], input[type="checkbox"] {margin: 10px 0 0 0;}

.cleditorMain {margin: 20px 0;}

/*
 ######   ##        #######  ########     ###    ##
##    ##  ##       ##     ## ##     ##   ## ##   ##
##        ##       ##     ## ##     ##  ##   ##  ##
##   #### ##       ##     ## ########  ##     ## ##
##    ##  ##       ##     ## ##     ## ######### ##
##    ##  ##       ##     ## ##     ## ##     ## ##
 ######   ########  #######  ########  ##     ## ########
 */

html {font-size: 16px; }

a {outline: none !important;}


/*
########  ########  #### ##    ## ########
##     ## ##     ##  ##  ###   ##    ##
##     ## ##     ##  ##  ####  ##    ##
########  ########   ##  ## ## ##    ##
##        ##   ##    ##  ##  ####    ##
##        ##    ##   ##  ##   ###    ##
##        ##     ## #### ##    ##    ##
*/
@media print {
  * {background: transparent !important; color: #000 !important; /* Black prints faster: h5bp.com/s */ box-shadow: none !important; text-shadow: none !important; }

  a, a:visited {text-decoration: underline; }

  a[href]:after {content: " (" attr(href) ")"; }

  abbr[title]:after {content: " (" attr(title) ")"; }

  /*
   * Don't show links for images, or javascript/internal links
   */

  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {content: ""; }

  pre, blockquote {border: 1px solid #999; page-break-inside: avoid; }

  thead {display: table-header-group; /* h5bp.com/t */ }

  tr, img {page-break-inside: avoid; }

  img {max-width: 100% !important; }

  @page {margin: 0.5cm; }

  p, h2, h3 {orphans: 3; widows: 3; }

  h2, h3 {page-break-after: avoid; }
}

.mr40 { margin-right: 40px; }
.mt40 { margin-top: 40px; }
.mb40 { margin-bottom: 40px; }
.ml30 { margin-left: 30px; }
.mr30 { margin-right: 30px; }
.mt30 { margin-top: 30px; }
.mb30 { margin-bottom: 30px; }
.ml20 { margin-left: 20px; }
.mr20 { margin-right: 20px; }
.mt20 { margin-top: 20px; }
.mb20 { margin-bottom: 20px; }
.ml15 { margin-left: 15px; }
.mr15 { margin-right: 15px; }
.mt15 { margin-top: 15px; }
.mb15 { margin-bottom: 15px; }
.ml10 { margin-left: 10px; }
.mr10 { margin-right: 10px; }
.mt10 { margin-top: 10px; }
.mb10 { margin-bottom: 10px; }
.ml5 { margin-left: 5px; }
.mr5 { margin-right: 5px; }
.mt5 { margin-top: 5px; }
.mb5 { margin-bottom: 5px; }


/* ==========================================================================
   Helper classes
   ========================================================================== */


.clear {clear: both; }

.kromiforms-form {
  .input_label {z-index: 1;}
  button, input[type=submit] { box-sizing:border-box;
    &:hover { cursor: pointer; }
  }
  input[type=text], select, textarea, input[type=password] {
    &:focus { outline: none;/*  .border-radius(15px 15px 0 0) */}
  }
  input[type=text], input[type=password], textarea, select{ width: 100%; }
  .datepicker { padding: 0; }
  input.datepicker { padding-left: 8px; }
  .btn-group{ display: flex; }
  .note-editor{
    .btn-group{ display: block; }
    .note-toolbar.panel-heading{ display: flex; }
  }
  &.inscription {
    .js_startup_field{ display: block;
      &.hidden{ display: none !important; }
    }
    #input_line_us_country, #input_line_us_company_presentation_document{ display: inline-block;
      &.hidden{ display: none !important; }
    }
    .legend_obligatoire {  }
    .ligne_separation { height: 0; margin: 0; }
    .input_legend { display: none; }
    .input_label { font-size: 14px;  }
    input[type=text], textarea, input[type=password] { box-sizing: border-box; }
    select { box-sizing: border-box; }
    .btn-group {
      button[type=submit] { border: none; }
      a { box-sizing: border-box; }
    }
    .input_line {
      &.checkbox-group { display: flex; flex-direction: row-reverse; justify-content: flex-end;
        .input_label { }
        .input_area { }
      }
    }
    .input_line_submits { width: 100%; }
    .three {
      &.civility { width: 70px;
        .input_label { width: 70px; overflow: hidden; display: inline-block; }
        select { width: 70px; }
      }
      &.prenom { width: 334px;
        input[type=text] { width: 328px; }
      }
      &.nom { width: 435px;
        input[type=text] { width: 420px; }
      }
    }
    @media screen and (max-width: 960px) {
      .input_line { float: none; position: relative;
        .input_area {
          input[type=text], textarea, input[type=password] { width: 100%; }
          select { width: 101.5%; }
        }
        &.mid { width: 98%; float: none;
          &.right { margin-left: 0; }
        }
        &.three {
          &.civility { width: 20%;
            .input_label { width: 70px; overflow: hidden; display: inline-block; }
            select { width: 95%; }
          }
          &.prenom { width: 34%; margin-left: 5%;
            input[type=text] { width: 95%; margin-top: 2px; }
          }
          &.nom { width: 35%; margin-left: 5%;
            input[type=text] { width: 95%; margin-top: 2px; }
          }
        }
      }
    }
    @media screen and (max-width: 500px) {
      .input_line {
        &.three {
          &.prenom { width: 100%; margin-left: 0;
            input[type=text] { width: 100%; margin-top: 2px; }
          }
          &.nom { width: 100%; margin-left: 0;
            input[type=text] { width: 100%; margin-top: 2px; }
          }
        }
      }
    }
    #input_line_valid_CGV{
      .input_label{ z-index: 1; }
    }
  }
  @media screen and (max-width: 430px) {
    .legend_obligatoire { margin-top: 30px; }
  }
  .input_line {
    textarea { min-height: 60px; resize: none; line-height: 16px; }
    &.fu { margin-bottom: 10px; }
    &.inline {
      .input_label { display: inline-block; }
      .input_area { display: inline-block; margin-left: 5px; }
    }
  }
  .ligne_separation { border: none; clear: both;
    .cf.titre{ height: auto; }
  }
  &.login {
    .input_label{  }
    .legend_obligatoire { display: none; }
    .input_field { width: 290px; float: right;
      &.remind-me { width: 15px; margin-top: 5px; }
    }
    .input_line {
      &.remind { float: right; width: 150px; }
    }
  }
}


.kromiforms-form {
  h3 {
    &.has-error { color: $rouge-bis; }
  }
  .input_line {
    &.has-error {
      input, select, textarea, .wysiwyg, .chosen-choices { background: no-repeat bottom,50% calc(100% - 1px); background-image: linear-gradient(to top, var(--nj-color-base-danger) 2px, transparent 2px),linear-gradient(to top, var(--nj-color-border-input) 1px, transparent 1px);  }
      .input_label { color: var(--nj-color-base-danger); }
    }
  }
}

.nj__flex-container{ display: flex; }